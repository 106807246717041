import {
  StarIconDefaultStateIcon,
  StarIconHoverStateIcon,
  StarIconPressStateIcon,
} from '../CustomIcons';

interface StarButtonProps {
  onClick?: () => void;
  className?: string;
}

export const StarButton: React.FC<StarButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={`group relative transition-transform duration-100 active:scale-95 &[>svg]:w-full ${className}`}
    >
      <div className='absolute inset-0 transition-opacity duration-200 opacity-100 group-hover:opacity-0 group-active:opacity-0'>
        <StarIconDefaultStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-100 group-active:opacity-0'>
        <StarIconHoverStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-0 group-active:opacity-100'>
        <StarIconPressStateIcon />
      </div>
    </button>
  );
};
