const DefaultStateIcon = () => (
  <svg viewBox='0 0 91 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='45' cy='45' r='40' fill='url(#paint0_radial_8_39)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M90 45C90 20.1474 69.8529 0 44.9993 0C20.1471 0 0 20.1474 0 45C0 69.8526 20.1471 90 44.9993 90C69.8529 90 90 69.8526 90 45ZM85 45C85 22.9088 67.0915 5 44.9994 5C22.9085 5 5 22.9088 5 45C5 67.0912 22.9085 85 44.9994 85C67.0915 85 85 67.0912 85 45Z'
      fill='url(#paint1_linear_8_39)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.4123 77.3161C84.2638 69.2155 89.0966 58.1721 89.0966 46C89.0966 21.1474 68.9495 1 44.0972 1C31.9248 1 20.8812 5.83279 12.7807 13.6842C20.9602 5.24515 32.4161 0 45.0972 0C69.9495 0 90.0966 20.1474 90.0966 45C90.0966 57.6808 84.8514 69.1366 76.4123 77.3161ZM44.0006 85.9711C21.9085 85.9711 4 68.0623 4 45.9711C4 34.6712 8.68565 24.4656 16.2201 17.1913C9.27329 24.3866 5 34.1798 5 44.9711C5 67.0623 22.9085 84.9711 45.0006 84.9711C55.7916 84.9711 65.5846 80.6978 72.7798 73.7511C65.5057 81.2854 55.3003 85.9711 44.0006 85.9711Z'
      fill='#FCF900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3948 9.40961C61.7514 9.40961 75.0105 19.8059 75.0105 32.6301C75.0105 45.4532 61.7514 55.8494 45.3948 55.8494C29.0382 55.8494 15.7792 45.4532 15.7792 32.6301C15.7792 19.8059 29.0382 9.40961 45.3948 9.40961Z'
      fill='url(#paint2_linear_8_39)'
    />
    <path
      d='M42 42H27V48H42V63H48V48H63V42H48V27H42V42Z'
      fill='url(#paint3_linear_8_39)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27 42H42V27H48V28H43V43H28V48H27V42ZM42 48V63H43V48H42ZM63 43V42H48V43H63Z'
      fill='#FCF900'
    />
    <defs>
      <radialGradient
        id='paint0_radial_8_39'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(45 45) rotate(90) scale(40)'
      >
        <stop stopOpacity='0' />
        <stop offset='1' stopOpacity='0.1' />
      </radialGradient>
      <linearGradient
        id='paint1_linear_8_39'
        x1='45'
        y1='0'
        x2='45'
        y2='90'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_8_39'
        x1='45.3948'
        y1='9.40961'
        x2='45.3948'
        y2='55.8494'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_8_39'
        x1='45'
        y1='27'
        x2='45'
        y2='63'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

const HoverStateIcon = () => (
  <svg viewBox='0 0 91 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='45' cy='45' r='40' fill='url(#paint0_radial_8_21)' />
    <circle cx='45' cy='45' r='40' fill='url(#paint1_radial_8_21)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M90 45C90 20.1474 69.8529 0 44.9993 0C20.1471 0 0 20.1474 0 45C0 69.8526 20.1471 90 44.9993 90C69.8529 90 90 69.8526 90 45ZM85 45C85 22.9088 67.0915 5 44.9994 5C22.9085 5 5 22.9088 5 45C5 67.0912 22.9085 85 44.9994 85C67.0915 85 85 67.0912 85 45Z'
      fill='url(#paint2_linear_8_21)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.4123 77.3161C84.2638 69.2155 89.0966 58.1721 89.0966 46C89.0966 21.1474 68.9495 1 44.0972 1C31.9248 1 20.8812 5.83279 12.7807 13.6842C20.9602 5.24515 32.4161 0 45.0972 0C69.9495 0 90.0966 20.1474 90.0966 45C90.0966 57.6808 84.8514 69.1366 76.4123 77.3161ZM44.0006 85.9711C21.9085 85.9711 4 68.0623 4 45.9711C4 34.6712 8.68565 24.4656 16.2201 17.1913C9.27329 24.3866 5 34.1798 5 44.9711C5 67.0623 22.9085 84.9711 45.0006 84.9711C55.7916 84.9711 65.5846 80.6978 72.7798 73.7511C65.5057 81.2854 55.3003 85.9711 44.0006 85.9711Z'
      fill='#FCF900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3948 9.40961C61.7514 9.40961 75.0105 19.8059 75.0105 32.6301C75.0105 45.4532 61.7514 55.8494 45.3948 55.8494C29.0382 55.8494 15.7792 45.4532 15.7792 32.6301C15.7792 19.8059 29.0382 9.40961 45.3948 9.40961Z'
      fill='url(#paint3_linear_8_21)'
    />
    <path
      d='M42 42H27V48H42V63H48V48H63V42H48V27H42V42Z'
      fill='url(#paint4_linear_8_21)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27 42H42V27H48V28H43V43H28V48H27V42ZM42 48V63H43V48H42ZM63 43V42H48V43H63Z'
      fill='#FCF900'
    />
    <defs>
      <radialGradient
        id='paint0_radial_8_21'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(35.5 32) rotate(53.514) scale(59.7013)'
      >
        <stop stopColor='#9C9C9C' />
        <stop offset='1' stopColor='#2D2D2D' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_8_21'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(45 45) rotate(90) scale(40)'
      >
        <stop stopOpacity='0' />
        <stop offset='1' stopOpacity='0.5' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_8_21'
        x1='45'
        y1='0'
        x2='45'
        y2='90'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_8_21'
        x1='45.3948'
        y1='9.40961'
        x2='45.3948'
        y2='55.8494'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_8_21'
        x1='45'
        y1='27'
        x2='45'
        y2='63'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

const PressStateIcon = () => (
  <svg viewBox='0 0 91 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='45' cy='45' r='39' fill='url(#paint0_linear_70_341)' />
    <circle cx='45' cy='45' r='39' fill='url(#paint1_radial_70_341)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M89.0011 45C89.0011 20.6997 69.3017 1 45.0004 1C20.7004 1 1.00108 20.6997 1.00108 45C1.00108 69.3003 20.7004 89 45.0004 89C69.3017 89 89.0011 69.3003 89.0011 45ZM84.1122 45C84.1122 23.3997 66.6016 5.88889 45.0005 5.88889C23.4005 5.88889 5.88996 23.3997 5.88996 45C5.88996 66.6003 23.4005 84.1111 45.0005 84.1111C66.6016 84.1111 84.1122 66.6003 84.1122 45Z'
      fill='url(#paint2_linear_70_341)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.7154 76.598C83.3923 68.6774 88.1177 57.8794 88.1177 45.9778C88.1177 21.6774 68.4184 1.97779 44.1184 1.97779C32.2164 1.97779 21.4183 6.70319 13.4977 14.3801C21.4955 6.12861 32.6968 1.00002 45.0962 1.00002C69.3961 1.00002 89.0955 20.6997 89.0955 45C89.0955 57.399 83.9669 68.6002 75.7154 76.598ZM44.0239 85.0606C22.4227 85.0606 4.91219 67.5498 4.91219 45.9495C4.91219 34.9007 9.4937 24.9219 16.8607 17.8093C10.0683 24.8447 5.88996 34.4203 5.88996 44.9717C5.88996 66.572 23.4005 84.0828 45.0017 84.0828C55.5529 84.0828 65.1283 79.9045 72.1635 73.1122C65.051 80.4791 55.0725 85.0606 44.0239 85.0606Z'
      fill='#FCF900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45 10C61.0166 10 74 20.2978 74 33.0006C74 45.7022 61.0166 56 45 56C28.9834 56 16 45.7022 16 33.0006C16 20.2978 28.9834 10 45 10Z'
      fill='url(#paint3_linear_70_341)'
    />
    <path
      d='M42 42H27V48H42V63H48V48H63V42H48V27H42V42Z'
      fill='url(#paint4_linear_70_341)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.4011 42.0667H42.0677V27.4H47.9344V28.3778H43.0455V43.0444H28.3789V47.9333H27.4011V42.0667ZM42.0677 47.9333V62.6H43.0455V47.9333H42.0677ZM62.6011 43.0444V42.0667H47.9344V43.0444H62.6011Z'
      fill='#FCF900'
    />
    <defs>
      <linearGradient
        id='paint0_linear_70_341'
        x1='45'
        y1='6'
        x2='45'
        y2='84'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <radialGradient
        id='paint1_radial_70_341'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(45 45) rotate(90) scale(39)'
      >
        <stop stopColor='#825B00' stopOpacity='0' />
        <stop offset='1' stopColor='#825B00' stopOpacity='0.3' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_70_341'
        x1='45.0011'
        y1='1'
        x2='45.0011'
        y2='89'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_70_341'
        x1='45'
        y1='10'
        x2='45'
        y2='56'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_70_341'
        x1='45'
        y1='27'
        x2='45'
        y2='63'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

interface PlusButtonProps {
  onClick?: () => void;
  className?: string;
}

export const PlusButton: React.FC<PlusButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={`group relative transition-transform duration-100 active:scale-95 ${className}`}
    >
      <div className='absolute inset-0 transition-opacity duration-200 opacity-100 group-hover:opacity-0 group-active:opacity-0'>
        <DefaultStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-100 group-active:opacity-0'>
        <HoverStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-0 group-active:opacity-100'>
        <PressStateIcon />
      </div>
    </button>
  );
};
