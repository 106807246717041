import { SelectChangeEvent, Stack } from '@mui/material';
import { useState } from 'react';
import { sortGames } from '../../utils/utils';
import GameCards from './components/GameCards';
import { PeriodButtons } from './components/PeriodButtons';

const Leaderboard: React.FC<LeaderboardNS.Props> = ({
  gameResultData,
  avaliableDaysPeriods,
}) => {
  const [selectedPeriod, setPeriod] = useState(0);
  const [sortingType, setSortingType] = useState('');

  const handleChangePeriod = (e: any) => {
    setPeriod(e.target.dataset.id);
  };

  const handleChangeSortingType = (event: SelectChangeEvent) => {
    setSortingType(event.target.value as string);
  };

  return (
    <div className='container mx-auto py-6 max-w-[760px] flex flex-col gap-y-4 h-full'>
      <PeriodButtons
        selectedPeriod={selectedPeriod}
        handleChangePeriod={handleChangePeriod}
        avaliableDaysPeriods={avaliableDaysPeriods}
        sortingType={sortingType}
        handleChangeSortingType={handleChangeSortingType}
      />
      <div className='flex-1 overflow-y-auto'>
        <GameCards
          leaderboard={sortGames(gameResultData, selectedPeriod, sortingType)}
        />
      </div>
    </div>
  );
};

export default Leaderboard;
