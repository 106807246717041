import { useState } from 'react';
import { useEffect } from 'react';
import Leaderboard from '../leaderboard/Leaderboard';
import axios from 'axios';
import { Icon } from '../shared';
import { useModal } from './../../context/modalContext';

export const WinnersModal = () => {
  const [gameResultsData, setGameResultsData] = useState<GameResultData[]>([]);
  const { closeModal } = useModal();

  useEffect(() => {
    axios.get('https://dev.pirate.top/api/results').then(
      (response: any) => {
        // console.log('get games results');
        setGameResultsData(response.data);
      },
      (error: any) => {
        console.log('no game results');
        console.log(error);
      }
    );
  }, []);

  return (
    <div className='w-[95%] sm:w-[754px] h-full max-h-[520px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
      <div className='absolute left-0 w-full flex justify-center -top-[65px] sm:-top-[70px] h-[100px] [&>svg]:w-[300px] sm:[&>svg]:h-auto'>
        <Icon name='mintBurnWaves' />
      </div>
      <div className='absolute -left-[80px] sm:-left-[70px] -top-[10px] sm:-top-[40px] [&>svg]:h-[60px] sm:[&>svg]:h-auto'>
        <Icon name='winnersLeft' />
      </div>
      <div className='absolute -right-[75px] sm:-right-[70px] -top-[15px] sm:-top-[40px] [&>svg]:h-[60px] sm:[&>svg]:h-auto'>
        <Icon name='winnersRight' />
      </div>
      <div className='w-full h-full bg-white rounded-[27px] p-[10px] border-[2px] border-[#FFD900] relative z-10 pt-[30px]'>
        <div className='absolute left-0 w-full flex justify-center -top-[60px] sm:-top-[100px] h-[142px] [&>svg]:h-[80px] sm:[&>svg]:h-auto'>
          <Icon name='winners' />
        </div>
        <div className='absolute left-[5px] sm:left-[65px] -top-[20px] sm:-top-[35px] [&>svg]:h-[40px] sm:[&>svg]:h-auto'>
          <Icon name='winnerCoinLeft' />
        </div>
        <div className='absolute right-[5px] sm:right-[60px] -top-[35px] sm:-top-[55px] [&>svg]:h-[65px] sm:[&>svg]:h-auto'>
          <Icon name='winnerCoinRight' />
        </div>
        <Leaderboard
          gameResultData={gameResultsData}
          avaliableDaysPeriods={[7, 30, 0]}
        />
        <div className='flex justify-center absolute -bottom-[20px] sm:-bottom-[28px] left-0 w-full'>
          <button type='button' onClick={() => closeModal()}>
            <Icon
              name='close'
              className='w-[40px] h-[40px] sm:w-[56px] sm:h-[56px]'
            />
          </button>
        </div>
      </div>
    </div>
  );
};
