import { BigNumber } from 'ethers';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const routes = {
  home: '/',
  leaderboard: '/leaderboard',
};

export enum TokenType {
  BlackSpotToken,
  DoubloonToken,
}

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export type MetamaskError = {
  code: number;
  message: string;
};

export enum ModalContentType {
  ReplenishBST,
  ReplenishChest,
  ExchangeDoubloon,
  TransferToAnother,
  GameOver,
}

export enum SortingType {
  LatestFirst,
  OldestFirst,
  PrizeDescending,
  PrizeAscending,
}

export enum ErrorType {
  ErrorImportingToken,
  ErrorNoMetamaskInstalled,
  ErrorTransaction,
  ReplenishingError,
  WithdrawingError,
  DoubloonBurnError,
  BstBuyError,
  BstBurnError,
  UnknownError,
  RejectedAddingToken,
  ForbiddenLocalChainId,
}

export const addHours = (numOfHours: number, date: Date = new Date()) => {
  console.log({ numOfHours, date });

  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
};

export const sortGames = (
  data: GameResultData[],
  between: number,
  sortingType: string
) => {
  const sortingMethod: string = SortingType[Number(sortingType)];
  let todayDate = new Date();
  let weekDate = new Date();
  console.log(todayDate.getTime());
  weekDate.setTime(todayDate.getTime() - (between + 1 * 24 * 3600000));
  console.log(weekDate);
  let filter = data.filter((val: any) => {
    let userDate = new Date(val.startgame * 1000);
    console.log(new Date(val.startgame * 1000));
    if (between === 0) return val;
    return weekDate <= userDate && todayDate >= userDate;
  });

  // sort with asending order
  const filteredByPeriods = filter.sort((a: any, b: any) => {
    if (a.score === b.score) {
      return b.score - a.score;
    } else {
      return b.score - a.score;
    }
  });

  if (sortingMethod === 'LatestFirst') {
    return (Number(between) !== 0 ? filteredByPeriods : data).sort(
      (objA: GameResultData, objB: GameResultData) =>
        BigNumber.from(objB.startgame)
          .sub(BigNumber.from(objA.startgame))
          .toNumber()
    );
  } else if (sortingMethod === 'OldestFirst') {
    return (Number(between) !== 0 ? filteredByPeriods : data).sort(
      (objA: GameResultData, objB: GameResultData) =>
        BigNumber.from(objA.startgame)
          .sub(BigNumber.from(objB.startgame))
          .toNumber()
    );
  } else if (sortingMethod === 'PrizeDescending') {
    return (Number(between) !== 0 ? filteredByPeriods : data).sort(
      (a: GameResultData, b: GameResultData) =>
        BigNumber.from(b.amount).sub(BigNumber.from(a.amount)).toNumber()
    );
  } else {
    return (Number(between) !== 0 ? filteredByPeriods : data).sort(
      (a: GameResultData, b: GameResultData) =>
        BigNumber.from(a.amount).sub(BigNumber.from(b.amount)).toNumber()
    );
  }
};

export const isInThePast = (date: Date) => {
  const today = new Date();
  return date < today;
};

export const errorSnackbarSelectText = (errorCause: ErrorType) => {
  switch (errorCause) {
    case ErrorType.ErrorImportingToken:
      return 'An critical error occurred while importing tokens.';
    case ErrorType.ErrorNoMetamaskInstalled:
      return 'You probably don`t have the metamask application installed, please check that it is installed correctly';
    case ErrorType.ErrorTransaction:
      return 'A critical error occurred during the transaction';
    case ErrorType.ReplenishingError:
      return 'A critical error occurred while replenishing the account';
    case ErrorType.DoubloonBurnError:
      return 'A critical error occurred while burning Doubloons';
    case ErrorType.BstBuyError:
      return 'A critical error occurred while purchasing BSTs';
    case ErrorType.BstBurnError:
      return 'A critical error occurred while burning BSTs';
    case ErrorType.WithdrawingError:
      return 'A critical error occurred while withdrawing from the account';
    case ErrorType.UnknownError:
      return 'An unknown critical error occurred';
    case ErrorType.RejectedAddingToken:
      return 'You rejected adding tokens in Metamask';

    case ErrorType.ForbiddenLocalChainId:
      return 'Metamask API does not allow the import of a token in the network with a Chain Id equal to 1337';
    default:
      return 'A critical error has occurred';
  }
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const formatToThousands = (value: number) => {
  if (value < 1000) {
    return value.toFixed(1);
  }

  if (value < 1000000) {
    return (value / 1000).toFixed(1) + 'k';
  }

  if (value < 1000000000) {
    return (value / 1000000).toFixed(1) + 'M';
  }

  return (value / 1000000000).toFixed(1) + 'B';
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};
