import { ReactNode } from 'react';
import { useChainValues } from '../../utils/chain';
import { SettingsButton } from '../CustomButtons/SettingsButton';
import {
  ChestOpenIconDefaultState,
  CupIconDefaultState,
  DublonBlackIconDefaultState,
  EthIconDefaultState,
  LogoIcon,
} from './../CustomIcons';
import { ethers } from 'ethers';
import { useGame } from '../../context/gameContext';
import { routes } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../context/modalContext';
import { MintBurnModal } from '../MintBurnModal';
import { DepositWithdrawModal } from '../DepositWithdrawModal';
import { WinnersModal } from '../WinnersModal';

const renderText = (text: string | ReactNode) => {
  return (
    <span className='text-white text-[10px] sm:text-[20px] font-medium font-robotoCondensed'>
      {text}
    </span>
  );
};

export const Header = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
  balanceBst: string;
  prizeChest: string;
  deposit: string;
}) => {
  const navigate = useNavigate();
  const { balanceBst, prizeChest, deposit } = useChainValues();

  const { handleGoToGame } = useGame();

  const { openModal } = useModal();

  const getBSTBalance = () => {
    if (balanceBst.length > 7) {
      return (
        <>
          {balanceBst.length - 7 > 1
            ? `${balanceBst.slice(0, 7)} * 10^
      ${balanceBst.length - 7}`
            : `${balanceBst}`}
        </>
      );
    } else return balanceBst;
  };

  const handleClickSettingsButton = () => {};

  const handleGoToGamedRoute = () => {
    handleGoToGame();
    navigate(routes.home);
  };

  const handleOpenMintBurnModal = () => {
    openModal(<MintBurnModal />);
  };

  const handleOpenDepositWithdrawModal = () => {
    openModal(<DepositWithdrawModal />);
  };

  const handleOpenWinnersModal = () => {
    openModal(<WinnersModal />);
  };

  return (
    <header className='h-[140px] sm:h-[170px] overflow-hidden relative'>
      <div className='h-full flex items-start'>
        <div className='flex-1 h-[120px] sm:h-[159px] bg-[#181A26]' />
        <div
          className='flex-none h-full bg-no-repeat bg-center w-[1920px]'
          style={{
            backgroundImage: `url(/assets/header-bg.svg)`,
          }}
        ></div>
        <div className='flex-1 h-[120px] sm:h-[139.8px] bg-[#181A26]' />
      </div>
      <div className='w-full flex items-center absolute z-10 inset-0 px-6 mt-[-20px]'>
        <div className='flex-1'></div>
        <div className='w-full sm:w-[760px]'>
          <div className='w-full flex items-center'>
            <div className='flex-1 flex items-center gap-x-3 sm:gap-x-8'>
              {isAuthenticated && (
                <>
                  <button
                    className='w-[45px] sm:w-[80px] h-[45px] sm:h-[80px]'
                    onClick={() => handleOpenMintBurnModal()}
                  >
                    <DublonBlackIconDefaultState />
                    {renderText(getBSTBalance())}
                  </button>
                  <button
                    className='w-[45px] sm:w-[80px] h-[45px] sm:h-[80px]'
                    onClick={() => handleOpenDepositWithdrawModal()}
                  >
                    <EthIconDefaultState />
                    {renderText(ethers.utils.formatEther(deposit).slice(0, 9))}
                  </button>
                </>
              )}
            </div>
            <div className='w-[70px] sm:w-[120]'>
              <button
                className='w-[70px] sm:w-[120px] h-[60px] sm:h-[120px]'
                onClick={handleGoToGamedRoute}
              >
                <LogoIcon />
              </button>
            </div>
            <div className='flex-1'>
              <div className='flex justify-end gap-x-3 sm:gap-x-6'>
                {isAuthenticated && (
                  <>
                    <div className='w-[45px] sm:w-[100px] h-[45px] sm:h-[80px] text-center'>
                      <ChestOpenIconDefaultState />
                      {renderText(
                        ethers.utils.formatEther(prizeChest).slice(0, 9)
                      )}
                    </div>
                    <button
                      className='w-[45px] sm:w-[100px] h-[45px] sm:h-[80px]'
                      onClick={handleOpenWinnersModal}
                    >
                      <CupIconDefaultState />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex-1 justify-end hidden sm:flex'>
          {isAuthenticated && (
            <SettingsButton onClick={handleClickSettingsButton} />
          )}
        </div>
      </div>
    </header>
  );
};
