export const ChestOpenIconDefaultState = () => (
  <svg viewBox='0 0 100 87' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M49.9989 86.194C77.6125 86.194 99.9978 79.0346 99.9978 70.203C99.9978 61.3714 77.6125 54.212 49.9989 54.212C22.3853 54.212 0 61.3714 0 70.203C0 79.0346 22.3853 86.194 49.9989 86.194Z'
      fill='black'
      fillOpacity='0.4'
    />
    <path d='M71.5 1H28.5L26 4H74L71.5 1Z' fill='#808080' />
    <path d='M74 4L26 3.96075V15H74V4Z' fill='#4D4D4D' />
    <path d='M25 4H14V15H25V4Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26 15H25V4L26 4.00002V15Z'
      fill='#FFB900'
    />
    <path
      d='M28.2937 0.000335693H18L14 4H25L28.2937 0.000335693Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.5 1L28.2944 0L25 4H26L28.5 1Z'
      fill='#F6D900'
    />
    <path d='M74.9997 4H85.9997V15H74.9997V4Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.9997 15H74.9997V4L73.9997 4.00002V15Z'
      fill='#FFB900'
    />
    <path
      d='M71.706 0.000335693H81.9997L85.9997 4H74.9997L71.706 0.000335693Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.4997 1L71.7053 0L74.9997 4H73.9997L71.4997 1Z'
      fill='#F6D900'
    />
    <path d='M24 55H14V73H24V55Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25 55H24V73L25 72V55Z'
      fill='#FFB900'
    />
    <path d='M76 55H86V73H76V55Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75 55H76V73L75 72V55Z'
      fill='#FFB900'
    />
    <path d='M75 55H25V68.9652H75V55Z' fill='#4D4D4D' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 19H82L72 34H28L18 19Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.1179 36.8239H72.318H27.9416H25.1417L12 18L88 18L75.1179 36.8239ZM27.0197 35.2304H73.2399L84 19H16L27.0197 35.2304Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.0197 35.2304L16 19H18L28 34L27.0083 35.2304H27.0197Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.2399 35.2304L84 19H82L71.9766 34.0126L73.2513 35.2304H73.2399Z'
      fill='#FFB900'
    />
    <path d='M88 15L88 18L12 18L12 15L88 15Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.2944 34.0126L33 21H67L71.7034 34.0126H28.2944Z'
      fill='#4D4D4D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33 21H66.9915V19H33V21Z'
      fill='#1A1A1A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.838 24.0652L48.8266 24.6343C48.9973 24.5887 49.1681 24.5546 49.3502 24.5318C50.0672 24.4408 50.7387 24.5091 51.2623 24.6912C51.194 24.5091 51.194 24.3497 51.2623 24.2359L51.5809 23.6896C51.8313 23.2571 53.015 23.5758 54.2214 24.3839C55.0295 24.9302 55.6441 25.5562 55.8831 26.0456C55.9059 26.0456 55.9287 26.0342 55.9514 26.0342C57.4993 25.8293 58.922 26.922 59.1269 28.4812C59.2635 29.5056 58.831 30.4844 58.0798 31.0762C58.8196 30.9851 59.3659 31.0876 59.4683 31.4063L59.5935 31.7932L24.732 36.3914C24.5271 36.3003 24.4019 36.1751 24.3792 36.0158L24.2767 35.3784C24.2084 34.9914 24.8344 34.5476 25.7677 34.2516C25.1303 33.8078 24.6751 33.1135 24.5613 32.2826C24.3792 30.8827 25.2442 29.5738 26.553 29.1869C26.4051 28.7202 27.2132 27.8325 28.4082 27.1496C29.6488 26.4439 30.8439 26.2391 31.0715 26.6829L31.2536 27.0471C31.3333 26.9675 31.4129 26.8878 31.504 26.8081C31.5267 26.3187 32.369 25.5903 33.5413 25.0782C34.8388 24.5091 36.0566 24.4294 36.2387 24.896L36.3866 25.2716C36.785 25.0326 37.2744 24.8619 37.8207 24.7822C38.2077 24.7367 38.5833 24.7367 38.9361 24.7936C38.5491 23.7693 38.4922 22.9157 38.8223 22.7222L39.3572 22.4263C39.7783 22.1986 40.6661 23.0864 41.3376 24.4294C41.3945 24.5432 41.4514 24.657 41.4969 24.7595C41.7132 24.6343 41.9522 24.5205 42.214 24.418C42.7603 24.1904 43.2838 24.0538 43.7277 23.9969L43.7391 23.7807C43.7505 23.2685 44.9114 22.9157 46.3341 22.984C47.7454 23.0522 48.8835 23.5189 48.8721 24.0311L48.838 24.0652Z'
      fill='#E4BA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.0648 34.1833L34.2355 35.0711C34.2355 35.0939 34.2469 35.1166 34.2469 35.1394L27.0652 36.0841L26.9628 35.5377C29.3301 35.0825 31.6975 34.6272 34.0648 34.172V34.1833Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.2748 33.5915C32.2324 33.2159 33.9396 33.4777 34.0648 34.1833C34.1331 34.5362 33.7917 34.9345 33.1998 35.2873L27.4294 36.0499C27.1676 35.9247 26.9969 35.754 26.9628 35.5491C26.8262 34.8435 28.3172 33.9671 30.2748 33.5801V33.5915Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.2975 33.7167C30.9121 33.5915 31.504 33.546 32.0275 33.546C31.7771 33.5688 31.5154 33.6143 31.2422 33.6712C29.3756 34.0354 27.9643 34.8435 28.0782 35.4695C28.1123 35.6516 28.2603 35.7995 28.5106 35.902L27.7026 36.0044C27.3839 35.8906 27.179 35.7199 27.1335 35.515C27.0197 34.8776 28.431 34.0695 30.2975 33.7167Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.4141 33.8078L39.3914 34.4793L33.1202 35.3101C32.5056 35.0483 32.1413 34.7183 32.1413 34.3654L32.1641 33.4549C34.577 33.5687 36.9899 33.6826 39.4027 33.8078H39.4141Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.8289 32.3282C37.8321 32.4192 39.4369 33.0907 39.4141 33.8078C39.4027 34.1151 39.0954 34.3882 38.5719 34.5931L35.92 34.9459C35.8631 34.9459 35.8062 34.9459 35.7493 34.9345C33.7461 34.8435 32.1413 34.172 32.1641 33.4549C32.1869 32.7379 33.8258 32.2371 35.8176 32.3282H35.8289Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.8176 32.4534C36.4549 32.4875 37.0354 32.5672 37.5476 32.6924C37.2972 32.6582 37.0354 32.6355 36.7622 32.6241C34.8615 32.533 33.3136 32.9769 33.2909 33.6257C33.2795 34.0582 33.951 34.4679 34.964 34.7183C33.4502 34.5248 32.3348 34.0012 32.3462 33.4549C32.369 32.8062 33.9169 32.3623 35.8176 32.4534Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.8831 31.7819L55.9173 32.294L48.747 33.2387C48.7356 33.1932 48.7242 33.1476 48.7128 33.1021L48.6559 32.1916C51.0688 32.055 53.4703 31.9184 55.8831 31.7819Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.1842 30.6892C54.1759 30.5754 55.8376 31.0648 55.8831 31.7818C55.8945 31.9753 55.7921 32.1574 55.5986 32.3395L49.9079 33.0907C49.1681 32.8972 48.69 32.5786 48.6673 32.203C48.6218 31.4859 50.2038 30.8144 52.1955 30.7006L52.1842 30.6892Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.1955 30.8258C52.8215 30.7917 53.4247 30.8144 53.9369 30.8827C53.6865 30.8713 53.4134 30.8827 53.1516 30.8941C51.2509 31.0079 49.7599 31.6111 49.794 32.2599C49.8168 32.5558 50.1583 32.8062 50.7046 32.9883L50.2721 33.0452C49.4298 32.8745 48.8721 32.5672 48.8494 32.1916C48.8039 31.5428 50.3062 30.9396 52.2069 30.8258H52.1955Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.2407 31.4632L59.0927 31.8843L56.327 32.2485C56.1108 32.1802 55.8832 32.0891 55.6555 31.9981C54.028 31.3038 52.8898 30.2795 53.1061 29.699L53.3678 28.9706C55.3255 29.8015 57.2831 30.6323 59.2407 31.4745V31.4632Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.6912 29.1641C58.3188 29.8584 59.4569 30.8827 59.2407 31.4632C59.0244 32.0436 57.5448 31.9526 55.9173 31.2583C54.3011 30.564 53.1516 29.5397 53.3678 28.9592C53.5841 28.3788 55.0637 28.4698 56.6912 29.1641Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.6457 29.2665C57.1579 29.4828 57.6245 29.7332 58.0001 29.995C57.7952 29.8925 57.5904 29.7901 57.3627 29.699C55.8262 29.0389 54.4149 28.9365 54.2328 29.46C54.1076 29.8129 54.5515 30.3478 55.3141 30.8599C54.119 30.234 53.3337 29.4714 53.5044 29.0275C53.6979 28.504 55.0978 28.6178 56.6343 29.2665H56.6457Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.2505 25.8976L58.3985 26.6716C58.5123 27.2862 57.2262 28.0487 55.5189 28.3788C53.8117 28.7088 52.3435 28.4812 52.2297 27.8666L52.0817 27.0927C54.1418 26.6943 56.2018 26.296 58.2619 25.8976H58.2505Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9612 25.3741C56.6685 25.044 58.1367 25.283 58.2505 25.8862C58.3643 26.5008 57.0782 27.2634 55.371 27.5935C53.6637 27.9235 52.1955 27.6959 52.0817 27.0813C51.9679 26.4667 53.254 25.7041 54.9612 25.3741Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9726 25.4879C55.5075 25.3855 56.0197 25.3399 56.475 25.3399C56.2587 25.3627 56.0311 25.3968 55.7921 25.4424C54.1759 25.7497 52.9467 26.4553 53.0491 27.013C53.1174 27.3772 53.7662 27.5935 54.6653 27.5935C53.3564 27.7414 52.3093 27.5252 52.2297 27.0585C52.1272 26.5008 53.3564 25.8066 54.9726 25.4879Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.3207 24.4863L52.4687 25.2602C52.5825 25.8748 51.2964 26.6374 49.5892 26.9675C47.8819 27.2975 46.4137 27.0699 46.2999 26.4553L46.152 25.6814C48.212 25.283 50.2721 24.8846 52.3207 24.4863Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.0315 23.9741C50.7387 23.6441 52.2069 23.8831 52.3207 24.4863C52.4346 25.1009 51.1484 25.8635 49.4412 26.1935C47.734 26.5236 46.2658 26.296 46.152 25.6814C46.0382 25.0668 47.3243 24.3042 49.0315 23.9741Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.0542 24.088C49.5892 23.9855 50.1127 23.94 50.5566 23.94C50.3404 23.9628 50.1127 23.9969 49.8737 24.0424C48.2575 24.3497 47.0283 25.0554 47.1308 25.6131C47.1991 25.9773 47.8478 26.1935 48.747 26.1935C47.4381 26.3415 46.391 26.1252 46.3113 25.6586C46.2089 25.1009 47.4381 24.4066 49.0542 24.088Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.1625 30.2226L31.1284 31.0079C31.1056 31.6339 29.6716 32.0436 27.9416 31.9412C26.2002 31.8274 24.8231 31.2355 24.8458 30.6095L24.88 29.8242C26.9742 29.9608 29.0683 30.086 31.1625 30.2226Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.0668 28.8909C29.8081 29.0048 31.1853 29.5966 31.1625 30.2226C31.1398 30.8486 29.7057 31.2583 27.9757 31.1559C26.2344 31.0421 24.8572 30.4502 24.88 29.8242C24.9027 29.1982 26.3368 28.7885 28.0668 28.8909Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.0554 29.0048C28.6017 29.0389 29.1139 29.1186 29.5578 29.2438C29.3415 29.2096 29.1139 29.1869 28.8749 29.1755C27.2246 29.073 25.8702 29.4373 25.8474 30.0063C25.836 30.3819 26.4165 30.7461 27.2928 30.9738C25.984 30.7803 25.0165 30.325 25.0393 29.8356C25.0621 29.2779 26.4165 28.9023 28.0668 29.0048H28.0554Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.0063 26.6374L33.2226 27.4C33.3933 28.0032 32.1755 28.891 30.5138 29.4031C28.8521 29.9039 27.3611 29.8242 27.1904 29.2324L26.9742 28.4698C28.9887 27.8666 30.9918 27.252 33.0063 26.6488V26.6374Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.683 26.4553C31.3447 25.9545 32.8356 26.0342 33.0064 26.626C33.1771 27.2293 31.9593 28.117 30.2976 28.6292C28.6359 29.13 27.1449 29.0503 26.9742 28.4585C26.8034 27.8552 28.0213 26.9675 29.683 26.4553Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.7171 26.5691C30.2407 26.4098 30.7528 26.3074 31.1967 26.2618C30.9804 26.3074 30.7528 26.3643 30.5366 26.4326C28.9545 26.9106 27.7936 27.7414 27.953 28.2764C28.0554 28.6406 28.7155 28.7772 29.6147 28.6975C28.3286 28.982 27.2701 28.8682 27.1449 28.4129C26.9969 27.8666 28.1465 27.0472 29.7285 26.5691H29.7171Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.8435 25.8521L37.7297 26.626C37.6386 27.2406 36.1818 27.491 34.4632 27.1951C32.7446 26.8878 31.4243 26.148 31.504 25.5334L31.6178 24.7595C33.6892 25.1237 35.772 25.4879 37.8435 25.8521Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.8843 24.1904C36.6029 24.4977 37.9232 25.2375 37.8435 25.8521C37.7524 26.4667 36.2956 26.7171 34.577 26.4212C32.8584 26.1139 31.5381 25.3741 31.6178 24.7595C31.7089 24.1449 33.1657 23.8945 34.8843 24.1904Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.8729 24.2928C35.4192 24.3839 35.92 24.5318 36.3411 24.6912C36.1249 24.6343 35.8972 24.5887 35.6696 24.5432C34.0421 24.2587 32.6535 24.4749 32.5739 25.0326C32.5169 25.4082 33.0633 25.8293 33.9169 26.1594C32.6308 25.8293 31.7089 25.2602 31.7771 24.7822C31.8568 24.2245 33.2454 24.0083 34.8729 24.2928Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.9467 29.2096L52.8215 30.0974C52.7191 30.803 51.0574 31.0876 49.0998 30.7461C47.1422 30.4047 45.6398 29.5511 45.7422 28.8568L45.8674 27.969C48.2234 28.3901 50.5907 28.7999 52.9581 29.221L52.9467 29.2096Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.5778 27.3203C51.5354 27.6617 53.0378 28.5153 52.9467 29.2096C52.8443 29.9153 51.1826 30.1998 49.225 29.8584C47.2674 29.5169 45.765 28.6633 45.8561 27.969C45.9585 27.2634 47.6202 26.9788 49.5778 27.3203Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.555 27.4455C50.1696 27.5593 50.7501 27.7186 51.2281 27.9007C50.9777 27.8325 50.7273 27.7756 50.4542 27.73C48.599 27.4 47.017 27.6504 46.9259 28.2877C46.869 28.7088 47.4836 29.1982 48.451 29.5738C46.9828 29.1982 45.9471 28.5495 46.0154 28.0032C46.1064 27.3772 47.6885 27.1268 49.5437 27.4569L49.555 27.4455Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.5852 31.19L32.7559 32.0664C32.8925 32.7607 31.4471 33.6598 29.5122 34.0695C27.5774 34.4793 25.8929 34.2403 25.7563 33.546L25.5856 32.6696C27.9188 32.1802 30.252 31.6908 32.5852 31.2014V31.19Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8293 30.6551C30.7642 30.2454 32.4486 30.4844 32.5852 31.1786C32.7218 31.8729 31.2764 32.772 29.3415 33.1818C27.4066 33.5915 25.7222 33.3525 25.5856 32.6582C25.449 31.964 26.8945 31.0648 28.8293 30.6551Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8521 30.7803C29.4667 30.6551 30.0472 30.5868 30.5593 30.5868C30.3089 30.6209 30.0472 30.6665 29.7854 30.712C27.953 31.099 26.5644 31.9184 26.6896 32.5444C26.7693 32.9655 27.5091 33.1932 28.5334 33.1818C27.0424 33.3753 25.8588 33.1476 25.7449 32.6127C25.6198 31.9867 27.0083 31.1672 28.8407 30.7803H28.8521Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M36.398 27.5935L36.5801 28.4699C36.7167 29.1641 35.2713 30.0633 33.3364 30.473C31.4016 30.8827 29.7171 30.6437 29.5805 29.9494L29.3984 29.0731C31.7316 28.5837 34.0648 28.0943 36.398 27.5935Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.6421 27.0699C34.577 26.6602 36.2615 26.8992 36.398 27.5935C36.5346 28.2877 35.0892 29.1869 33.1543 29.5966C31.2195 30.0063 29.535 29.7673 29.3984 29.0731C29.2618 28.3788 30.7073 27.4797 32.6421 27.0699Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.6649 27.1951C33.2795 27.0699 33.86 27.0016 34.3721 27.0016C34.1217 27.0358 33.86 27.0813 33.5982 27.1268C31.7658 27.5138 30.3772 28.3333 30.5024 28.9592C30.5821 29.3803 31.3219 29.608 32.3462 29.5966C30.8552 29.7901 29.6716 29.5625 29.5578 29.0275C29.4326 28.4015 30.8211 27.5821 32.6535 27.1951H32.6649Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.2089 23.9514L46.2544 24.6115C46.2886 25.1351 45.139 25.6358 43.6708 25.7269C42.214 25.8179 40.9962 25.4651 40.962 24.9416L40.9165 24.2814C42.6806 24.1676 44.4447 24.0538 46.2089 23.94V23.9514Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.4887 23.1661C44.9455 23.075 46.1633 23.4278 46.2089 23.9514C46.243 24.4749 45.0935 24.9757 43.6253 25.0781C42.1684 25.1692 40.9506 24.8164 40.9051 24.2928C40.871 23.7693 42.0205 23.2685 43.4887 23.1774V23.1661Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.5001 23.2685C43.9667 23.2343 44.3992 23.2571 44.7748 23.3026C44.5927 23.3026 44.3992 23.3026 44.1944 23.314C42.8058 23.4051 41.7132 23.8603 41.7473 24.327C41.7701 24.6456 42.2937 24.8847 43.0448 24.9871C41.9294 24.9643 41.0758 24.6798 41.0531 24.2814C41.0189 23.8034 42.1116 23.3482 43.5001 23.2685Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M42.3619 26.4781L42.4075 27.1382C42.4416 27.6618 41.2921 28.1625 39.8239 28.2536C38.367 28.3447 37.1492 27.9918 37.1151 27.4683L37.0695 26.8082C38.8337 26.6943 40.5978 26.5805 42.3619 26.4667V26.4781Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.6531 25.6928C41.11 25.6017 42.3278 25.9545 42.3733 26.4781C42.4074 27.0016 41.2579 27.5024 39.7897 27.6048C38.3329 27.6959 37.1151 27.3431 37.0695 26.8195C37.0354 26.296 38.1849 25.7952 39.6531 25.7041V25.6928Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.6645 25.7838C40.1311 25.7497 40.5636 25.7724 40.9392 25.8179C40.7571 25.8179 40.5636 25.8179 40.3588 25.8293C38.9702 25.9204 37.8776 26.3756 37.9118 26.8423C37.9345 27.161 38.4581 27.4 39.2092 27.5024C38.0939 27.4796 37.2402 27.1951 37.2175 26.7968C37.1833 26.3187 38.276 25.8635 39.6645 25.7838Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7536 32.6582L45.8219 33.5915C45.8219 33.6029 45.8219 33.6143 45.8219 33.6257L38.6857 34.5703C38.4808 34.4224 38.367 34.2516 38.3556 34.0695L38.2874 33.1363C40.7799 32.9769 43.2724 32.8176 45.7536 32.6582Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.9181 31.5542C43.9781 31.429 45.6967 31.9184 45.7422 32.6582C45.7764 33.1021 45.2187 33.5232 44.3195 33.8305L41.0986 34.2516C39.5166 34.1947 38.3215 33.7509 38.276 33.1363C38.2191 32.3965 39.858 31.6908 41.9181 31.5542Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.9294 31.6794C42.5782 31.6339 43.1928 31.6567 43.7277 31.7249C43.4659 31.7249 43.1928 31.7249 42.9082 31.7477C40.9506 31.8729 39.4027 32.5103 39.4483 33.1818C39.4824 33.6257 40.2108 33.9671 41.2807 34.1151C39.71 34.0923 38.5036 33.6826 38.4695 33.1135C38.4239 32.442 39.9718 31.8046 41.9294 31.6794Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.6547 30.3023L40.7116 31.1331C40.7571 31.7933 39.3117 32.4192 37.4793 32.5444C35.6469 32.6582 34.1217 32.2257 34.0762 31.5656L34.0193 30.7348C36.2273 30.5982 38.4467 30.4502 40.6661 30.3137L40.6547 30.3023Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.2516 29.3235C39.0841 29.2096 40.6092 29.6421 40.6547 30.3023C40.7002 30.9624 39.2548 31.5884 37.4224 31.7136C35.5899 31.8274 34.0648 31.3949 34.0193 30.7348C33.9738 30.0746 35.4192 29.4487 37.2516 29.3235Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.263 29.4373C37.8435 29.4031 38.3898 29.4145 38.8678 29.4828C38.6402 29.4828 38.3898 29.4828 38.1394 29.5056C36.398 29.6194 35.0209 30.1885 35.0664 30.7803C35.1005 31.1787 35.7493 31.486 36.6939 31.6111C35.294 31.5884 34.2242 31.2242 34.19 30.7234C34.1445 30.1316 35.5217 29.5625 37.263 29.4487V29.4373Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.2493 31.1559L50.3062 31.9867C50.3518 32.6469 48.9063 33.2728 47.0739 33.398C45.2415 33.5119 43.7163 33.0794 43.6708 32.4192L43.6139 31.5884C45.8219 31.4518 48.0413 31.3038 50.2607 31.1673L50.2493 31.1559Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.8349 30.1771C48.6673 30.0633 50.1924 30.4957 50.2379 31.1559C50.2835 31.816 48.838 32.442 47.0056 32.5672C45.1732 32.681 43.648 32.2485 43.6025 31.5884C43.557 30.9282 45.0024 30.3023 46.8349 30.1771Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.8462 30.2909C47.4267 30.2567 47.973 30.2681 48.451 30.3364C48.2234 30.3364 47.973 30.3364 47.7226 30.3592C45.9812 30.473 44.6041 31.0421 44.6496 31.6339C44.6838 32.0323 45.3325 32.3396 46.2772 32.4648C44.8772 32.442 43.8074 32.0778 43.7732 31.577C43.7277 30.9852 45.1049 30.4161 46.8462 30.3023V30.2909Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.1732 29.8356L45.2187 30.4958C45.2528 31.0193 44.1033 31.5201 42.6351 31.6111C41.1783 31.7022 39.9604 31.3494 39.9263 30.8258L39.8808 30.1657C41.6449 30.0519 43.409 29.9381 45.1732 29.8242V29.8356Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M42.4644 29.0503C43.9212 28.9592 45.139 29.3121 45.1845 29.8356C45.2187 30.3592 44.0692 30.86 42.6009 30.951C41.1441 31.0421 39.9263 30.6892 39.8808 30.1657C39.8466 29.6421 40.9961 29.1413 42.4644 29.0503Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M42.4644 29.1413C42.931 29.1072 43.3635 29.13 43.7391 29.1755C43.557 29.1755 43.3635 29.1755 43.1586 29.1869C41.7701 29.2779 40.6775 29.7332 40.7116 30.1998C40.7344 30.5185 41.2579 30.7575 42.0091 30.86C40.8937 30.8372 40.0401 30.5527 40.0173 30.1429C39.9832 29.6649 41.0758 29.2096 42.4644 29.13V29.1413Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.172 25.7724L65.9785 26.3074C66.1493 26.3187 66.3314 26.3415 66.5135 26.3756C67.2191 26.5008 67.8451 26.774 68.289 27.1154C68.2776 26.922 68.3231 26.774 68.4255 26.6829L68.8922 26.2618C69.2678 25.9318 70.2921 26.5919 71.1912 27.73C71.7945 28.4926 72.1814 29.2893 72.2611 29.8242C72.2839 29.8242 72.3066 29.8356 72.3294 29.8356C73.8659 30.1202 74.8789 31.5998 74.5943 33.1476C74.4122 34.172 73.7066 34.9573 72.8074 35.2987C73.5358 35.4353 74.0252 35.7085 74.0252 36.0385V36.4369L39.4369 30.1088C39.2662 29.9608 39.1865 29.8015 39.2093 29.6421L39.3003 29.0048C39.3572 28.6178 40.0856 28.3788 41.0644 28.3902C40.5978 27.7756 40.3702 26.9675 40.5295 26.148C40.7799 24.7595 42.0091 23.7807 43.3749 23.8148C43.3863 23.3254 44.422 22.7222 45.765 22.449C47.1649 22.1645 48.36 22.3238 48.4396 22.8246L48.5079 23.223C48.599 23.1661 48.7128 23.1205 48.8152 23.075C48.986 22.6198 50.0217 22.1759 51.285 22.0507C52.6963 21.9027 53.88 22.1986 53.9141 22.7108L53.9483 23.1205C54.4035 23.0181 54.9271 22.9953 55.462 23.0978C55.849 23.1661 56.2018 23.2913 56.5205 23.4506C56.4636 22.358 56.6685 21.5157 57.0554 21.4475L57.6587 21.3336C58.1367 21.2426 58.6944 22.3694 58.922 23.8376C58.9448 23.9628 58.9561 24.088 58.9675 24.2018C59.2179 24.1563 59.4797 24.1221 59.7528 24.0993C60.3333 24.0538 60.8796 24.088 61.3121 24.1676L61.3804 23.9628C61.5511 23.4847 62.7576 23.4961 64.0892 23.9969C65.4208 24.4977 66.3541 25.2944 66.1834 25.7724H66.172Z'
      fill='#E4BA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.0087 30.8713L48.8949 31.7705C48.8949 31.7932 48.8835 31.816 48.8835 31.8388L41.7587 30.5413L41.827 29.995C44.2171 30.2909 46.6186 30.5754 49.0087 30.8713Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.5829 29.1414C47.5633 29.3804 49.0998 30.1543 49.0087 30.8713C48.9632 31.2242 48.5193 31.4973 47.8478 31.6567L42.1229 30.6096C41.9067 30.4047 41.8042 30.1998 41.827 29.995C41.918 29.2779 43.6025 28.9023 45.5829 29.1414Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.5715 29.2665C46.1975 29.3462 46.778 29.4714 47.2674 29.6307C47.017 29.5852 46.7552 29.5397 46.4934 29.5056C44.6155 29.2779 43.0107 29.608 42.931 30.2567C42.9082 30.4388 43.0107 30.6209 43.2042 30.803L42.3961 30.6551C42.1229 30.4502 41.975 30.2226 42.0091 30.0177C42.0888 29.3804 43.6822 29.0389 45.5715 29.2665Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.2101 32.1347L53.9824 32.772L47.7568 31.6339C47.256 31.2014 47.0056 30.7689 47.1194 30.4388L47.4267 29.5852C49.6916 30.4388 51.9451 31.2924 54.2101 32.1347Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2509 29.6421C53.1288 30.3478 54.4491 31.4632 54.2101 32.1461C54.1076 32.442 53.7207 32.6013 53.1743 32.6355L50.5452 32.1574C50.4883 32.1347 50.4428 32.1233 50.3859 32.1005C48.5079 31.3949 47.1877 30.2795 47.4267 29.5966C47.6657 28.9251 49.3843 28.9479 51.2509 29.6535V29.6421Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2054 29.756C51.7972 29.9836 52.3321 30.2454 52.776 30.5185C52.5484 30.4047 52.298 30.3023 52.0476 30.2112C50.2721 29.5397 48.6559 29.4942 48.4397 30.1088C48.2917 30.5185 48.8152 31.1103 49.703 31.668C48.3258 31.0193 47.4153 30.1771 47.5974 29.6535C47.8137 29.0389 49.4298 29.0958 51.2054 29.7673V29.756Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.5084 35.276L70.3832 35.7768L63.2697 34.4793C63.2697 34.4337 63.2697 34.3882 63.2811 34.3313L63.4974 33.4549C65.8306 34.0581 68.1638 34.6727 70.497 35.276H70.5084Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3215 33.1021C69.2564 33.6029 70.6791 34.5817 70.5084 35.276C70.4628 35.4581 70.3035 35.606 70.0645 35.7198L64.4079 34.6841C63.7591 34.2744 63.4063 33.8191 63.4974 33.4549C63.6681 32.7607 65.3867 32.6013 67.3215 33.1021Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.2874 33.2273C67.902 33.3866 68.4597 33.5915 68.9263 33.8191C68.6873 33.7395 68.4369 33.6598 68.1752 33.5915C66.3427 33.1135 64.7266 33.2387 64.5672 33.8647C64.4989 34.1492 64.7379 34.502 65.2046 34.8321L64.7721 34.7524C64.0209 34.3313 63.5884 33.876 63.6795 33.5004C63.8388 32.8745 65.455 32.7493 67.2874 33.2273Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.7976 35.993L73.5244 36.3458L70.7815 35.8451C70.5994 35.7085 70.4059 35.5605 70.2238 35.4012C68.8922 34.2517 68.1182 32.92 68.4938 32.4306L68.9718 31.816C70.5766 33.2046 72.1928 34.6045 73.7976 35.993Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.079 33.0224C73.4106 34.172 74.1846 35.5036 73.809 35.993C73.4334 36.4824 72.0449 35.9361 70.7132 34.7866C69.3816 33.637 68.6076 32.3054 68.9832 31.816C69.3588 31.3266 70.7474 31.8729 72.079 33.0224Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.0107 33.1135C72.4318 33.4777 72.796 33.8647 73.0806 34.2175C72.9212 34.0582 72.7505 33.8988 72.5684 33.7395C71.3051 32.6468 69.9962 32.1119 69.6661 32.5444C69.4385 32.8403 69.7003 33.4891 70.2693 34.2175C69.3247 33.2615 68.8125 32.294 69.1084 31.9184C69.4499 31.4859 70.7474 32.0095 72.0107 33.1135Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M74.5716 30.4047L74.4691 31.19C74.3895 31.8046 72.9326 32.146 71.2026 31.9298C69.484 31.7249 68.1524 31.042 68.2321 30.4274L68.3345 29.6421C70.4173 29.8925 72.5001 30.1543 74.5716 30.4047Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.5896 28.8909C73.3082 29.0958 74.6398 29.7787 74.5602 30.3933C74.4805 31.0079 73.0123 31.3493 71.2937 31.1331C69.5751 30.9282 68.2321 30.2453 68.3231 29.6307C68.4028 29.0161 69.8596 28.6747 71.5896 28.8909Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.5782 29.0048C72.1245 29.0731 72.6253 29.1869 73.0578 29.3234C72.8416 29.2779 72.6139 29.2438 72.3749 29.2096C70.736 29.0048 69.3588 29.3007 69.2792 29.8584C69.2336 30.234 69.7799 30.6323 70.6336 30.9169C69.3361 30.6551 68.4142 30.1315 68.4825 29.6535C68.5507 29.0958 69.9393 28.7999 71.5782 29.0048Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.3588 27.2406L69.2564 28.0259C69.1767 28.6405 67.7085 28.982 65.9899 28.7657C64.2713 28.5609 62.9397 27.878 63.0193 27.2634L63.1218 26.4781C65.2046 26.7285 67.2874 26.9902 69.3588 27.2406Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.3883 25.7383C68.1069 25.9431 69.4385 26.626 69.3588 27.2406C69.2792 27.8552 67.8223 28.1967 66.0923 27.9804C64.3737 27.7755 63.0421 27.0927 63.1218 26.4781C63.2014 25.8635 64.6583 25.522 66.3883 25.7383Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.3655 25.8521C66.9118 25.9204 67.4126 26.0342 67.8451 26.1708C67.6289 26.1252 67.4012 26.0911 67.1622 26.057C65.5233 25.8521 64.1461 26.148 64.0664 26.7057C64.0209 27.0813 64.5672 27.4796 65.4208 27.7528C64.1234 27.491 63.2015 26.9675 63.2697 26.4894C63.338 25.9318 64.7266 25.6358 66.3655 25.8407V25.8521Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.4608 26.2049L47.1877 26.9447C46.9714 27.5252 45.4805 27.491 43.8643 26.8537C42.2481 26.2163 41.11 25.2261 41.3262 24.6457L41.5994 23.9059C43.557 24.6798 45.5032 25.4424 47.4608 26.2163V26.2049Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.9114 23.9855C46.5276 24.6229 47.6657 25.6131 47.4495 26.1935C47.2332 26.774 45.7422 26.7399 44.1261 26.1025C42.5099 25.4651 41.3717 24.4749 41.588 23.8945C41.8042 23.314 43.2952 23.3482 44.9114 23.9855Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.8772 24.0993C45.3894 24.3042 45.856 24.5318 46.2316 24.7822C46.0268 24.6798 45.8219 24.5888 45.6057 24.5091C44.0692 23.9059 42.6692 23.8376 42.4757 24.3725C42.3505 24.7253 42.783 25.2489 43.557 25.7383C42.3619 25.1578 41.588 24.418 41.7587 23.9628C41.9522 23.4392 43.3521 23.4961 44.8886 24.0993H44.8772Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.3062 23.3595L50.2721 24.1449C50.2493 24.7708 48.8266 25.2489 47.0852 25.2147C45.3439 25.1806 43.9553 24.657 43.9781 24.0311L44.0122 23.2457C46.1064 23.2799 48.212 23.3254 50.3062 23.3595Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.2104 22.1645C48.9518 22.1986 50.3403 22.7335 50.3176 23.3481C50.2948 23.9741 48.8721 24.4522 47.1308 24.418C45.3894 24.3839 44.0009 23.8603 44.0236 23.2343C44.0464 22.6083 45.4691 22.1303 47.2104 22.1645Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.1991 22.2783C47.7454 22.2897 48.2575 22.3466 48.7014 22.449C48.4852 22.4263 48.2575 22.4149 48.0185 22.4149C46.3682 22.3807 45.0138 22.8132 44.991 23.3709C44.9797 23.7465 45.5601 24.0879 46.4365 24.2814C45.1162 24.1562 44.1488 23.7351 44.1716 23.2457C44.1943 22.688 45.5487 22.2555 47.1991 22.2897V22.2783Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.1547 24.0879L54.8133 24.7936C54.5401 25.3513 53.0719 25.1464 51.524 24.327C49.9762 23.5075 48.9518 22.3921 49.225 21.8344L49.5664 21.1288C51.433 22.1076 53.2882 23.0978 55.1547 24.0766V24.0879Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.8557 21.6068C54.4036 22.4263 55.4279 23.5416 55.1547 24.0993C54.8816 24.657 53.4134 24.4522 51.8655 23.6327C50.3176 22.8132 49.2933 21.6978 49.5664 21.1401C49.8396 20.5825 51.3078 20.7873 52.8557 21.6068Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.7988 21.6978C53.2882 21.9596 53.7207 22.2441 54.0735 22.5287C53.88 22.4035 53.6865 22.2897 53.4703 22.1872C52.0021 21.4133 50.6135 21.1971 50.3745 21.6978C50.2152 22.0393 50.6021 22.6084 51.3078 23.1774C50.181 22.4604 49.4867 21.6409 49.6916 21.2084C49.9306 20.7076 51.3192 20.9239 52.7874 21.6978H52.7988Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.4938 31.9298L68.1069 32.7379C67.7996 33.3753 66.1265 33.1362 64.3623 32.2143C62.6096 31.2811 61.4373 30.0177 61.7446 29.3804L62.1316 28.5723C64.2485 29.6877 66.3769 30.8144 68.4938 31.9298Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.8761 29.0958C67.6288 30.0291 68.8011 31.2924 68.4938 31.9298C68.1865 32.5672 66.5134 32.3282 64.7493 31.4062C62.9966 30.473 61.8243 29.2096 62.1316 28.5723C62.4389 27.9349 64.112 28.1739 65.8761 29.0958Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.8192 29.2096C66.3769 29.5055 66.8663 29.8356 67.276 30.1543C67.0598 30.0177 66.8321 29.8811 66.5931 29.7559C64.9314 28.8796 63.3494 28.6292 63.0649 29.1982C62.8828 29.5852 63.3153 30.234 64.1233 30.8827C62.8372 30.0746 62.0519 29.1299 62.2909 28.6405C62.5641 28.0715 64.1461 28.3219 65.8192 29.1982V29.2096Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.5193 27.5593L48.4169 28.4471C48.3372 29.1527 46.6755 29.5625 44.7065 29.3576C42.7375 29.1527 41.2124 28.4129 41.2921 27.7073L41.3945 26.8195C43.7618 27.0699 46.1406 27.3203 48.5193 27.5707V27.5593Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.1049 25.8976C47.0739 26.1025 48.599 26.8423 48.5193 27.5479C48.4396 28.2536 46.7779 28.6633 44.8089 28.4585C42.8399 28.2536 41.3148 27.5138 41.3945 26.8081C41.4742 26.1025 43.1359 25.6927 45.1049 25.8976Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0935 26.0228C45.7195 26.0911 46.2885 26.2049 46.7779 26.3643C46.5275 26.3187 46.2772 26.2846 46.004 26.2505C44.1374 26.057 42.5668 26.4098 42.4871 27.0472C42.4416 27.4683 43.0676 27.9122 44.0464 28.2195C42.5668 27.9463 41.5083 27.3658 41.5766 26.8195C41.6449 26.1822 43.2155 25.8293 45.0821 26.0228H45.0935Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.254 25.3058L53.1516 26.1935C53.0719 26.8992 51.4102 27.3089 49.4412 27.1041C47.4722 26.8992 45.9471 26.1594 46.0268 25.4537L46.1292 24.566C48.4966 24.8164 50.8753 25.0668 53.254 25.3172V25.3058Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.8396 23.6555C51.8086 23.8603 53.3337 24.6001 53.254 25.3058C53.1744 26.0114 51.5127 26.4212 49.5437 26.2163C47.5747 26.0114 46.0495 25.2716 46.1292 24.566C46.2089 23.8603 47.8706 23.4506 49.8396 23.6555Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.8168 23.7806C50.4428 23.8489 51.0119 23.9627 51.5013 24.1221C51.2509 24.0766 51.0005 24.0424 50.7273 24.0083C48.8608 23.8148 47.2901 24.1676 47.2218 24.805C47.1763 25.2261 47.8023 25.67 48.7811 25.9773C47.3015 25.7041 46.243 25.1237 46.3113 24.5773C46.3796 23.94 47.9502 23.5872 49.8168 23.7806Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.7022 24.8505L63.5429 25.4993C63.4177 26.0114 62.1657 26.1366 60.743 25.7724C59.3203 25.4082 58.2733 24.7026 58.3985 24.1904L58.5578 23.5417C60.2764 23.9742 61.9836 24.4066 63.7022 24.8391V24.8505Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.3576 23.2799C62.7803 23.6441 63.8274 24.3497 63.7022 24.8619C63.577 25.3741 62.3251 25.4993 60.9024 25.1351C59.4797 24.7708 58.4326 24.0652 58.5578 23.553C58.683 23.0409 59.935 22.9157 61.3576 23.2799Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.3349 23.3709C61.7787 23.4847 62.1885 23.6327 62.5413 23.792C62.3706 23.7351 62.1771 23.6782 61.9836 23.6327C60.6406 23.2912 59.4569 23.3937 59.3431 23.8489C59.2634 24.1562 59.6846 24.5432 60.3788 24.8733C59.3203 24.5091 58.6033 23.9741 58.6944 23.5872C58.8082 23.1205 59.9919 23.0295 61.3349 23.3709Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.2748 26.0797L59.1155 26.7285C58.9903 27.2406 57.7383 27.3658 56.3156 27.0016C54.893 26.6374 53.8459 25.9318 53.9711 25.4196L54.1304 24.7708C55.8376 25.2033 57.5562 25.6358 59.2748 26.0797Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.9302 24.4977C58.3529 24.8619 59.4 25.5675 59.2748 26.0797C59.1496 26.5919 57.8977 26.7171 56.475 26.3529C55.0523 25.9887 54.0052 25.283 54.1304 24.7708C54.2556 24.2587 55.5076 24.1335 56.9302 24.4977Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.9075 24.5887C57.3514 24.7025 57.7611 24.8505 58.1139 25.0098C57.9432 24.9529 57.7497 24.896 57.5562 24.8505C56.2132 24.509 55.0295 24.6001 54.9157 25.0667C54.836 25.374 55.2572 25.761 55.9514 26.0911C54.893 25.7269 54.1759 25.1919 54.267 24.805C54.3808 24.3383 55.5645 24.2473 56.9075 24.5887Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.5951 32.9883L60.3675 33.8988C60.3675 33.9102 60.3561 33.9216 60.3561 33.9329L53.2768 32.6354C53.1288 32.4306 53.0719 32.2371 53.1174 32.055L53.3451 31.1445C55.7579 31.7591 58.1822 32.3737 60.5951 32.9883Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.2831 30.7689C59.2862 31.2811 60.7658 32.2712 60.5951 32.9997C60.4927 33.4322 59.8211 33.6598 58.8879 33.6825L55.6897 33.1021C54.1987 32.5558 53.1971 31.7705 53.3451 31.1672C53.5272 30.4502 55.2913 30.2681 57.2944 30.7803L57.2831 30.7689Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.2603 30.8941C57.8977 31.0534 58.4667 31.2583 58.9561 31.4973C58.7057 31.4176 58.444 31.3379 58.1708 31.2697C56.2701 30.7916 54.597 30.9282 54.4377 31.577C54.3353 32.0095 54.9271 32.5671 55.8945 33.0224C54.4035 32.5102 53.3906 31.7591 53.5272 31.2014C53.6865 30.5526 55.3596 30.416 57.2603 30.8941Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.4636 29.1983L56.2701 30.0063C56.1108 30.6437 54.5401 30.8031 52.7532 30.3478C50.9663 29.8925 49.6575 29.0048 49.8168 28.3674L50.0103 27.5593C52.1614 28.1056 54.3125 28.652 56.4636 29.1983Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.5272 27.2179C55.3141 27.6731 56.6229 28.5609 56.4636 29.1983C56.3043 29.8356 54.7336 29.995 52.9581 29.5397C51.1712 29.0844 49.8623 28.1967 50.0217 27.5593C50.181 26.922 51.7517 26.7626 53.5386 27.2179H53.5272Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.5044 27.3317C54.0735 27.4797 54.5857 27.6618 55.0182 27.8666C54.8019 27.787 54.5629 27.7187 54.3239 27.6618C52.628 27.2293 51.1484 27.3545 51.0005 27.9349C50.9094 28.3219 51.433 28.8113 52.298 29.221C50.9777 28.7658 50.0672 28.0943 50.1924 27.6048C50.329 27.0244 51.82 26.8992 53.5158 27.3317H53.5044Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.3298 32.9542L65.1249 33.7622C64.9656 34.3996 63.3949 34.5589 61.6194 34.1037C59.8325 33.6484 58.5237 32.7607 58.683 32.1233L58.8765 31.3152C61.0276 31.8615 63.1787 32.4078 65.3298 32.9542Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.3934 30.9738C64.1689 31.429 65.4891 32.3168 65.3298 32.9541C65.1704 33.5915 63.5998 33.7509 61.8243 33.2956C60.0374 32.8403 58.7285 31.9526 58.8879 31.3152C59.0472 30.6778 60.6178 30.5185 62.4047 30.9738H62.3934Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.3592 31.0876C62.9169 31.2356 63.4405 31.4177 63.8729 31.6225C63.6453 31.5429 63.4177 31.4746 63.1787 31.4177C61.4828 30.9852 60.0032 31.1104 59.8553 31.6908C59.7642 32.0778 60.2878 32.5672 61.1528 32.9769C59.8211 32.5217 58.922 31.8502 59.0472 31.3607C59.1838 30.7803 60.6748 30.6665 62.3706 31.0876H62.3592Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.9138 30.1315L60.7544 30.7803C60.6292 31.2924 59.3773 31.4176 57.9546 31.0534C56.5319 30.6892 55.4848 29.9836 55.61 29.4714L55.7693 28.8227C57.4766 29.2552 59.1952 29.6877 60.9138 30.1202V30.1315Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5692 28.5495C59.9919 28.9137 61.039 29.6194 60.9138 30.1315C60.7886 30.6437 59.5366 30.7689 58.1139 30.4047C56.6912 30.0405 55.6441 29.3348 55.7693 28.8227C55.8945 28.3105 57.1465 28.1853 58.5692 28.5495Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5464 28.6405C58.9903 28.7544 59.4 28.9023 59.7529 29.0617C59.5821 29.0047 59.3886 28.9478 59.1952 28.9023C57.8521 28.5609 56.6685 28.6633 56.5546 29.1186C56.475 29.4259 56.8961 29.8128 57.5904 30.1429C56.5319 29.7787 55.8149 29.2438 55.9059 28.8568C56.0197 28.3901 57.2034 28.2991 58.5464 28.6405Z'
      fill='#F2F2F2'
    />
    <path d='M88 54L88 46L12 46L12 54L88 54Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M86 55L88 54H12L14 55H86Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.4278 37.8596H73.9797L82 44H18L26.4392 37.8596H26.4278Z'
      fill='#1A1A1A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 44H82L74 38L26.4961 37.962L18 44ZM88 46H12L25 37C41.6625 37 58.3375 37 75 37L88 46Z'
      fill='#FFFF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.4679 30.9055L37.6045 31.7136C37.3427 31.6908 37.0695 31.668 36.785 31.668C35.6924 31.668 34.6908 31.8843 33.9282 32.2485C33.9965 31.9753 33.9738 31.7591 33.8486 31.5998L33.2567 30.8941C32.7901 30.3364 31.0715 30.9965 29.4212 32.3737C28.3172 33.2956 27.5205 34.3086 27.2473 35.037C27.2132 35.037 27.179 35.037 27.1563 35.037C24.7775 35.037 22.8541 36.858 22.8541 39.1002C22.8541 40.5798 23.6963 41.8772 24.9482 42.5943C23.8215 42.5943 23.0134 42.8561 22.9223 43.3227L22 44H77C77.2959 43.8293 76.7568 43.5162 76.7682 43.2999L76.8023 42.378C76.8251 41.8203 75.7894 41.2968 74.3325 41.0578C75.2089 40.318 75.7552 39.2367 75.7552 38.0417C75.7552 36.0158 74.1846 34.3313 72.1359 34.024C72.2611 33.3297 70.8726 32.2257 68.9377 31.4859C66.9232 30.712 65.0908 30.6437 64.829 31.3266L64.6241 31.8729C64.4989 31.7705 64.351 31.6794 64.203 31.5884C64.0778 30.9055 62.6551 30.0177 60.7772 29.5169C58.6944 28.9479 56.862 29.0617 56.6685 29.7673L56.5091 30.3364C55.849 30.0746 55.0864 29.9153 54.2556 29.9153C53.6638 29.9153 53.0947 29.995 52.5825 30.1429C52.9581 28.6178 52.8898 27.3886 52.3321 27.1837L51.4671 26.8651C50.7842 26.6147 49.6233 28.0487 48.8835 30.0633C48.8266 30.234 48.7583 30.4047 48.7128 30.564C48.36 30.4388 47.973 30.3136 47.5633 30.2112C46.6983 29.995 45.8788 29.9039 45.2073 29.9039L45.1504 29.5966C45.0252 28.8796 43.2042 28.595 41.0872 28.9479C38.9702 29.3121 37.3427 30.1885 37.4679 30.9055Z'
      fill='#F6D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.7674 42.5032L61.6991 43.8007C61.6991 43.8349 61.6991 43.869 61.6991 43.9032L72.5 44L72.7619 43.1178C69.0971 42.913 65.4322 42.7081 61.7674 42.5032Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3671 40.944C64.3282 40.7732 61.8243 41.4675 61.7674 42.4918C61.7332 43.004 62.6346 43.613 63.6134 44H72C72.3756 43.7724 72.7505 43.4024 72.7619 43.1064C72.8188 42.0821 70.4059 41.1147 67.3671 40.944Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3557 41.1261C66.3996 41.0692 65.4891 41.1033 64.7152 41.2057C65.1022 41.1944 65.5005 41.2057 65.9216 41.2285C68.8011 41.3879 71.1002 42.2642 71.0433 43.1975C71.0319 43.4593 70.827 43.6983 70.4856 43.9032H71.7262C72.1928 43.6755 72.466 43.4024 72.4887 43.0951C72.5343 42.1732 70.2466 41.2854 67.3671 41.1261H67.3557Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.6296 42.9471L54 44H63.5C64.3764 43.5106 64.7835 42.8674 64.7038 42.378L64.4876 41.0919C60.8682 41.7065 57.2489 42.3211 53.6296 42.9471Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.7399 40.1928C55.7466 40.705 53.4589 41.9342 53.6296 42.9585C53.7093 43.4024 54.1805 43.8065 55 44H59C59.0797 43.9886 59.2862 43.8918 59.3659 43.869C62.3592 43.3568 64.6469 42.1276 64.4762 41.1033C64.3055 40.0904 61.7332 39.6806 58.7399 40.1928Z'
      fill='#F6EE00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.774 40.3749C57.8294 40.5342 56.953 40.7732 56.2132 41.0464C56.5888 40.9553 56.9871 40.8757 57.3855 40.796C60.2309 40.3066 62.6665 40.6594 62.8259 41.5699C62.9283 42.1732 61.995 42.8902 60.5154 43.4365C62.7576 42.8788 64.3396 41.9342 64.2144 41.1488C64.0551 40.2383 61.6308 39.8855 58.774 40.3749Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.3741 43.1633L28.5 44H39C39.0114 43.9317 39.4483 43.7552 39.4483 43.6869L39.3572 42.3894C35.6924 42.6512 32.0389 42.9016 28.3741 43.152V43.1633Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7461 40.9326C30.7187 41.1488 28.3172 42.1504 28.3855 43.1747C28.4082 43.4479 28.6699 43.7951 29 44H38C39.0812 43.5789 39.4141 42.9471 39.3686 42.4008C39.3003 41.3765 36.785 40.7163 33.7461 40.9326Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7575 41.1147C32.8015 41.183 31.9023 41.3309 31.1398 41.5244C31.5267 41.4675 31.9251 41.422 32.3348 41.3992C35.2143 41.1944 37.6045 41.7862 37.6614 42.7081C37.6955 43.1292 37.2853 43.6472 36.5 44H37C38.2406 43.613 39.1182 42.9699 39.0841 42.4236C39.0158 41.5017 36.637 40.9098 33.7575 41.1147Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.241 43.3341L23.5 44H27.5C27.8187 43.852 28.431 43.5958 28.7611 43.4137C31.0715 42.1276 32.5966 40.4546 32.1527 39.6806L31.6064 38.6904C28.818 40.2383 26.0295 41.7862 23.241 43.3341Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.6441 39.601C24.3336 40.8871 22.8085 42.5601 23.2524 43.3341C23.6849 44.1194 25.9157 43.7097 28.2147 42.4236C30.5252 41.1375 32.0389 39.4644 31.6064 38.6904C31.1739 37.9051 28.9432 38.3148 26.6441 39.601Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.7238 39.7375C25.9953 40.1472 25.3466 40.5911 24.823 41.0236C25.1076 40.8415 25.4035 40.6594 25.7108 40.4887C27.9074 39.2709 30.0016 38.8498 30.3886 39.5554C30.6504 40.022 30.0813 40.8757 29.0342 41.752C30.7187 40.648 31.743 39.4188 31.4015 38.8156C31.0146 38.11 28.9204 38.5311 26.7238 39.7489V39.7375Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.6394 35.2646L23.5711 36.3914C23.5256 37.2791 25.6198 38.1213 28.2603 38.2693C30.9008 38.4173 33.0746 37.814 33.1315 36.9263L33.1998 35.7995C30.013 35.6174 26.8262 35.4467 23.6508 35.2646H23.6394Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.4993 33.9102C25.8588 33.7622 23.6849 34.3654 23.628 35.2646C23.5825 36.1523 25.6767 36.9946 28.3172 37.1425C30.9577 37.2905 33.1315 36.6873 33.1885 35.7881C33.234 34.9004 31.1398 34.0582 28.4993 33.9102Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.4879 34.0695C27.657 34.024 26.8717 34.0468 26.1888 34.1378C26.5303 34.1264 26.8717 34.1378 27.2359 34.1606C29.7399 34.2971 31.7316 35.0711 31.6861 35.8678C31.6519 36.4027 30.73 36.8238 29.3643 36.9946C31.3788 36.949 32.8925 36.4596 32.9381 35.7767C32.9836 34.9687 30.9918 34.2061 28.4879 34.0695Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.2893 32.1574L32.221 33.2842C32.1755 34.172 34.2697 35.0142 36.9102 35.1622C39.5507 35.3101 41.7246 34.7069 41.7815 33.8191L41.8498 32.6924C38.6629 32.5103 35.4761 32.3395 32.3007 32.1574H32.2893Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1606 30.8144C34.5201 30.6665 32.3462 31.2697 32.3007 32.1575C32.2552 33.0452 34.3494 33.8988 36.9899 34.0354C39.6304 34.1834 41.8042 33.5801 41.8611 32.6924C41.9067 31.8046 39.8125 30.9624 37.172 30.8144H37.1606Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1492 30.9738C36.3184 30.9283 35.533 30.951 34.8501 31.0421C35.1916 31.0307 35.533 31.0421 35.8972 31.0648C38.4012 31.2014 40.3929 31.9754 40.3474 32.7721C40.3133 33.307 39.3914 33.7281 38.0256 33.8988C40.0401 33.8647 41.5538 33.3639 41.5994 32.681C41.6449 31.8729 39.6531 31.1104 37.1492 30.9738Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.3639 36.3344L65.5688 37.4384C65.7281 38.3148 67.9703 38.6449 70.5653 38.1555C73.1603 37.6775 75.1406 36.5735 74.9699 35.6971L74.7651 34.5817C71.6351 35.1622 68.4938 35.7426 65.3526 36.3231L65.3639 36.3344Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.7686 33.876C67.1736 34.3541 65.1932 35.4581 65.3639 36.3344C65.5233 37.2108 67.7654 37.5409 70.3604 37.0515C72.9554 36.5735 74.9358 35.4695 74.7651 34.5931C74.6057 33.7167 72.3636 33.3866 69.7686 33.876Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.8027 34.024C68.9833 34.1719 68.2207 34.3882 67.5833 34.6386C67.9134 34.5475 68.2548 34.4792 68.6077 34.411C71.0774 33.9557 73.1944 34.2289 73.3424 35.0142C73.4448 35.5377 72.6367 36.1637 71.362 36.6645C73.3082 36.1523 74.674 35.3101 74.5488 34.6386C74.4008 33.8419 72.2839 33.5687 69.8141 34.0354L69.8027 34.024Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.8698 31.5884L61.6991 32.7038C61.5625 33.5915 63.5657 34.6272 66.1834 35.037C68.7898 35.4353 71.0205 35.0484 71.1571 34.1606L71.3278 33.0452C68.1752 32.5558 65.0225 32.0778 61.8698 31.5884Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8435 30.7234C64.2372 30.325 62.0064 30.712 61.8698 31.5998C61.7332 32.4875 63.7364 33.5232 66.3541 33.933C68.9605 34.3313 71.1912 33.9443 71.3278 33.0566C71.4644 32.1688 69.4613 31.1331 66.8435 30.7234Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8208 30.8827C66.0013 30.7575 65.216 30.712 64.5331 30.7348C64.8631 30.7575 65.216 30.8031 65.5688 30.86C68.05 31.2469 69.9621 32.203 69.8369 32.9883C69.7572 33.5232 68.7898 33.8419 67.424 33.8874C69.4271 34.0468 70.9978 33.694 71.1002 33.0224C71.2254 32.2257 69.3133 31.2697 66.8321 30.8941L66.8208 30.8827Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.4149 31.3835L54.7336 32.4647C54.984 33.3184 57.2489 33.4094 59.7756 32.6582C62.3023 31.9071 64.1575 30.6096 63.8957 29.7446L63.577 28.6633C60.5154 29.5738 57.4652 30.473 54.4036 31.3835H54.4149Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5464 28.4699C56.0197 29.221 54.1645 30.5185 54.4263 31.3835C54.6767 32.2371 56.9416 32.3282 59.4683 31.577C62.0064 30.8258 63.8502 29.5283 63.5998 28.6633C63.3494 27.8097 61.0845 27.7187 58.5578 28.4699H58.5464Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5919 28.6178C57.7952 28.8568 57.0668 29.1414 56.4522 29.4601C56.7709 29.3462 57.101 29.2324 57.4424 29.13C59.8439 28.413 61.9836 28.4699 62.2113 29.2324C62.3592 29.7446 61.6308 30.4502 60.413 31.0762C62.2909 30.3592 63.5657 29.3804 63.3608 28.7203C63.1332 27.9463 60.9934 27.9008 58.5919 28.6178Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.3007 38.975L32.6649 40.2042C32.9494 41.183 35.5217 41.274 38.4126 40.4204C41.2921 39.5668 43.3976 38.0872 43.1131 37.1084L42.7489 35.8792C39.2662 36.9149 35.7834 37.9393 32.3121 38.975H32.3007Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.0012 35.663C34.1217 36.5166 32.0162 37.9962 32.3007 38.975C32.5852 39.9538 35.1574 40.0448 38.0483 39.1912C40.9279 38.3376 43.0334 36.858 42.7489 35.8792C42.4644 34.9004 39.8921 34.8094 37.0012 35.663Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.0468 35.8337C36.1362 36.1068 35.3054 36.4369 34.6111 36.7897C34.964 36.6531 35.3509 36.5279 35.7379 36.4141C38.4808 35.606 40.9051 35.663 41.1669 36.5393C41.3376 37.1198 40.5067 37.9279 39.1182 38.6449C41.2579 37.8254 42.7034 36.7214 42.4757 35.9589C42.214 35.0825 39.7897 35.0256 37.0468 35.8337Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.4063 37.962L63.3153 39.2481C63.247 40.2611 65.6143 41.2626 68.619 41.4789C71.6124 41.6951 74.1049 41.0464 74.1846 40.0334L74.2756 38.7473C70.6563 38.4856 67.037 38.2238 63.4177 37.962H63.4063Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.9719 36.5166C65.9671 36.3003 63.486 36.9491 63.4063 37.962C63.338 38.975 65.7054 39.9765 68.7101 40.1928C71.7148 40.409 74.196 39.7603 74.2756 38.7473C74.3439 37.7344 71.9766 36.7328 68.9719 36.5166Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.9491 36.6987C68.0044 36.6304 67.1053 36.6531 66.3314 36.7328C66.7183 36.7328 67.1167 36.7442 67.515 36.7783C70.3604 36.9832 72.6139 37.8937 72.557 38.8042C72.5115 39.4075 71.4416 39.8741 69.8938 40.0448C72.1814 40.0334 73.9228 39.4985 73.9797 38.7246C74.048 37.8141 71.7945 36.9035 68.9377 36.6987H68.9491Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.9302 33.5915L56.8392 34.8776C56.7709 35.8906 59.1382 36.8922 62.143 37.1084C65.1363 37.3247 67.6288 36.6759 67.7085 35.663L67.7996 34.3768C64.1803 34.1151 60.5609 33.8533 56.9416 33.5915H56.9302Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.4958 32.1347C59.4911 31.9184 57.0099 32.5672 56.9302 33.5801C56.862 34.5931 59.2293 35.5947 62.234 35.8109C65.2274 36.0272 67.7199 35.3784 67.7996 34.3655C67.8679 33.3525 65.5005 32.3509 62.5072 32.1347H62.4958Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.4844 32.3168C61.5398 32.2485 60.6406 32.2713 59.8667 32.3509C60.2536 32.3509 60.652 32.3623 61.0503 32.3965C63.8957 32.6013 66.1493 33.5119 66.0923 34.4224C66.0468 35.037 64.977 35.4922 63.4291 35.6743C65.7168 35.663 67.4581 35.128 67.515 34.3427C67.5833 33.4322 65.3298 32.5217 62.473 32.3168H62.4844Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.4173 30.2567L41.4742 31.2014C41.5197 31.9526 43.3635 32.4534 45.5829 32.3054C47.8023 32.1688 49.5664 31.4404 49.5209 30.6892L49.464 29.7446C46.778 29.9153 44.0919 30.086 41.4173 30.2567Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3553 28.6406C43.1359 28.7771 41.3717 29.5056 41.4173 30.2567C41.4628 31.0079 43.3066 31.5087 45.526 31.3607C47.7454 31.2242 49.5095 30.4957 49.464 29.7446C49.4185 28.9934 47.5747 28.4926 45.3553 28.6406Z'
      fill='#F6EE00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3667 28.7772C44.661 28.8227 44.0123 28.9251 43.4546 29.0617C43.7391 29.0275 44.0236 28.9934 44.3309 28.9706C46.4479 28.8341 48.1893 29.2779 48.2348 29.9608C48.2689 30.4161 47.5291 30.86 46.4024 31.1445C48.0868 30.9055 49.3046 30.3478 49.2705 29.7673C49.225 29.0845 47.4836 28.652 45.3667 28.7772Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7112 33.1135L47.7681 34.0582C47.8137 34.8093 49.6575 35.3101 51.8769 35.1622C54.0963 35.0256 55.8604 34.2972 55.8149 33.546L55.7579 32.6013C53.0719 32.772 50.3859 32.9428 47.7112 33.1135Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.6492 31.4973C49.4298 31.6339 47.6657 32.3623 47.7112 33.1135C47.7568 33.8647 49.6006 34.3654 51.82 34.2175C54.0393 34.0809 55.8035 33.3525 55.758 32.6013C55.7124 31.8501 53.8686 31.3493 51.6492 31.4973Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.6606 31.6339C50.955 31.6794 50.3062 31.7818 49.7485 31.9184C50.0331 31.8843 50.3176 31.8501 50.6249 31.8274C52.7305 31.6908 54.4832 32.1347 54.5287 32.8175C54.5629 33.2728 53.8231 33.7167 52.6963 34.0012C54.3808 33.7622 55.5986 33.2045 55.5645 32.6241C55.5189 31.9412 53.7776 31.5087 51.6606 31.6339Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.8415 42.5032C43.852 42.6584 43.9445 44.0281 44 44H55C55.2732 43.7496 55.3027 43.391 55.2913 43.1292L55.2116 41.7862C51.4216 42.0252 47.6316 42.2642 43.8529 42.5032H43.8415Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.3957 40.2269C46.2544 40.4318 43.7732 41.4448 43.8415 42.5146C43.8871 43.1406 44.5887 43.7382 46 44H51C53.3787 43.6244 55.2685 42.674 55.2116 41.7862C55.1433 40.7277 52.5484 40.0221 49.4071 40.2269H49.3957Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.4071 40.409C48.4169 40.4773 47.495 40.6139 46.6983 40.8188C47.0966 40.7619 47.5064 40.7277 47.9389 40.6936C50.9208 40.5001 53.3792 41.1261 53.4475 42.0821C53.493 42.7195 52.4459 43.3455 50.8639 43.7438C53.2312 43.4138 54.9612 42.6171 54.9157 41.7976C54.8588 40.8415 52.389 40.2156 49.4071 40.409Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.0574 38.2238L51.1371 39.4188C51.194 40.3635 53.5044 40.9895 56.2929 40.8074C59.0813 40.6253 61.3007 39.7261 61.2324 38.7815L61.1528 37.5864C57.7839 37.8026 54.4149 38.0189 51.046 38.2238H51.0574Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.997 36.1979C53.2085 36.3686 50.9891 37.2791 51.0574 38.2238C51.1143 39.1684 53.4247 39.7944 56.2246 39.6123C59.0131 39.4302 61.2325 38.5311 61.1642 37.5864C61.1073 36.6417 58.7968 36.0158 55.997 36.1979Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.0083 36.3686C55.132 36.4255 54.3011 36.5507 53.6068 36.7328C53.9597 36.6873 54.3239 36.6417 54.7108 36.619C57.3627 36.4483 59.548 37.006 59.6049 37.8596C59.639 38.4286 58.7171 38.9863 57.3058 39.3392C59.4114 39.0432 60.9479 38.3376 60.9024 37.6092C60.8455 36.7556 58.6602 36.2093 56.0083 36.3686Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M36.7509 41.2285L36.8305 42.4235C36.8874 43.3682 39.1979 43.9942 41.9863 43.8121C44.7748 43.63 46.9942 42.7308 46.9259 41.7862L46.8462 40.5911C43.4773 40.8074 40.1084 41.0236 36.7395 41.2285H36.7509Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.7018 39.2026C38.9133 39.3847 36.6939 40.2838 36.7622 41.2285C36.8191 42.1732 39.1296 42.7992 41.9294 42.617C44.7179 42.4463 46.9373 41.5358 46.869 40.5911C46.8121 39.6465 44.5016 39.0205 41.7018 39.2026Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.7132 39.3733C40.8368 39.4302 40.006 39.5554 39.3117 39.7375C39.6645 39.692 40.0287 39.6465 40.4157 39.6237C43.0676 39.453 45.2528 40.0107 45.3097 40.8643C45.3439 41.4334 44.422 41.9911 43.0107 42.3439C45.1163 42.048 46.6528 41.3423 46.6072 40.6139C46.5503 39.7603 44.3651 39.214 41.7132 39.3733Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.1374 38.3945L44.1943 39.3392C44.2399 40.0904 46.0837 40.5912 48.3031 40.4432C50.5225 40.3066 52.2866 39.5782 52.2411 38.827L52.1842 37.8824C49.4981 38.0531 46.8121 38.2238 44.1374 38.3945Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.0754 36.7783C45.8561 36.9149 44.0919 37.6433 44.1374 38.3945C44.183 39.1457 46.0268 39.6465 48.2462 39.4985C50.4656 39.3619 52.2297 38.6335 52.1842 37.8823C52.1386 37.1312 50.2948 36.6304 48.0754 36.7783Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.0868 36.9149C47.3812 36.9604 46.7324 37.0629 46.1747 37.1994C46.4593 37.1653 46.7438 37.1312 47.0511 37.1084C49.1567 36.9718 50.9094 37.4157 50.955 38.0986C50.9777 38.5538 50.2493 38.9977 49.1225 39.2823C50.807 39.0432 52.0248 38.4856 51.9907 37.9051C51.9451 37.2222 50.2038 36.7897 48.0868 36.9149Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.8831 44.1536L25.6539 42.0252L28.1009 39.5896L26.8831 44.1422V44.1536Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.8831 44.1535L25.6539 42.0252L22.3191 42.9243L26.8717 44.1422L26.8831 44.1535Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.5483 38.3717L25.6539 42.0252L22.3191 42.9243L23.5369 38.3717H23.5483Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.5483 38.3717L25.6539 42.0252L28.1009 39.5896L23.5483 38.3717Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.0794 36.2206L65.7168 33.8533L69.1653 33.8647L65.0794 36.2206Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.0794 36.2206L65.7168 33.8532L62.7234 32.1346L65.0794 36.2206Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8094 29.7787L65.7168 33.8533L62.7234 32.1347L66.8094 29.7787Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8094 29.7787L65.7168 33.8533L69.1653 33.8646L66.8094 29.7787Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.661 43.4479L74.8902 41.3195L72.4432 38.8839L73.661 43.4365V43.4479Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.661 43.4479L74.8902 41.3195L78.2136 42.2187L73.661 43.4365V43.4479Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.9958 37.6661L74.8902 41.3195L78.2136 42.2187L76.9958 37.6661Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.9958 37.6661L74.8902 41.3195L72.4432 38.8839L76.9958 37.6661Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.3698 36.2207L46.1406 34.0923L42.8058 34.9915L47.3584 36.2093L47.3698 36.2207Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.3698 36.2207L46.1406 34.0923L48.5876 31.6567L47.3698 36.2093V36.2207Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.035 30.4388L46.1406 34.0923L48.5876 31.6567L44.035 30.4388Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.035 30.4388L46.1406 34.0923L42.8058 34.9914L44.0236 30.4388H44.035Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7006 35.1849L32.7332 33.5005L30.0927 34.2175L33.7006 35.1849Z'
      fill='#396605'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7006 35.1849L32.7332 33.5005L34.668 31.577L33.7006 35.1849Z'
      fill='#74DA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.0487 30.6096L32.7218 33.5005L34.6567 31.577L31.0487 30.6096Z'
      fill='#8AFF05'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.0487 30.6096L32.7218 33.5005L30.0813 34.2175L31.0487 30.6096Z'
      fill='#4F9300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.1104 42.1504L64.0778 40.4659L66.7183 41.1829L63.1104 42.1504Z'
      fill='#396605'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.1104 42.1504L64.0778 40.4659L62.143 38.5424L63.1104 42.1504Z'
      fill='#74DA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.7509 37.575L64.0778 40.4659L62.143 38.5424L65.7509 37.575Z'
      fill='#8AFF05'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.7509 37.575L64.0778 40.4659L66.7183 41.1829L65.7509 37.575Z'
      fill='#4F9300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.5401 30.6095L55.5076 28.9251L58.1481 29.6421L54.5401 30.6095Z'
      fill='#396605'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.5401 30.6096L55.5076 28.9251L53.5727 27.0016L54.5401 30.6096Z'
      fill='#74DA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.1806 26.0228L55.5076 28.9137L53.5727 26.9902L57.1806 26.0228Z'
      fill='#8AFF05'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.1806 26.0228L55.5076 28.9137L58.1481 29.6307L57.1806 26.0228Z'
      fill='#4F9300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44 18H56L54 24H47L44 18Z'
      fill='#FFD900'
    />
    <path d='M54 24H47V25H54V24Z' fill='#FCF211' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47 24L44 18V19L47 25V24Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54 24L56 18V19L54 25V24Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.5 16L52 17.5L51 18.5L53 23H48L50 18.5L49 17.5L50.5 16Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.5 17.5L50.5 18.5L52.5 23H48.5L50.5 18.5L49.5 17.5L50.5 16.5L51.5 17.5Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29 28.268L28.1479 27.913L28.2677 27L27.9151 27.851L27 27.732L27.8521 28.087L27.7323 29L28.0839 28.149L28.999 28.268H29Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69 28.732L69.9155 28.8509L70.268 28L70.147 28.9135L71 29.268L70.0845 29.1491L69.732 30L69.853 29.0865L69 28.732Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 42.366L34.9155 42.4254L35.268 42L35.147 42.4567L36 42.634L35.0845 42.5746L34.732 43L34.853 42.5433L34 42.366Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55 37L55.7651 35.9978L55 35L55.9978 35.7651L57 35L56.2349 36.0022L57 37L56.0022 36.2349L55 37Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56 24L56.3824 24.5011L56 25L56.4989 24.6176L57 25L56.6176 24.4989L57 24L56.5011 24.3824L56 24Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71 43.2677L71.8539 42.913L71.7323 42L72.0837 42.8506L73 42.7323L72.1461 43.087L72.2677 44L71.9163 43.1494L71 43.2677Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56 42L55.6164 42.5011L56 43L55.5011 42.6176L55 43L55.3824 42.4989L55 42L55.4989 42.3836L56 42Z'
      fill='white'
    />
  </svg>
);

export const ChestOpenIconActiveState = () => (
  <svg viewBox='0 0 100 93' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M50 93C77.6142 93 100 84.0457 100 73C100 61.9543 77.6142 53 50 53C22.3858 53 0 61.9543 0 73C0 84.0457 22.3858 93 50 93Z'
      fill='url(#paint0_radial_70_5134)'
    />
    <path d='M71.5 1H28.5L26 4H74L71.5 1Z' fill='#B6AA65' />
    <path d='M74 4L26 3.96075V15H74V4Z' fill='#6C632F' />
    <path d='M25 4H14V15H25V4Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26 15H25V4L26 4.00002V15Z'
      fill='#FFB900'
    />
    <path
      d='M28.2937 0.000335693H18L14 4H25L28.2937 0.000335693Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.5 1L28.2944 0L25 4H26L28.5 1Z'
      fill='#F6D900'
    />
    <path d='M74.9997 4H85.9997V15H74.9997V4Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.9997 15H74.9997V4L73.9997 4.00002V15Z'
      fill='#FFB900'
    />
    <path
      d='M71.706 0.000335693H81.9997L85.9997 4H74.9997L71.706 0.000335693Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.4997 1L71.7053 0L74.9997 4H73.9997L71.4997 1Z'
      fill='#F6D900'
    />
    <path d='M24 58H14V76H24V58Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25 58H24V76L25 75V58Z'
      fill='#FFB900'
    />
    <path d='M76 58H86V76H76V58Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75 58H76V76L75 75V58Z'
      fill='#FFB900'
    />
    <path d='M75 58H25V71.9652H75V58Z' fill='#6C632F' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.6511 20.2932H83.6084L73.2399 38.2304H27.0197L16.6511 20.2932Z'
      fill='#333333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.1179 39.8238H72.318H27.9416H25.1417L12 18H88L75.1179 39.8238ZM27.0197 38.2304H73.2399L83.6085 20.2932H16.6511L27.0197 38.2304Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.0197 38.2304L16.6511 20.2932H18.6201L28 37L27.0083 38.2304H27.0197Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.2399 38.2304L83.6085 20.2932H81.6395L71.9766 37.0126L73.2513 38.2304H73.2399Z'
      fill='#FFB900'
    />
    <path d='M88 15L88 18L12 18L12 15L88 15Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28 37.0126L33 24H67L72 37.0126H28Z'
      fill='#4D4D4D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.0177 20.2932L33.0291 24H67V20.2932H33.0291H33.0177Z'
      fill='#1A1A1A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.838 27.0652L48.8266 27.6343C48.9973 27.5887 49.1681 27.5546 49.3502 27.5318C50.0672 27.4408 50.7387 27.5091 51.2623 27.6912C51.194 27.5091 51.194 27.3497 51.2623 27.2359L51.5809 26.6896C51.8313 26.2571 53.015 26.5758 54.2214 27.3839C55.0295 27.9302 55.6441 28.5562 55.8831 29.0456C55.9059 29.0456 55.9287 29.0342 55.9514 29.0342C57.4993 28.8293 58.922 29.922 59.1269 31.4812C59.2635 32.5056 58.831 33.4844 58.0798 34.0762C58.8196 33.9851 59.3659 34.0876 59.4683 34.4063L59.5935 34.7932L24.732 39.3914C24.5271 39.3003 24.4019 39.1751 24.3792 39.0158L24.2767 38.3784C24.2084 37.9914 24.8344 37.5476 25.7677 37.2516C25.1303 36.8078 24.6751 36.1135 24.5613 35.2826C24.3792 33.8827 25.2442 32.5738 26.553 32.1869C26.4051 31.7202 27.2132 30.8325 28.4082 30.1496C29.6488 29.4439 30.8439 29.2391 31.0715 29.6829L31.2536 30.0471C31.3333 29.9675 31.4129 29.8878 31.504 29.8081C31.5267 29.3187 32.369 28.5903 33.5413 28.0782C34.8388 27.5091 36.0566 27.4294 36.2387 27.896L36.3866 28.2716C36.785 28.0326 37.2744 27.8619 37.8207 27.7822C38.2077 27.7367 38.5833 27.7367 38.9361 27.7936C38.5491 26.7693 38.4922 25.9157 38.8223 25.7222L39.3572 25.4263C39.7783 25.1986 40.6661 26.0864 41.3376 27.4294C41.3945 27.5432 41.4514 27.657 41.4969 27.7595C41.7132 27.6343 41.9522 27.5205 42.214 27.418C42.7603 27.1904 43.2838 27.0538 43.7277 26.9969L43.7391 26.7807C43.7505 26.2685 44.9114 25.9157 46.3341 25.984C47.7454 26.0522 48.8835 26.5189 48.8721 27.0311L48.838 27.0652Z'
      fill='#E4BA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.0648 37.1833L34.2355 38.0711C34.2355 38.0939 34.2469 38.1166 34.2469 38.1394L27.0652 39.0841L26.9628 38.5377C29.3301 38.0825 31.6975 37.6272 34.0648 37.172V37.1833Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.2748 36.5915C32.2324 36.2159 33.9396 36.4777 34.0648 37.1833C34.1331 37.5362 33.7917 37.9345 33.1998 38.2873L27.4294 39.0499C27.1676 38.9247 26.9969 38.754 26.9628 38.5491C26.8262 37.8435 28.3172 36.9671 30.2748 36.5801V36.5915Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.2975 36.7167C30.9121 36.5915 31.504 36.546 32.0275 36.546C31.7771 36.5688 31.5154 36.6143 31.2422 36.6712C29.3756 37.0354 27.9643 37.8435 28.0782 38.4695C28.1123 38.6516 28.2603 38.7995 28.5106 38.902L27.7026 39.0044C27.3839 38.8906 27.179 38.7199 27.1335 38.515C27.0197 37.8776 28.431 37.0695 30.2975 36.7167Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.4141 36.8078L39.3914 37.4793L33.1202 38.3101C32.5056 38.0483 32.1413 37.7183 32.1413 37.3654L32.1641 36.4549C34.577 36.5687 36.9899 36.6826 39.4027 36.8078H39.4141Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.8289 35.3282C37.8321 35.4192 39.4369 36.0907 39.4141 36.8078C39.4027 37.1151 39.0954 37.3882 38.5719 37.5931L35.92 37.9459C35.8631 37.9459 35.8062 37.9459 35.7493 37.9345C33.7461 37.8435 32.1413 37.172 32.1641 36.4549C32.1869 35.7379 33.8258 35.2371 35.8176 35.3282H35.8289Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.8176 35.4534C36.4549 35.4875 37.0354 35.5672 37.5476 35.6924C37.2972 35.6582 37.0354 35.6355 36.7622 35.6241C34.8615 35.533 33.3136 35.9769 33.2909 36.6257C33.2795 37.0582 33.951 37.4679 34.964 37.7183C33.4502 37.5248 32.3348 37.0012 32.3462 36.4549C32.369 35.8062 33.9169 35.3623 35.8176 35.4534Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.8831 34.7819L55.9173 35.294L48.747 36.2387C48.7356 36.1932 48.7242 36.1476 48.7128 36.1021L48.6559 35.1916C51.0688 35.055 53.4703 34.9184 55.8831 34.7819Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.1842 33.6892C54.1759 33.5754 55.8376 34.0648 55.8831 34.7818C55.8945 34.9753 55.7921 35.1574 55.5986 35.3395L49.9079 36.0907C49.1681 35.8972 48.69 35.5786 48.6673 35.203C48.6218 34.4859 50.2038 33.8144 52.1955 33.7006L52.1842 33.6892Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.1955 33.8258C52.8215 33.7917 53.4247 33.8144 53.9369 33.8827C53.6865 33.8713 53.4134 33.8827 53.1516 33.8941C51.2509 34.0079 49.7599 34.6111 49.794 35.2599C49.8168 35.5558 50.1583 35.8062 50.7046 35.9883L50.2721 36.0452C49.4298 35.8745 48.8721 35.5672 48.8494 35.1916C48.8039 34.5428 50.3062 33.9396 52.2069 33.8258H52.1955Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.2407 34.4632L59.0927 34.8843L56.327 35.2485C56.1108 35.1802 55.8832 35.0891 55.6555 34.9981C54.028 34.3038 52.8898 33.2795 53.1061 32.699L53.3678 31.9706C55.3255 32.8015 57.2831 33.6323 59.2407 34.4745V34.4632Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.6912 32.1641C58.3188 32.8584 59.4569 33.8827 59.2407 34.4632C59.0244 35.0436 57.5448 34.9526 55.9173 34.2583C54.3011 33.564 53.1516 32.5397 53.3678 31.9592C53.5841 31.3788 55.0637 31.4698 56.6912 32.1641Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.6457 32.2665C57.1579 32.4828 57.6245 32.7332 58.0001 32.995C57.7952 32.8925 57.5904 32.7901 57.3627 32.699C55.8262 32.0389 54.4149 31.9365 54.2328 32.46C54.1076 32.8129 54.5515 33.3478 55.3141 33.8599C54.119 33.234 53.3337 32.4714 53.5044 32.0275C53.6979 31.504 55.0978 31.6178 56.6343 32.2665H56.6457Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.2505 28.8976L58.3985 29.6716C58.5123 30.2862 57.2262 31.0487 55.5189 31.3788C53.8117 31.7088 52.3435 31.4812 52.2297 30.8666L52.0817 30.0927C54.1418 29.6943 56.2018 29.296 58.2619 28.8976H58.2505Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9612 28.3741C56.6685 28.044 58.1367 28.283 58.2505 28.8862C58.3643 29.5008 57.0782 30.2634 55.371 30.5935C53.6637 30.9235 52.1955 30.6959 52.0817 30.0813C51.9679 29.4667 53.254 28.7041 54.9612 28.3741Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9726 28.4879C55.5075 28.3855 56.0197 28.3399 56.475 28.3399C56.2587 28.3627 56.0311 28.3968 55.7921 28.4424C54.1759 28.7497 52.9467 29.4553 53.0491 30.013C53.1174 30.3772 53.7662 30.5935 54.6653 30.5935C53.3564 30.7414 52.3093 30.5252 52.2297 30.0585C52.1272 29.5008 53.3564 28.8066 54.9726 28.4879Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.3207 27.4863L52.4687 28.2602C52.5825 28.8748 51.2964 29.6374 49.5892 29.9675C47.8819 30.2975 46.4137 30.0699 46.2999 29.4553L46.152 28.6814C48.212 28.283 50.2721 27.8846 52.3207 27.4863Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.0315 26.9741C50.7387 26.6441 52.2069 26.8831 52.3207 27.4863C52.4346 28.1009 51.1484 28.8635 49.4412 29.1935C47.734 29.5236 46.2658 29.296 46.152 28.6814C46.0382 28.0668 47.3243 27.3042 49.0315 26.9741Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.0542 27.088C49.5892 26.9855 50.1127 26.94 50.5566 26.94C50.3404 26.9628 50.1127 26.9969 49.8737 27.0424C48.2575 27.3497 47.0283 28.0554 47.1308 28.6131C47.1991 28.9773 47.8478 29.1935 48.747 29.1935C47.4381 29.3415 46.391 29.1252 46.3113 28.6586C46.2089 28.1009 47.4381 27.4066 49.0542 27.088Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.1625 33.2226L31.1284 34.0079C31.1056 34.6339 29.6716 35.0436 27.9416 34.9412C26.2002 34.8274 24.8231 34.2355 24.8458 33.6095L24.88 32.8242C26.9742 32.9608 29.0683 33.086 31.1625 33.2226Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.0668 31.8909C29.8081 32.0048 31.1853 32.5966 31.1625 33.2226C31.1398 33.8486 29.7057 34.2583 27.9757 34.1559C26.2344 34.0421 24.8572 33.4502 24.88 32.8242C24.9027 32.1982 26.3368 31.7885 28.0668 31.8909Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.0554 32.0048C28.6017 32.0389 29.1139 32.1186 29.5578 32.2438C29.3415 32.2096 29.1139 32.1869 28.8749 32.1755C27.2246 32.073 25.8702 32.4373 25.8474 33.0063C25.836 33.3819 26.4165 33.7461 27.2928 33.9738C25.984 33.7803 25.0165 33.325 25.0393 32.8356C25.0621 32.2779 26.4165 31.9023 28.0668 32.0048H28.0554Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.0063 29.6374L33.2226 30.4C33.3933 31.0032 32.1755 31.891 30.5138 32.4031C28.8521 32.9039 27.3611 32.8242 27.1904 32.2324L26.9742 31.4698C28.9887 30.8666 30.9918 30.252 33.0063 29.6488V29.6374Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.683 29.4553C31.3447 28.9545 32.8356 29.0342 33.0064 29.626C33.1771 30.2293 31.9593 31.117 30.2976 31.6292C28.6359 32.13 27.1449 32.0503 26.9742 31.4585C26.8034 30.8552 28.0213 29.9675 29.683 29.4553Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M29.7171 29.5691C30.2407 29.4098 30.7528 29.3074 31.1967 29.2618C30.9804 29.3074 30.7528 29.3643 30.5366 29.4326C28.9545 29.9106 27.7936 30.7414 27.953 31.2764C28.0554 31.6406 28.7155 31.7772 29.6147 31.6975C28.3286 31.982 27.2701 31.8682 27.1449 31.4129C26.9969 30.8666 28.1465 30.0472 29.7285 29.5691H29.7171Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.8435 28.8521L37.7297 29.626C37.6386 30.2406 36.1818 30.491 34.4632 30.1951C32.7446 29.8878 31.4243 29.148 31.504 28.5334L31.6178 27.7595C33.6892 28.1237 35.772 28.4879 37.8435 28.8521Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.8843 27.1904C36.6029 27.4977 37.9232 28.2375 37.8435 28.8521C37.7524 29.4667 36.2956 29.7171 34.577 29.4212C32.8584 29.1139 31.5381 28.3741 31.6178 27.7595C31.7089 27.1449 33.1657 26.8945 34.8843 27.1904Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.8729 27.2928C35.4192 27.3839 35.92 27.5318 36.3411 27.6912C36.1249 27.6343 35.8972 27.5887 35.6696 27.5432C34.0421 27.2587 32.6535 27.4749 32.5739 28.0326C32.5169 28.4082 33.0633 28.8293 33.9169 29.1594C32.6308 28.8293 31.7089 28.2602 31.7771 27.7822C31.8568 27.2245 33.2454 27.0083 34.8729 27.2928Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.9467 32.2096L52.8215 33.0974C52.7191 33.803 51.0574 34.0876 49.0998 33.7461C47.1422 33.4047 45.6398 32.5511 45.7422 31.8568L45.8674 30.969C48.2234 31.3901 50.5907 31.7999 52.9581 32.221L52.9467 32.2096Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.5778 30.3203C51.5354 30.6617 53.0378 31.5153 52.9467 32.2096C52.8443 32.9153 51.1826 33.1998 49.225 32.8584C47.2674 32.5169 45.765 31.6633 45.8561 30.969C45.9585 30.2634 47.6202 29.9788 49.5778 30.3203Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.555 30.4455C50.1696 30.5593 50.7501 30.7186 51.2281 30.9007C50.9777 30.8325 50.7273 30.7756 50.4542 30.73C48.599 30.4 47.017 30.6504 46.9259 31.2877C46.869 31.7088 47.4836 32.1982 48.451 32.5738C46.9828 32.1982 45.9471 31.5495 46.0154 31.0032C46.1064 30.3772 47.6885 30.1268 49.5437 30.4569L49.555 30.4455Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.5852 34.19L32.7559 35.0664C32.8925 35.7607 31.4471 36.6598 29.5122 37.0695C27.5774 37.4793 25.8929 37.2403 25.7563 36.546L25.5856 35.6696C27.9188 35.1802 30.252 34.6908 32.5852 34.2014V34.19Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8293 33.6551C30.7642 33.2454 32.4486 33.4844 32.5852 34.1786C32.7218 34.8729 31.2764 35.772 29.3415 36.1818C27.4066 36.5915 25.7222 36.3525 25.5856 35.6582C25.449 34.964 26.8945 34.0648 28.8293 33.6551Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8521 33.7803C29.4667 33.6551 30.0472 33.5868 30.5593 33.5868C30.3089 33.6209 30.0472 33.6665 29.7854 33.712C27.953 34.099 26.5644 34.9184 26.6896 35.5444C26.7693 35.9655 27.5091 36.1932 28.5334 36.1818C27.0424 36.3753 25.8588 36.1476 25.7449 35.6127C25.6198 34.9867 27.0083 34.1672 28.8407 33.7803H28.8521Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M36.398 30.5935L36.5801 31.4699C36.7167 32.1641 35.2713 33.0633 33.3364 33.473C31.4016 33.8827 29.7171 33.6437 29.5805 32.9494L29.3984 32.0731C31.7316 31.5837 34.0648 31.0943 36.398 30.5935Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.6421 30.0699C34.577 29.6602 36.2615 29.8992 36.398 30.5935C36.5346 31.2877 35.0892 32.1869 33.1543 32.5966C31.2195 33.0063 29.535 32.7673 29.3984 32.0731C29.2618 31.3788 30.7073 30.4797 32.6421 30.0699Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.6649 30.1951C33.2795 30.0699 33.86 30.0016 34.3721 30.0016C34.1217 30.0358 33.86 30.0813 33.5982 30.1268C31.7658 30.5138 30.3772 31.3333 30.5024 31.9592C30.5821 32.3803 31.3219 32.608 32.3462 32.5966C30.8552 32.7901 29.6716 32.5625 29.5578 32.0275C29.4326 31.4015 30.8211 30.5821 32.6535 30.1951H32.6649Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.2089 26.9514L46.2544 27.6115C46.2886 28.1351 45.139 28.6358 43.6708 28.7269C42.214 28.8179 40.9962 28.4651 40.962 27.9416L40.9165 27.2814C42.6806 27.1676 44.4447 27.0538 46.2089 26.94V26.9514Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.4887 26.1661C44.9455 26.075 46.1633 26.4278 46.2089 26.9514C46.243 27.4749 45.0935 27.9757 43.6253 28.0781C42.1684 28.1692 40.9506 27.8164 40.9051 27.2928C40.871 26.7693 42.0205 26.2685 43.4887 26.1774V26.1661Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.5001 26.2685C43.9667 26.2343 44.3992 26.2571 44.7748 26.3026C44.5927 26.3026 44.3992 26.3026 44.1944 26.314C42.8058 26.4051 41.7132 26.8603 41.7473 27.327C41.7701 27.6456 42.2937 27.8847 43.0448 27.9871C41.9294 27.9643 41.0758 27.6798 41.0531 27.2814C41.0189 26.8034 42.1116 26.3482 43.5001 26.2685Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M42.3619 29.4781L42.4075 30.1382C42.4416 30.6618 41.2921 31.1625 39.8239 31.2536C38.367 31.3447 37.1492 30.9918 37.1151 30.4683L37.0695 29.8082C38.8337 29.6943 40.5978 29.5805 42.3619 29.4667V29.4781Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.6531 28.6928C41.11 28.6017 42.3278 28.9545 42.3733 29.4781C42.4074 30.0016 41.2579 30.5024 39.7897 30.6048C38.3329 30.6959 37.1151 30.3431 37.0695 29.8195C37.0354 29.296 38.1849 28.7952 39.6531 28.7041V28.6928Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.6645 28.7838C40.1311 28.7497 40.5636 28.7724 40.9392 28.8179C40.7571 28.8179 40.5636 28.8179 40.3588 28.8293C38.9702 28.9204 37.8776 29.3756 37.9118 29.8423C37.9345 30.161 38.4581 30.4 39.2092 30.5024C38.0939 30.4796 37.2402 30.1951 37.2175 29.7968C37.1833 29.3187 38.276 28.8635 39.6645 28.7838Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7536 35.6582L45.8219 36.5915C45.8219 36.6029 45.8219 36.6143 45.8219 36.6257L38.6857 37.5703C38.4808 37.4224 38.367 37.2516 38.3556 37.0695L38.2874 36.1363C40.7799 35.9769 43.2724 35.8176 45.7536 35.6582Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.9181 34.5542C43.9781 34.429 45.6967 34.9184 45.7422 35.6582C45.7764 36.1021 45.2187 36.5232 44.3195 36.8305L41.0986 37.2516C39.5166 37.1947 38.3215 36.7509 38.276 36.1363C38.2191 35.3965 39.858 34.6908 41.9181 34.5542Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.9294 34.6794C42.5782 34.6339 43.1928 34.6567 43.7277 34.7249C43.4659 34.7249 43.1928 34.7249 42.9082 34.7477C40.9506 34.8729 39.4027 35.5103 39.4483 36.1818C39.4824 36.6257 40.2108 36.9671 41.2807 37.1151C39.71 37.0923 38.5036 36.6826 38.4695 36.1135C38.4239 35.442 39.9718 34.8046 41.9294 34.6794Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.6547 33.3023L40.7116 34.1331C40.7571 34.7933 39.3117 35.4192 37.4793 35.5444C35.6469 35.6582 34.1217 35.2257 34.0762 34.5656L34.0193 33.7348C36.2273 33.5982 38.4467 33.4502 40.6661 33.3137L40.6547 33.3023Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.2516 32.3235C39.0841 32.2096 40.6092 32.6421 40.6547 33.3023C40.7002 33.9624 39.2548 34.5884 37.4224 34.7136C35.5899 34.8274 34.0648 34.3949 34.0193 33.7348C33.9738 33.0746 35.4192 32.4487 37.2516 32.3235Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.263 32.4373C37.8435 32.4031 38.3898 32.4145 38.8678 32.4828C38.6402 32.4828 38.3898 32.4828 38.1394 32.5056C36.398 32.6194 35.0209 33.1885 35.0664 33.7803C35.1005 34.1787 35.7493 34.486 36.6939 34.6111C35.294 34.5884 34.2242 34.2242 34.19 33.7234C34.1445 33.1316 35.5217 32.5625 37.263 32.4487V32.4373Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.2493 34.1559L50.3062 34.9867C50.3518 35.6469 48.9063 36.2728 47.0739 36.398C45.2415 36.5119 43.7163 36.0794 43.6708 35.4192L43.6139 34.5884C45.8219 34.4518 48.0413 34.3038 50.2607 34.1673L50.2493 34.1559Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.8349 33.1771C48.6673 33.0633 50.1924 33.4957 50.2379 34.1559C50.2835 34.816 48.838 35.442 47.0056 35.5672C45.1732 35.681 43.648 35.2485 43.6025 34.5884C43.557 33.9282 45.0024 33.3023 46.8349 33.1771Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.8462 33.2909C47.4267 33.2567 47.973 33.2681 48.451 33.3364C48.2234 33.3364 47.973 33.3364 47.7226 33.3592C45.9812 33.473 44.6041 34.0421 44.6496 34.6339C44.6838 35.0323 45.3325 35.3396 46.2772 35.4648C44.8772 35.442 43.8074 35.0778 43.7732 34.577C43.7277 33.9852 45.1049 33.4161 46.8462 33.3023V33.2909Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.1732 32.8356L45.2187 33.4958C45.2528 34.0193 44.1033 34.5201 42.6351 34.6111C41.1783 34.7022 39.9604 34.3494 39.9263 33.8258L39.8808 33.1657C41.6449 33.0519 43.409 32.9381 45.1732 32.8242V32.8356Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M42.4644 32.0503C43.9212 31.9592 45.139 32.3121 45.1845 32.8356C45.2187 33.3592 44.0692 33.86 42.6009 33.951C41.1441 34.0421 39.9263 33.6892 39.8808 33.1657C39.8466 32.6421 40.9961 32.1413 42.4644 32.0503Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M42.4644 32.1413C42.931 32.1072 43.3635 32.13 43.7391 32.1755C43.557 32.1755 43.3635 32.1755 43.1586 32.1869C41.7701 32.2779 40.6775 32.7332 40.7116 33.1998C40.7344 33.5185 41.2579 33.7575 42.0091 33.86C40.8937 33.8372 40.0401 33.5527 40.0173 33.1429C39.9832 32.6649 41.0758 32.2096 42.4644 32.13V32.1413Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.172 28.7724L65.9785 29.3074C66.1493 29.3187 66.3314 29.3415 66.5135 29.3756C67.2191 29.5008 67.8451 29.774 68.289 30.1154C68.2776 29.922 68.3231 29.774 68.4255 29.6829L68.8922 29.2618C69.2678 28.9318 70.2921 29.5919 71.1912 30.73C71.7945 31.4926 72.1814 32.2893 72.2611 32.8242C72.2839 32.8242 72.3066 32.8356 72.3294 32.8356C73.8659 33.1202 74.8789 34.5998 74.5943 36.1476C74.4122 37.172 73.7066 37.9573 72.8074 38.2987C73.5358 38.4353 74.0252 38.7085 74.0252 39.0385V39.4369L39.4369 33.1088C39.2662 32.9608 39.1865 32.8015 39.2093 32.6421L39.3003 32.0048C39.3572 31.6178 40.0856 31.3788 41.0644 31.3902C40.5978 30.7756 40.3702 29.9675 40.5295 29.148C40.7799 27.7595 42.0091 26.7807 43.3749 26.8148C43.3863 26.3254 44.422 25.7222 45.765 25.449C47.1649 25.1645 48.36 25.3238 48.4396 25.8246L48.5079 26.223C48.599 26.1661 48.7128 26.1205 48.8152 26.075C48.986 25.6198 50.0217 25.1759 51.285 25.0507C52.6963 24.9027 53.88 25.1986 53.9141 25.7108L53.9483 26.1205C54.4035 26.0181 54.9271 25.9953 55.462 26.0978C55.849 26.1661 56.2018 26.2913 56.5205 26.4506C56.4636 25.358 56.6685 24.5157 57.0554 24.4475L57.6587 24.3336C58.1367 24.2426 58.6944 25.3694 58.922 26.8376C58.9448 26.9628 58.9561 27.088 58.9675 27.2018C59.2179 27.1563 59.4797 27.1221 59.7528 27.0993C60.3333 27.0538 60.8796 27.088 61.3121 27.1676L61.3804 26.9628C61.5511 26.4847 62.7576 26.4961 64.0892 26.9969C65.4208 27.4977 66.3541 28.2944 66.1834 28.7724H66.172Z'
      fill='#E4BA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.0087 33.8713L48.8949 34.7705C48.8949 34.7932 48.8835 34.816 48.8835 34.8388L41.7587 33.5413L41.827 32.995C44.2171 33.2909 46.6186 33.5754 49.0087 33.8713Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.5829 32.1414C47.5633 32.3804 49.0998 33.1543 49.0087 33.8713C48.9632 34.2242 48.5193 34.4973 47.8478 34.6567L42.1229 33.6096C41.9067 33.4047 41.8042 33.1998 41.827 32.995C41.918 32.2779 43.6025 31.9023 45.5829 32.1414Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.5715 32.2665C46.1975 32.3462 46.778 32.4714 47.2674 32.6307C47.017 32.5852 46.7552 32.5397 46.4934 32.5056C44.6155 32.2779 43.0107 32.608 42.931 33.2567C42.9082 33.4388 43.0107 33.6209 43.2042 33.803L42.3961 33.6551C42.1229 33.4502 41.975 33.2226 42.0091 33.0177C42.0888 32.3804 43.6822 32.0389 45.5715 32.2665Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.2101 35.1347L53.9824 35.772L47.7568 34.6339C47.256 34.2014 47.0056 33.7689 47.1194 33.4388L47.4267 32.5852C49.6916 33.4388 51.9451 34.2924 54.2101 35.1347Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2509 32.6421C53.1288 33.3478 54.4491 34.4632 54.2101 35.1461C54.1076 35.442 53.7207 35.6013 53.1743 35.6355L50.5452 35.1574C50.4883 35.1347 50.4428 35.1233 50.3859 35.1005C48.5079 34.3949 47.1877 33.2795 47.4267 32.5966C47.6657 31.9251 49.3843 31.9479 51.2509 32.6535V32.6421Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2054 32.756C51.7972 32.9836 52.3321 33.2454 52.776 33.5185C52.5484 33.4047 52.298 33.3023 52.0476 33.2112C50.2721 32.5397 48.6559 32.4942 48.4397 33.1088C48.2917 33.5185 48.8152 34.1103 49.703 34.668C48.3258 34.0193 47.4153 33.1771 47.5974 32.6535C47.8137 32.0389 49.4298 32.0958 51.2054 32.7673V32.756Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.5084 38.276L70.3832 38.7768L63.2697 37.4793C63.2697 37.4337 63.2697 37.3882 63.2811 37.3313L63.4974 36.4549C65.8306 37.0581 68.1638 37.6727 70.497 38.276H70.5084Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3215 36.1021C69.2564 36.6029 70.6791 37.5817 70.5084 38.276C70.4628 38.4581 70.3035 38.606 70.0645 38.7198L64.4079 37.6841C63.7591 37.2744 63.4063 36.8191 63.4974 36.4549C63.6681 35.7607 65.3867 35.6013 67.3215 36.1021Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.2874 36.2273C67.902 36.3866 68.4597 36.5915 68.9263 36.8191C68.6873 36.7395 68.4369 36.6598 68.1752 36.5915C66.3427 36.1135 64.7266 36.2387 64.5672 36.8647C64.4989 37.1492 64.7379 37.502 65.2046 37.8321L64.7721 37.7524C64.0209 37.3313 63.5884 36.876 63.6795 36.5004C63.8388 35.8745 65.455 35.7493 67.2874 36.2273Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.7976 38.993L73.5244 39.3458L70.7815 38.8451C70.5994 38.7085 70.4059 38.5605 70.2238 38.4012C68.8922 37.2517 68.1182 35.92 68.4938 35.4306L68.9718 34.816C70.5766 36.2046 72.1928 37.6045 73.7976 38.993Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.079 36.0224C73.4106 37.172 74.1846 38.5036 73.809 38.993C73.4334 39.4824 72.0449 38.9361 70.7132 37.7866C69.3816 36.637 68.6076 35.3054 68.9832 34.816C69.3588 34.3266 70.7474 34.8729 72.079 36.0224Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.0107 36.1135C72.4318 36.4777 72.796 36.8647 73.0806 37.2175C72.9212 37.0582 72.7505 36.8988 72.5684 36.7395C71.3051 35.6468 69.9962 35.1119 69.6661 35.5444C69.4385 35.8403 69.7003 36.4891 70.2693 37.2175C69.3247 36.2615 68.8125 35.294 69.1084 34.9184C69.4499 34.4859 70.7474 35.0095 72.0107 36.1135Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M74.5716 33.4047L74.4691 34.19C74.3895 34.8046 72.9326 35.146 71.2026 34.9298C69.484 34.7249 68.1524 34.042 68.2321 33.4274L68.3345 32.6421C70.4173 32.8925 72.5001 33.1543 74.5716 33.4047Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.5896 31.8909C73.3082 32.0958 74.6398 32.7787 74.5602 33.3933C74.4805 34.0079 73.0123 34.3493 71.2937 34.1331C69.5751 33.9282 68.2321 33.2453 68.3231 32.6307C68.4028 32.0161 69.8596 31.6747 71.5896 31.8909Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71.5782 32.0048C72.1245 32.0731 72.6253 32.1869 73.0578 32.3234C72.8416 32.2779 72.6139 32.2438 72.3749 32.2096C70.736 32.0048 69.3588 32.3007 69.2792 32.8584C69.2336 33.234 69.7799 33.6323 70.6336 33.9169C69.3361 33.6551 68.4142 33.1315 68.4825 32.6535C68.5507 32.0958 69.9393 31.7999 71.5782 32.0048Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.3588 30.2406L69.2564 31.0259C69.1767 31.6405 67.7085 31.982 65.9899 31.7657C64.2713 31.5609 62.9397 30.878 63.0193 30.2634L63.1218 29.4781C65.2046 29.7285 67.2874 29.9902 69.3588 30.2406Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.3883 28.7383C68.1069 28.9431 69.4385 29.626 69.3588 30.2406C69.2792 30.8552 67.8223 31.1967 66.0923 30.9804C64.3737 30.7755 63.0421 30.0927 63.1218 29.4781C63.2014 28.8635 64.6583 28.522 66.3883 28.7383Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.3655 28.8521C66.9118 28.9204 67.4126 29.0342 67.8451 29.1708C67.6289 29.1252 67.4012 29.0911 67.1622 29.057C65.5233 28.8521 64.1461 29.148 64.0664 29.7057C64.0209 30.0813 64.5672 30.4796 65.4208 30.7528C64.1234 30.491 63.2015 29.9675 63.2697 29.4894C63.338 28.9318 64.7266 28.6358 66.3655 28.8407V28.8521Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.4608 29.2049L47.1877 29.9447C46.9714 30.5252 45.4805 30.491 43.8643 29.8537C42.2481 29.2163 41.11 28.2261 41.3262 27.6457L41.5994 26.9059C43.557 27.6798 45.5032 28.4424 47.4608 29.2163V29.2049Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.9114 26.9855C46.5276 27.6229 47.6657 28.6131 47.4495 29.1935C47.2332 29.774 45.7422 29.7399 44.1261 29.1025C42.5099 28.4651 41.3717 27.4749 41.588 26.8945C41.8042 26.314 43.2952 26.3482 44.9114 26.9855Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.8772 27.0993C45.3894 27.3042 45.856 27.5318 46.2316 27.7822C46.0268 27.6798 45.8219 27.5888 45.6057 27.5091C44.0692 26.9059 42.6692 26.8376 42.4757 27.3725C42.3505 27.7253 42.783 28.2489 43.557 28.7383C42.3619 28.1578 41.588 27.418 41.7587 26.9628C41.9522 26.4392 43.3521 26.4961 44.8886 27.0993H44.8772Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.3062 26.3595L50.2721 27.1449C50.2493 27.7708 48.8266 28.2489 47.0852 28.2147C45.3439 28.1806 43.9553 27.657 43.9781 27.0311L44.0122 26.2457C46.1064 26.2799 48.212 26.3254 50.3062 26.3595Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.2104 25.1645C48.9518 25.1986 50.3403 25.7335 50.3176 26.3481C50.2948 26.9741 48.8721 27.4522 47.1308 27.418C45.3894 27.3839 44.0009 26.8603 44.0236 26.2343C44.0464 25.6083 45.4691 25.1303 47.2104 25.1645Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.1991 25.2783C47.7454 25.2897 48.2575 25.3466 48.7014 25.449C48.4852 25.4263 48.2575 25.4149 48.0185 25.4149C46.3682 25.3807 45.0138 25.8132 44.991 26.3709C44.9797 26.7465 45.5601 27.0879 46.4365 27.2814C45.1162 27.1562 44.1488 26.7351 44.1716 26.2457C44.1943 25.688 45.5487 25.2555 47.1991 25.2897V25.2783Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.1547 27.0879L54.8133 27.7936C54.5401 28.3513 53.0719 28.1464 51.524 27.327C49.9762 26.5075 48.9518 25.3921 49.225 24.8344L49.5664 24.1288C51.433 25.1076 53.2882 26.0978 55.1547 27.0766V27.0879Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.8557 24.6068C54.4036 25.4263 55.4279 26.5416 55.1547 27.0993C54.8816 27.657 53.4134 27.4522 51.8655 26.6327C50.3176 25.8132 49.2933 24.6978 49.5664 24.1401C49.8396 23.5825 51.3078 23.7873 52.8557 24.6068Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.7988 24.6978C53.2882 24.9596 53.7207 25.2441 54.0735 25.5287C53.88 25.4035 53.6865 25.2897 53.4703 25.1872C52.0021 24.4133 50.6135 24.1971 50.3745 24.6978C50.2152 25.0393 50.6021 25.6084 51.3078 26.1774C50.181 25.4604 49.4867 24.6409 49.6916 24.2084C49.9306 23.7076 51.3192 23.9239 52.7874 24.6978H52.7988Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.4938 34.9298L68.1069 35.7379C67.7996 36.3753 66.1265 36.1362 64.3623 35.2143C62.6096 34.2811 61.4373 33.0177 61.7446 32.3804L62.1316 31.5723C64.2485 32.6877 66.3769 33.8144 68.4938 34.9298Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.8761 32.0958C67.6288 33.0291 68.8011 34.2924 68.4938 34.9298C68.1865 35.5672 66.5134 35.3282 64.7493 34.4062C62.9966 33.473 61.8243 32.2096 62.1316 31.5723C62.4389 30.9349 64.112 31.1739 65.8761 32.0958Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.8192 32.2096C66.3769 32.5055 66.8663 32.8356 67.276 33.1543C67.0598 33.0177 66.8321 32.8811 66.5931 32.7559C64.9314 31.8796 63.3494 31.6292 63.0649 32.1982C62.8828 32.5852 63.3153 33.234 64.1233 33.8827C62.8372 33.0746 62.0519 32.1299 62.2909 31.6405C62.5641 31.0715 64.1461 31.3219 65.8192 32.1982V32.2096Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.5193 30.5593L48.4169 31.4471C48.3372 32.1527 46.6755 32.5625 44.7065 32.3576C42.7375 32.1527 41.2124 31.4129 41.2921 30.7073L41.3945 29.8195C43.7618 30.0699 46.1406 30.3203 48.5193 30.5707V30.5593Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.1049 28.8976C47.0739 29.1025 48.599 29.8423 48.5193 30.5479C48.4396 31.2536 46.7779 31.6633 44.8089 31.4585C42.8399 31.2536 41.3148 30.5138 41.3945 29.8081C41.4742 29.1025 43.1359 28.6927 45.1049 28.8976Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0935 29.0228C45.7195 29.0911 46.2885 29.2049 46.7779 29.3643C46.5275 29.3187 46.2772 29.2846 46.004 29.2505C44.1374 29.057 42.5668 29.4098 42.4871 30.0472C42.4416 30.4683 43.0676 30.9122 44.0464 31.2195C42.5668 30.9463 41.5083 30.3658 41.5766 29.8195C41.6449 29.1822 43.2155 28.8293 45.0821 29.0228H45.0935Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.254 28.3058L53.1516 29.1935C53.0719 29.8992 51.4102 30.3089 49.4412 30.1041C47.4722 29.8992 45.9471 29.1594 46.0268 28.4537L46.1292 27.566C48.4966 27.8164 50.8753 28.0668 53.254 28.3172V28.3058Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.8396 26.6555C51.8086 26.8603 53.3337 27.6001 53.254 28.3058C53.1744 29.0114 51.5127 29.4212 49.5437 29.2163C47.5747 29.0114 46.0495 28.2716 46.1292 27.566C46.2089 26.8603 47.8706 26.4506 49.8396 26.6555Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.8168 26.7806C50.4428 26.8489 51.0119 26.9627 51.5013 27.1221C51.2509 27.0766 51.0005 27.0424 50.7273 27.0083C48.8608 26.8148 47.2901 27.1676 47.2218 27.805C47.1763 28.2261 47.8023 28.67 48.7811 28.9773C47.3015 28.7041 46.243 28.1237 46.3113 27.5773C46.3796 26.94 47.9502 26.5872 49.8168 26.7806Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.7022 27.8505L63.5429 28.4993C63.4177 29.0114 62.1657 29.1366 60.743 28.7724C59.3203 28.4082 58.2733 27.7026 58.3985 27.1904L58.5578 26.5417C60.2764 26.9742 61.9836 27.4066 63.7022 27.8391V27.8505Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.3576 26.2799C62.7803 26.6441 63.8274 27.3497 63.7022 27.8619C63.577 28.3741 62.3251 28.4993 60.9024 28.1351C59.4797 27.7708 58.4326 27.0652 58.5578 26.553C58.683 26.0409 59.935 25.9157 61.3576 26.2799Z'
      fill='#E4D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.3349 26.3709C61.7787 26.4847 62.1885 26.6327 62.5413 26.792C62.3706 26.7351 62.1771 26.6782 61.9836 26.6327C60.6406 26.2912 59.4569 26.3937 59.3431 26.8489C59.2634 27.1562 59.6846 27.5432 60.3788 27.8733C59.3203 27.5091 58.6033 26.9741 58.6944 26.5872C58.8082 26.1205 59.9919 26.0295 61.3349 26.3709Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.2748 29.0797L59.1155 29.7285C58.9903 30.2406 57.7383 30.3658 56.3156 30.0016C54.893 29.6374 53.8459 28.9318 53.9711 28.4196L54.1304 27.7708C55.8376 28.2033 57.5562 28.6358 59.2748 29.0797Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.9302 27.4977C58.3529 27.8619 59.4 28.5675 59.2748 29.0797C59.1496 29.5919 57.8977 29.7171 56.475 29.3529C55.0523 28.9887 54.0052 28.283 54.1304 27.7708C54.2556 27.2587 55.5076 27.1335 56.9302 27.4977Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.9075 27.5887C57.3514 27.7025 57.7611 27.8505 58.1139 28.0098C57.9432 27.9529 57.7497 27.896 57.5562 27.8505C56.2132 27.509 55.0295 27.6001 54.9157 28.0667C54.836 28.374 55.2572 28.761 55.9514 29.0911C54.893 28.7269 54.1759 28.1919 54.267 27.805C54.3808 27.3383 55.5645 27.2473 56.9075 27.5887Z'
      fill='#F2F2F2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.5951 35.9883L60.3675 36.8988C60.3675 36.9102 60.3561 36.9216 60.3561 36.9329L53.2768 35.6354C53.1288 35.4306 53.0719 35.2371 53.1174 35.055L53.3451 34.1445C55.7579 34.7591 58.1822 35.3737 60.5951 35.9883Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.2831 33.7689C59.2862 34.2811 60.7658 35.2712 60.5951 35.9997C60.4927 36.4322 59.8211 36.6598 58.8879 36.6825L55.6897 36.1021C54.1987 35.5558 53.1971 34.7705 53.3451 34.1672C53.5272 33.4502 55.2913 33.2681 57.2944 33.7803L57.2831 33.7689Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.2603 33.8941C57.8977 34.0534 58.4667 34.2583 58.9561 34.4973C58.7057 34.4176 58.444 34.3379 58.1708 34.2697C56.2701 33.7916 54.597 33.9282 54.4377 34.577C54.3353 35.0095 54.9271 35.5671 55.8945 36.0224C54.4035 35.5102 53.3906 34.7591 53.5272 34.2014C53.6865 33.5526 55.3596 33.416 57.2603 33.8941Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.4636 32.1983L56.2701 33.0063C56.1108 33.6437 54.5401 33.8031 52.7532 33.3478C50.9663 32.8925 49.6575 32.0048 49.8168 31.3674L50.0103 30.5593C52.1614 31.1056 54.3125 31.652 56.4636 32.1983Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.5272 30.2179C55.3141 30.6731 56.6229 31.5609 56.4636 32.1983C56.3043 32.8356 54.7336 32.995 52.9581 32.5397C51.1712 32.0844 49.8623 31.1967 50.0217 30.5593C50.181 29.922 51.7517 29.7626 53.5386 30.2179H53.5272Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.5044 30.3317C54.0735 30.4797 54.5857 30.6618 55.0182 30.8666C54.8019 30.787 54.5629 30.7187 54.3239 30.6618C52.628 30.2293 51.1484 30.3545 51.0005 30.9349C50.9094 31.3219 51.433 31.8113 52.298 32.221C50.9777 31.7658 50.0672 31.0943 50.1924 30.6048C50.329 30.0244 51.82 29.8992 53.5158 30.3317H53.5044Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.3298 35.9542L65.1249 36.7622C64.9656 37.3996 63.3949 37.5589 61.6194 37.1037C59.8325 36.6484 58.5237 35.7607 58.683 35.1233L58.8765 34.3152C61.0276 34.8615 63.1787 35.4078 65.3298 35.9542Z'
      fill='#DF8000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.3934 33.9738C64.1689 34.429 65.4891 35.3168 65.3298 35.9541C65.1704 36.5915 63.5998 36.7509 61.8243 36.2956C60.0374 35.8403 58.7285 34.9526 58.8879 34.3152C59.0472 33.6778 60.6178 33.5185 62.4047 33.9738H62.3934Z'
      fill='#F2A800'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.3592 34.0876C62.9169 34.2356 63.4405 34.4177 63.8729 34.6225C63.6453 34.5429 63.4177 34.4746 63.1787 34.4177C61.4828 33.9852 60.0032 34.1104 59.8553 34.6908C59.7642 35.0778 60.2878 35.5672 61.1528 35.9769C59.8211 35.5217 58.922 34.8502 59.0472 34.3607C59.1838 33.7803 60.6748 33.6665 62.3706 34.0876H62.3592Z'
      fill='#EFF23D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.9138 33.1315L60.7544 33.7803C60.6292 34.2924 59.3773 34.4176 57.9546 34.0534C56.5319 33.6892 55.4848 32.9836 55.61 32.4714L55.7693 31.8227C57.4766 32.2552 59.1952 32.6877 60.9138 33.1202V33.1315Z'
      fill='#D9B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5692 31.5495C59.9919 31.9137 61.039 32.6194 60.9138 33.1315C60.7886 33.6437 59.5366 33.7689 58.1139 33.4047C56.6912 33.0405 55.6441 32.3348 55.7693 31.8227C55.8945 31.3105 57.1465 31.1853 58.5692 31.5495Z'
      fill='#EDDF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5464 31.6405C58.9903 31.7544 59.4 31.9023 59.7529 32.0617C59.5821 32.0047 59.3886 31.9478 59.1952 31.9023C57.8521 31.5609 56.6685 31.6633 56.5546 32.1186C56.475 32.4259 56.8961 32.8128 57.5904 33.1429C56.5319 32.7787 55.8149 32.2438 55.9059 31.8568C56.0197 31.3901 57.2034 31.2991 58.5464 31.6405Z'
      fill='#F2F2F2'
    />
    <path d='M88 57L88 49L12 49L12 57L88 57Z' fill='#FFD900' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M86 58L88 57H12L14 58H86Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.4278 40.8596H73.9797L82 47H18L26.4392 40.8596H26.4278Z'
      fill='#1A1A1A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 47H82L74 41L26.4961 40.962L18 47ZM88 49H12L25 40C41.6625 40 58.3375 40 75 40L88 49Z'
      fill='#FFFF00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.4679 33.9055L37.6045 34.7136C37.3427 34.6908 37.0695 34.668 36.785 34.668C35.6924 34.668 34.6908 34.8843 33.9282 35.2485C33.9965 34.9753 33.9738 34.7591 33.8486 34.5998L33.2567 33.8941C32.7901 33.3364 31.0715 33.9965 29.4212 35.3737C28.3172 36.2956 27.5205 37.3086 27.2473 38.037C27.2132 38.037 27.179 38.037 27.1563 38.037C24.7775 38.037 22.8541 39.858 22.8541 42.1002C22.8541 43.5798 23.6963 44.8772 24.9482 45.5943C23.8215 45.5943 23.0134 45.8561 22.9223 46.3227L22 47H77C77.2959 46.8293 76.7568 46.5162 76.7682 46.2999L76.8023 45.378C76.8251 44.8203 75.7894 44.2968 74.3325 44.0578C75.2089 43.318 75.7552 42.2367 75.7552 41.0417C75.7552 39.0158 74.1846 37.3313 72.1359 37.024C72.2611 36.3297 70.8726 35.2257 68.9377 34.4859C66.9232 33.712 65.0908 33.6437 64.829 34.3266L64.6241 34.8729C64.4989 34.7705 64.351 34.6794 64.203 34.5884C64.0778 33.9055 62.6551 33.0177 60.7772 32.5169C58.6944 31.9479 56.862 32.0617 56.6685 32.7673L56.5091 33.3364C55.849 33.0746 55.0864 32.9153 54.2556 32.9153C53.6638 32.9153 53.0947 32.995 52.5825 33.1429C52.9581 31.6178 52.8898 30.3886 52.3321 30.1837L51.4671 29.8651C50.7842 29.6147 49.6233 31.0487 48.8835 33.0633C48.8266 33.234 48.7583 33.4047 48.7128 33.564C48.36 33.4388 47.973 33.3136 47.5633 33.2112C46.6983 32.995 45.8788 32.9039 45.2073 32.9039L45.1504 32.5966C45.0252 31.8796 43.2042 31.595 41.0872 31.9479C38.9702 32.3121 37.3427 33.1885 37.4679 33.9055Z'
      fill='#F6D900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.7674 45.5032L61.6991 46.8007C61.6991 46.8349 61.6991 46.869 61.6991 46.9032L72.5 47L72.7619 46.1178C69.0971 45.913 65.4322 45.7081 61.7674 45.5032Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3671 43.944C64.3282 43.7732 61.8243 44.4675 61.7674 45.4918C61.7332 46.004 62.6346 46.613 63.6134 47H72C72.3756 46.7724 72.7505 46.4024 72.7619 46.1064C72.8188 45.0821 70.4059 44.1147 67.3671 43.944Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3557 44.1261C66.3996 44.0692 65.4891 44.1033 64.7152 44.2057C65.1022 44.1944 65.5005 44.2057 65.9216 44.2285C68.8011 44.3879 71.1002 45.2642 71.0433 46.1975C71.0319 46.4593 70.827 46.6983 70.4856 46.9032H71.7262C72.1928 46.6755 72.466 46.4024 72.4887 46.0951C72.5343 45.1732 70.2466 44.2854 67.3671 44.1261H67.3557Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M53.6296 45.9471L54 47H63.5C64.3764 46.5106 64.7835 45.8674 64.7038 45.378L64.4876 44.0919C60.8682 44.7065 57.2489 45.3211 53.6296 45.9471Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.7399 43.1928C55.7466 43.705 53.4589 44.9342 53.6296 45.9585C53.7093 46.4024 54.1805 46.8065 55 47H59C59.0797 46.9886 59.2862 46.8918 59.3659 46.869C62.3592 46.3568 64.6469 45.1276 64.4762 44.1033C64.3055 43.0904 61.7332 42.6806 58.7399 43.1928Z'
      fill='#F6EE00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.774 43.3749C57.8294 43.5342 56.953 43.7732 56.2132 44.0464C56.5888 43.9553 56.9871 43.8757 57.3855 43.796C60.2309 43.3066 62.6665 43.6594 62.8259 44.5699C62.9283 45.1732 61.995 45.8902 60.5154 46.4365C62.7576 45.8788 64.3396 44.9342 64.2144 44.1488C64.0551 43.2383 61.6308 42.8855 58.774 43.3749Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.3741 46.1633L28.5 47H39C39.0114 46.9317 39.4483 46.7552 39.4483 46.6869L39.3572 45.3894C35.6924 45.6512 32.0389 45.9016 28.3741 46.152V46.1633Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7461 43.9326C30.7187 44.1488 28.3172 45.1504 28.3855 46.1747C28.4082 46.4479 28.6699 46.7951 29 47H38C39.0812 46.5789 39.4141 45.9471 39.3686 45.4008C39.3003 44.3765 36.785 43.7163 33.7461 43.9326Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7575 44.1147C32.8015 44.183 31.9023 44.3309 31.1398 44.5244C31.5267 44.4675 31.9251 44.422 32.3348 44.3992C35.2143 44.1944 37.6045 44.7862 37.6614 45.7081C37.6955 46.1292 37.2853 46.6472 36.5 47H37C38.2406 46.613 39.1182 45.9699 39.0841 45.4236C39.0158 44.5017 36.637 43.9098 33.7575 44.1147Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.241 46.3341L23.5 47H27.5C27.8187 46.852 28.431 46.5958 28.7611 46.4137C31.0715 45.1276 32.5966 43.4546 32.1527 42.6806L31.6064 41.6904C28.818 43.2383 26.0295 44.7862 23.241 46.3341Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.6441 42.601C24.3336 43.8871 22.8085 45.5601 23.2524 46.3341C23.6849 47.1194 25.9157 46.7097 28.2147 45.4236C30.5252 44.1375 32.0389 42.4644 31.6064 41.6904C31.1739 40.9051 28.9432 41.3148 26.6441 42.601Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.7238 42.7375C25.9953 43.1472 25.3466 43.5911 24.823 44.0236C25.1076 43.8415 25.4035 43.6594 25.7108 43.4887C27.9074 42.2709 30.0016 41.8498 30.3886 42.5554C30.6504 43.022 30.0813 43.8757 29.0342 44.752C30.7187 43.648 31.743 42.4188 31.4015 41.8156C31.0146 41.11 28.9204 41.5311 26.7238 42.7489V42.7375Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.6394 38.2646L23.5711 39.3914C23.5256 40.2791 25.6198 41.1213 28.2603 41.2693C30.9008 41.4173 33.0746 40.814 33.1315 39.9263L33.1998 38.7995C30.013 38.6174 26.8262 38.4467 23.6508 38.2646H23.6394Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.4993 36.9102C25.8588 36.7622 23.6849 37.3654 23.628 38.2646C23.5825 39.1523 25.6767 39.9946 28.3172 40.1425C30.9577 40.2905 33.1315 39.6873 33.1885 38.7881C33.234 37.9004 31.1398 37.0582 28.4993 36.9102Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.4879 37.0695C27.657 37.024 26.8717 37.0468 26.1888 37.1378C26.5303 37.1264 26.8717 37.1378 27.2359 37.1606C29.7399 37.2971 31.7316 38.0711 31.6861 38.8678C31.6519 39.4027 30.73 39.8238 29.3643 39.9946C31.3788 39.949 32.8925 39.4596 32.9381 38.7767C32.9836 37.9687 30.9918 37.2061 28.4879 37.0695Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.2893 35.1574L32.221 36.2842C32.1755 37.172 34.2697 38.0142 36.9102 38.1622C39.5507 38.3101 41.7246 37.7069 41.7815 36.8191L41.8498 35.6924C38.6629 35.5103 35.4761 35.3395 32.3007 35.1574H32.2893Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1606 33.8144C34.5201 33.6665 32.3462 34.2697 32.3007 35.1575C32.2552 36.0452 34.3494 36.8988 36.9899 37.0354C39.6304 37.1834 41.8042 36.5801 41.8611 35.6924C41.9067 34.8046 39.8125 33.9624 37.172 33.8144H37.1606Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1492 33.9738C36.3184 33.9283 35.533 33.951 34.8501 34.0421C35.1916 34.0307 35.533 34.0421 35.8972 34.0648C38.4012 34.2014 40.3929 34.9754 40.3474 35.7721C40.3133 36.307 39.3914 36.7281 38.0256 36.8988C40.0401 36.8647 41.5538 36.3639 41.5994 35.681C41.6449 34.8729 39.6531 34.1104 37.1492 33.9738Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.3639 39.3344L65.5688 40.4384C65.7281 41.3148 67.9703 41.6449 70.5653 41.1555C73.1603 40.6775 75.1406 39.5735 74.9699 38.6971L74.7651 37.5817C71.6351 38.1622 68.4938 38.7426 65.3526 39.3231L65.3639 39.3344Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.7686 36.876C67.1736 37.3541 65.1932 38.4581 65.3639 39.3344C65.5233 40.2108 67.7654 40.5409 70.3604 40.0515C72.9554 39.5735 74.9358 38.4695 74.7651 37.5931C74.6057 36.7167 72.3636 36.3866 69.7686 36.876Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.8027 37.024C68.9833 37.1719 68.2207 37.3882 67.5833 37.6386C67.9134 37.5475 68.2548 37.4792 68.6077 37.411C71.0774 36.9557 73.1944 37.2289 73.3424 38.0142C73.4448 38.5377 72.6367 39.1637 71.362 39.6645C73.3082 39.1523 74.674 38.3101 74.5488 37.6386C74.4008 36.8419 72.2839 36.5687 69.8141 37.0354L69.8027 37.024Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.8698 34.5884L61.6991 35.7038C61.5625 36.5915 63.5657 37.6272 66.1834 38.037C68.7898 38.4353 71.0205 38.0484 71.1571 37.1606L71.3278 36.0452C68.1752 35.5558 65.0225 35.0778 61.8698 34.5884Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8435 33.7234C64.2372 33.325 62.0064 33.712 61.8698 34.5998C61.7332 35.4875 63.7364 36.5232 66.3541 36.933C68.9605 37.3313 71.1912 36.9443 71.3278 36.0566C71.4644 35.1688 69.4613 34.1331 66.8435 33.7234Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8208 33.8827C66.0013 33.7575 65.216 33.712 64.5331 33.7348C64.8631 33.7575 65.216 33.8031 65.5688 33.86C68.05 34.2469 69.9621 35.203 69.8369 35.9883C69.7572 36.5232 68.7898 36.8419 67.424 36.8874C69.4271 37.0468 70.9978 36.694 71.1002 36.0224C71.2254 35.2257 69.3133 34.2697 66.8321 33.8941L66.8208 33.8827Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.4149 34.3835L54.7336 35.4647C54.984 36.3184 57.2489 36.4094 59.7756 35.6582C62.3023 34.9071 64.1575 33.6096 63.8957 32.7446L63.577 31.6633C60.5154 32.5738 57.4652 33.473 54.4036 34.3835H54.4149Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5464 31.4699C56.0197 32.221 54.1645 33.5185 54.4263 34.3835C54.6767 35.2371 56.9416 35.3282 59.4683 34.577C62.0064 33.8258 63.8502 32.5283 63.5998 31.6633C63.3494 30.8097 61.0845 30.7187 58.5578 31.4699H58.5464Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.5919 31.6178C57.7952 31.8568 57.0668 32.1414 56.4522 32.4601C56.7709 32.3462 57.101 32.2324 57.4424 32.13C59.8439 31.413 61.9836 31.4699 62.2113 32.2324C62.3592 32.7446 61.6308 33.4502 60.413 34.0762C62.2909 33.3592 63.5657 32.3804 63.3608 31.7203C63.1332 30.9463 60.9934 30.9008 58.5919 31.6178Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.3007 41.975L32.6649 43.2042C32.9494 44.183 35.5217 44.274 38.4126 43.4204C41.2921 42.5668 43.3976 41.0872 43.1131 40.1084L42.7489 38.8792C39.2662 39.9149 35.7834 40.9393 32.3121 41.975H32.3007Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.0012 38.663C34.1217 39.5166 32.0162 40.9962 32.3007 41.975C32.5852 42.9538 35.1574 43.0448 38.0483 42.1912C40.9279 41.3376 43.0334 39.858 42.7489 38.8792C42.4644 37.9004 39.8921 37.8094 37.0012 38.663Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.0468 38.8337C36.1362 39.1068 35.3054 39.4369 34.6111 39.7897C34.964 39.6531 35.3509 39.5279 35.7379 39.4141C38.4808 38.606 40.9051 38.663 41.1669 39.5393C41.3376 40.1198 40.5067 40.9279 39.1182 41.6449C41.2579 40.8254 42.7034 39.7214 42.4757 38.9589C42.214 38.0825 39.7897 38.0256 37.0468 38.8337Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.4063 40.962L63.3153 42.2481C63.247 43.2611 65.6143 44.2626 68.619 44.4789C71.6124 44.6951 74.1049 44.0464 74.1846 43.0334L74.2756 41.7473C70.6563 41.4856 67.037 41.2238 63.4177 40.962H63.4063Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.9719 39.5166C65.9671 39.3003 63.486 39.9491 63.4063 40.962C63.338 41.975 65.7054 42.9765 68.7101 43.1928C71.7148 43.409 74.196 42.7603 74.2756 41.7473C74.3439 40.7344 71.9766 39.7328 68.9719 39.5166Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.9491 39.6987C68.0044 39.6304 67.1053 39.6531 66.3314 39.7328C66.7183 39.7328 67.1167 39.7442 67.515 39.7783C70.3604 39.9832 72.6139 40.8937 72.557 41.8042C72.5115 42.4075 71.4416 42.8741 69.8938 43.0448C72.1814 43.0334 73.9228 42.4985 73.9797 41.7246C74.048 40.8141 71.7945 39.9035 68.9377 39.6987H68.9491Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.9302 36.5915L56.8392 37.8776C56.7709 38.8906 59.1382 39.8922 62.143 40.1084C65.1363 40.3247 67.6288 39.6759 67.7085 38.663L67.7996 37.3768C64.1803 37.1151 60.5609 36.8533 56.9416 36.5915H56.9302Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.4958 35.1347C59.4911 34.9184 57.0099 35.5672 56.9302 36.5801C56.862 37.5931 59.2293 38.5947 62.234 38.8109C65.2274 39.0272 67.7199 38.3784 67.7996 37.3655C67.8679 36.3525 65.5005 35.3509 62.5072 35.1347H62.4958Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.4844 35.3168C61.5398 35.2485 60.6406 35.2713 59.8667 35.3509C60.2536 35.3509 60.652 35.3623 61.0503 35.3965C63.8957 35.6013 66.1493 36.5119 66.0923 37.4224C66.0468 38.037 64.977 38.4922 63.4291 38.6743C65.7168 38.663 67.4581 38.128 67.515 37.3427C67.5833 36.4322 65.3298 35.5217 62.473 35.3168H62.4844Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.4173 33.2567L41.4742 34.2014C41.5197 34.9526 43.3635 35.4534 45.5829 35.3054C47.8023 35.1688 49.5664 34.4404 49.5209 33.6892L49.464 32.7446C46.778 32.9153 44.0919 33.086 41.4173 33.2567Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3553 31.6406C43.1359 31.7771 41.3717 32.5056 41.4173 33.2567C41.4628 34.0079 43.3066 34.5087 45.526 34.3607C47.7454 34.2242 49.5095 33.4957 49.464 32.7446C49.4185 31.9934 47.5747 31.4926 45.3553 31.6406Z'
      fill='#F6EE00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3667 31.7772C44.661 31.8227 44.0123 31.9251 43.4546 32.0617C43.7391 32.0275 44.0236 31.9934 44.3309 31.9706C46.4479 31.8341 48.1893 32.2779 48.2348 32.9608C48.2689 33.4161 47.5291 33.86 46.4024 34.1445C48.0868 33.9055 49.3046 33.3478 49.2705 32.7673C49.225 32.0845 47.4836 31.652 45.3667 31.7772Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7112 36.1135L47.7681 37.0582C47.8137 37.8093 49.6575 38.3101 51.8769 38.1622C54.0963 38.0256 55.8604 37.2972 55.8149 36.546L55.7579 35.6013C53.0719 35.772 50.3859 35.9428 47.7112 36.1135Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.6492 34.4973C49.4298 34.6339 47.6657 35.3623 47.7112 36.1135C47.7568 36.8647 49.6006 37.3654 51.82 37.2175C54.0393 37.0809 55.8035 36.3525 55.758 35.6013C55.7124 34.8501 53.8686 34.3493 51.6492 34.4973Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.6606 34.6339C50.955 34.6794 50.3062 34.7818 49.7485 34.9184C50.0331 34.8843 50.3176 34.8501 50.6249 34.8274C52.7305 34.6908 54.4832 35.1347 54.5287 35.8175C54.5629 36.2728 53.8231 36.7167 52.6963 37.0012C54.3808 36.7622 55.5986 36.2045 55.5645 35.6241C55.5189 34.9412 53.7776 34.5087 51.6606 34.6339Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.8415 45.5032C43.852 45.6584 43.9445 47.0281 44 47H55C55.2732 46.7496 55.3027 46.391 55.2913 46.1292L55.2116 44.7862C51.4216 45.0252 47.6316 45.2642 43.8529 45.5032H43.8415Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.3957 43.2269C46.2544 43.4318 43.7732 44.4448 43.8415 45.5146C43.8871 46.1406 44.5887 46.7382 46 47H51C53.3787 46.6244 55.2685 45.674 55.2116 44.7862C55.1433 43.7277 52.5484 43.0221 49.4071 43.2269H49.3957Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.4071 43.409C48.4169 43.4773 47.495 43.6139 46.6983 43.8188C47.0966 43.7619 47.5064 43.7277 47.9389 43.6936C50.9208 43.5001 53.3792 44.1261 53.4475 45.0821C53.493 45.7195 52.4459 46.3455 50.8639 46.7438C53.2312 46.4138 54.9612 45.6171 54.9157 44.7976C54.8588 43.8415 52.389 43.2156 49.4071 43.409Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.0574 41.2238L51.1371 42.4188C51.194 43.3635 53.5044 43.9895 56.2929 43.8074C59.0813 43.6253 61.3007 42.7261 61.2324 41.7815L61.1528 40.5864C57.7839 40.8026 54.4149 41.0189 51.046 41.2238H51.0574Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.997 39.1979C53.2085 39.3686 50.9891 40.2791 51.0574 41.2238C51.1143 42.1684 53.4247 42.7944 56.2246 42.6123C59.0131 42.4302 61.2325 41.5311 61.1642 40.5864C61.1073 39.6417 58.7968 39.0158 55.997 39.1979Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M56.0083 39.3686C55.132 39.4255 54.3011 39.5507 53.6068 39.7328C53.9597 39.6873 54.3239 39.6417 54.7108 39.619C57.3627 39.4483 59.548 40.006 59.6049 40.8596C59.639 41.4286 58.7171 41.9863 57.3058 42.3392C59.4114 42.0432 60.9479 41.3376 60.9024 40.6092C60.8455 39.7556 58.6602 39.2093 56.0083 39.3686Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M36.7509 44.2285L36.8305 45.4235C36.8874 46.3682 39.1979 46.9942 41.9863 46.8121C44.7748 46.63 46.9942 45.7308 46.9259 44.7862L46.8462 43.5911C43.4773 43.8074 40.1084 44.0236 36.7395 44.2285H36.7509Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.7018 42.2026C38.9133 42.3847 36.6939 43.2838 36.7622 44.2285C36.8191 45.1732 39.1296 45.7992 41.9294 45.617C44.7179 45.4463 46.9373 44.5358 46.869 43.5911C46.8121 42.6465 44.5016 42.0205 41.7018 42.2026Z'
      fill='#FFCC00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.7132 42.3733C40.8368 42.4302 40.006 42.5554 39.3117 42.7375C39.6645 42.692 40.0287 42.6465 40.4157 42.6237C43.0676 42.453 45.2528 43.0107 45.3097 43.8643C45.3439 44.4334 44.422 44.9911 43.0107 45.3439C45.1163 45.048 46.6528 44.3423 46.6072 43.6139C46.5503 42.7603 44.3651 42.214 41.7132 42.3733Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.1374 41.3945L44.1943 42.3392C44.2399 43.0904 46.0837 43.5912 48.3031 43.4432C50.5225 43.3066 52.2866 42.5782 52.2411 41.827L52.1842 40.8824C49.4981 41.0531 46.8121 41.2238 44.1374 41.3945Z'
      fill='#EED300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.0754 39.7783C45.8561 39.9149 44.0919 40.6433 44.1374 41.3945C44.183 42.1457 46.0268 42.6465 48.2462 42.4985C50.4656 42.3619 52.2297 41.6335 52.1842 40.8823C52.1386 40.1312 50.2948 39.6304 48.0754 39.7783Z'
      fill='#FCF211'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.0868 39.9149C47.3812 39.9604 46.7324 40.0629 46.1747 40.1994C46.4593 40.1653 46.7438 40.1312 47.0511 40.1084C49.1567 39.9718 50.9094 40.4157 50.955 41.0986C50.9777 41.5538 50.2493 41.9977 49.1225 42.2823C50.807 42.0432 52.0248 41.4856 51.9907 40.9051C51.9451 40.2222 50.2038 39.7897 48.0868 39.9149Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.8831 47.1536L25.6539 45.0252L28.1009 42.5896L26.8831 47.1422V47.1536Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.8831 47.1535L25.6539 45.0252L22.3191 45.9243L26.8717 47.1422L26.8831 47.1535Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.5483 41.3717L25.6539 45.0252L22.3191 45.9243L23.5369 41.3717H23.5483Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.5483 41.3717L25.6539 45.0252L28.1009 42.5896L23.5483 41.3717Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.0794 39.2206L65.7168 36.8533L69.1653 36.8647L65.0794 39.2206Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.0794 39.2206L65.7168 36.8532L62.7234 35.1346L65.0794 39.2206Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8094 32.7787L65.7168 36.8533L62.7234 35.1347L66.8094 32.7787Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.8094 32.7787L65.7168 36.8533L69.1653 36.8646L66.8094 32.7787Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.661 46.4479L74.8902 44.3195L72.4432 41.8839L73.661 46.4365V46.4479Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.661 46.4479L74.8902 44.3195L78.2136 45.2187L73.661 46.4365V46.4479Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.9958 40.6661L74.8902 44.3195L78.2136 45.2187L76.9958 40.6661Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.9958 40.6661L74.8902 44.3195L72.4432 41.8839L76.9958 40.6661Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.3698 39.2207L46.1406 37.0923L42.8058 37.9915L47.3584 39.2093L47.3698 39.2207Z'
      fill='#A31717'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.3698 39.2207L46.1406 37.0923L48.5876 34.6567L47.3698 39.2093V39.2207Z'
      fill='#FF7A7A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.035 33.4388L46.1406 37.0923L48.5876 34.6567L44.035 33.4388Z'
      fill='#FFC2C2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.035 33.4388L46.1406 37.0923L42.8058 37.9914L44.0236 33.4388H44.035Z'
      fill='#FF0000'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7006 38.1849L32.7332 36.5005L30.0927 37.2175L33.7006 38.1849Z'
      fill='#396605'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.7006 38.1849L32.7332 36.5005L34.668 34.577L33.7006 38.1849Z'
      fill='#74DA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.0487 33.6096L32.7218 36.5005L34.6567 34.577L31.0487 33.6096Z'
      fill='#8AFF05'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.0487 33.6096L32.7218 36.5005L30.0813 37.2175L31.0487 33.6096Z'
      fill='#4F9300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.1104 45.1504L64.0778 43.4659L66.7183 44.1829L63.1104 45.1504Z'
      fill='#396605'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63.1104 45.1504L64.0778 43.4659L62.143 41.5424L63.1104 45.1504Z'
      fill='#74DA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.7509 40.575L64.0778 43.4659L62.143 41.5424L65.7509 40.575Z'
      fill='#8AFF05'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.7509 40.575L64.0778 43.4659L66.7183 44.1829L65.7509 40.575Z'
      fill='#4F9300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.5401 33.6095L55.5076 31.9251L58.1481 32.6421L54.5401 33.6095Z'
      fill='#396605'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.5401 33.6096L55.5076 31.9251L53.5727 30.0016L54.5401 33.6096Z'
      fill='#74DA00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.1806 29.0228L55.5076 31.9137L53.5727 29.9902L57.1806 29.0228Z'
      fill='#8AFF05'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.1806 29.0228L55.5076 31.9137L58.1481 32.6307L57.1806 29.0228Z'
      fill='#4F9300'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44 18H56L54 24H47L44 18Z'
      fill='#FFD900'
    />
    <path d='M54 24H47V25H54V24Z' fill='#FCF211' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47 24L44 18V19L47 25V24Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54 24L56 18V19L54 25V24Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.5 16L52 17.5L51 18.5L53 23H48L50 18.5L49 17.5L50.5 16Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.5 17.5L50.5 18.5L52.5 23H48.5L50.5 18.5L49.5 17.5L50.5 16.5L51.5 17.5Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43 35.02L30.2178 29.695L32.016 16L26.7263 28.765L13 26.98L25.7822 32.305L23.984 46L29.2587 33.235L42.985 35.02H43Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60 29.3201L69.1554 30.5089L72.6799 22L71.4703 31.1345L80 34.6799L70.8446 33.4911L67.3201 42L68.5297 32.8655L60 29.3201Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30 43.6601L34.5777 44.2544L36.3399 40L35.7351 44.5673L40 46.3399L35.4223 45.7456L33.6601 50L34.2649 45.4327L30 43.6601Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51 44L54.8255 38.9888L51 34L55.9888 37.8255L61 34L57.1745 39.0112L61 44L56.0112 40.1745L51 44Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52 23L55.4418 27.5103L52 32L56.4897 28.5582L61 32L57.5582 27.4897L61 23L56.5103 26.4418L52 23Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M71 46.2677L71.8539 45.913L71.7323 45L72.0837 45.8506L73 45.7323L72.1461 46.087L72.2677 47L71.9163 46.1494L71 46.2677Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58 43L56.0822 45.5057L58 48L55.5057 46.0879L53 48L54.9121 45.4943L53 43L55.4943 44.9178L58 43Z'
      fill='white'
    />
    <defs>
      <radialGradient
        id='paint0_radial_70_5134'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(50 73) rotate(90) scale(20 50)'
      >
        <stop stopColor='#FFFF00' />
        <stop offset='1' stopColor='#DF8000' stopOpacity='0' />
      </radialGradient>
    </defs>
  </svg>
);
