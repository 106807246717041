import { useChainValues } from '../../../utils/chain';
import { BigNumber } from 'ethers';
import { useModal } from '../../../context/modalContext';
import { TransferModal } from '../../../components/TransferModal';
import { useEffect, useRef } from 'react';
import { Icon } from './../../../components/shared/Icon';
import { DublonButton } from './../../../components/CustomButtons';

export const BubbleCallout: React.FC<BubbleCalloutNS.Props> = ({
  scale,
  focusedPirate,
  setFocusedPirate,
  handleOpenModal,
  setRecepientAddress,
  startPosition,
}) => {
  const scaledStyle = (fontSize: number) => ({
    style: {
      fontSize: fontSize / scale,
    },
  });
  const { address } = useChainValues();
  const ref = useRef<HTMLDivElement>(null);

  const { openModal } = useModal();

  const handleOpenTransferModal = () => {
    openModal(<TransferModal />);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setFocusedPirate(undefined);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setFocusedPirate]);

  return focusedPirate ? (
    <div
      ref={ref}
      className='border-[2px] border-[#F2B100] bg-white py-[5px] pr-[20px] pl-[40px] sm:pl-[80px] rounded-full absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-[90%] sm:w-auto'
    >
      <div className='flex items-center gap-x-10'>
        <div className='flex-1 flex flex-col font-robotoCondensed'>
          <div>
            <div className='uppercase text-[10px] sm:text-sm text-[#848484]'>
              Address:
            </div>
            <div className='text-[#181A26] font-medium text-[10px] sm:text-[15px]'>
              {focusedPirate.address?.slice(0, 4) +
                '...' +
                focusedPirate.address?.slice(-6)}
            </div>
          </div>
          <div>
            <div className='uppercase text-[10px] sm:text-sm text-[#848484]'>
              Balance:
            </div>
            <div className='font-black text-[15px] sm:text-[20px] leading-[15px] sm:leading-[20px]'>
              {BigNumber.from(focusedPirate.amount).toNumber()}
            </div>
          </div>
        </div>
        <div className='flex items-center gap-x-2'>
          <div className='mt-[10px] sm:mt-0'>
            <DublonButton
              className='w-[70px] h-[70px] sm:w-[100px] sm:h-[100px]'
              onClick={() => {
                setRecepientAddress(focusedPirate.address);
                setFocusedPirate(undefined);
                handleOpenTransferModal();
              }}
            />
          </div>
          <button
            type='button'
            onClick={() => {
              setFocusedPirate(undefined);
              setRecepientAddress(focusedPirate.address);
            }}
          >
            <Icon
              name='close'
              className='w-[30px] h-[30px] sm:w-[40px] sm:h-[40px]'
            />
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
