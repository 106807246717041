import { useChainValues } from '../../utils/chain';
import { GamePanel } from '../GamePanel';
import { Header } from '../Header';
import { Alert, AppBar } from '@mui/material';

export const Layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { address, balanceBst, prizeChest, deposit } = useChainValues();

  const isAuthenticated = !!address && address !== '0x';

  return (
    <div className='min-h-screen flex flex-col bg-[#DDE4E6] relative'>
      <Header
        isAuthenticated={isAuthenticated}
        balanceBst={balanceBst}
        prizeChest={prizeChest}
        deposit={deposit}
      />
      {!isAuthenticated && (
        <AppBar
          sx={{
            position: 'absolute',
            backgroundColor: '#FFF',
          }}
          elevation={0}
        >
          <Alert
            severity='warning'
            sx={{
              width: '100%',
              '& .MuiAlert-message': { textAlign: 'center', width: 'inherit' },
            }}
          >
            <span style={{ margin: 20 }}>
              To start playing you will need a Metamask wallet, please make sure
              it is installed correctly{' '}
            </span>
            <span style={{ margin: 20 }}>
              <a target='_blank' href='https://metamask.io/download/'>
                Get Metamask
              </a>
            </span>
          </Alert>
        </AppBar>
      )}

      <div
        className='h-[474px] bg-repeat-x bg-bottom absolute bottom-0 left-0 right-0'
        style={{
          backgroundImage: `url(/assets/wave.svg)`,
        }}
      />
      {isAuthenticated && (
        <>
          <main className='flex-1 flex '>{children}</main>
          <div className='fixed bottom-0 left-0 right-0 z-20'>
            <GamePanel />
          </div>
        </>
      )}
    </div>
  );
};
