import { useGame } from './../../context/gameContext';
import { useTimer } from '../../hooks/useTimer';
import { cn } from '../../utils/utils';

export const TimerComponent: React.FC<{
  size?: number;
  showTimer?: boolean;
  showMoves?: boolean;
  showProgress?: boolean;
}> = ({
  size = 210,
  showTimer = true,
  showMoves = true,
  showProgress = true,
}) => {
  const { minutes, seconds, progressPercentage } = useTimer();
  const { finishTransactions } = useGame();

  const strokeWidth = 5;
  const radius = (size - strokeWidth * 2) / 2;
  const center = size / 2;
  const circumference = 2 * Math.PI * radius;

  const dashOffset = circumference - (progressPercentage / 100) * circumference;

  const FULL_CIRCLE = 2 * Math.PI;
  const ROTATION_OFFSET = Math.PI / 2;
  const FINE_TUNING = Math.PI / 2;

  const angle =
    FULL_CIRCLE * (1 - progressPercentage / 100) -
    ROTATION_OFFSET +
    FINE_TUNING;

  const dotX = center + radius * Math.cos(angle);
  const dotY = center + radius * Math.sin(angle);

  const timeString = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

  return (
    <div className='flex flex-col items-center gap-y-4'>
      <div
        className='relative'
        style={{
          ...(!showProgress && {
            width: size,
            height: size,
          }),
        }}
      >
        {showProgress && (
          <svg
            width={size}
            height={size}
            style={{ transform: 'rotate(-90deg)' }}
            viewBox={`0 0 ${size + 10} ${size + 10}`}
          >
            <defs>
              <linearGradient id='yellowGradient' x1='0%' y1='0%'>
                <stop offset='0%' stopColor='#F9EA2A' />
                <stop offset='100%' stopColor='#EE8A00' />
              </linearGradient>

              <radialGradient
                id='dotGradient'
                cx='50%'
                cy='50%'
                r='50%'
                fx='50%'
                fy='50%'
              >
                <stop offset='0%' stopColor='white' />
                <stop offset='50%' stopColor='#FFFF4F' />
                <stop offset='100%' stopColor='#FFDD00' />
              </radialGradient>
            </defs>

            {/* Background circle */}
            <circle
              cx={center}
              cy={center}
              r={radius}
              fill='none'
              stroke='#e6e6e6'
              strokeWidth={strokeWidth}
            />

            {/* Progress circle */}
            <circle
              cx={center}
              cy={center}
              r={radius}
              fill='none'
              stroke='#FFCD00'
              strokeWidth={strokeWidth}
              strokeLinecap='round'
              strokeDasharray={circumference}
              strokeDashoffset={-dashOffset}
              style={{ transition: 'stroke-dashoffset 0.3s ease' }}
            />

            {/* Second circle with gradient */}
            <circle
              id='second'
              cx={center}
              cy={center}
              r={radius}
              fill='none'
              stroke='url(#yellowGradient)'
              strokeWidth={3}
              strokeLinecap='round'
              strokeDasharray={circumference}
              strokeDashoffset={-dashOffset}
              style={{ transition: 'stroke-dashoffset 0.3s ease' }}
            />

            {/* Small circle at the end of progress */}
            <circle
              cx={dotX}
              cy={dotY}
              r={10}
              fill='url(#dotGradient)'
              style={{ transition: 'cx 0.3s ease, cy 0.3s ease' }}
            />
          </svg>
        )}

        <div
          className={cn(
            'absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center'
          )}
          style={{
            transform: 'rotate(0deg)',
          }}
        >
          {showMoves && (
            <>
              <div className='text-[10px] text-[#808080] uppercase whitespace-pre-line hidden sm:block'>{`MOVES UNTIL \nTHE END OF THE GAME`}</div>
              <div className='text-[#4D667A] sm:text-[#3C5069] text-[14px] sm:text-[70px] font-robotoCondensed font-medium leading-[14px] sm:leading-[70px]'>
                {finishTransactions}/3
              </div>
            </>
          )}
          {showTimer && (
            <>
              <div className='h-[1px] bg-[#181A26] w-[25px] sm:w-[100px]' />
              <div className='font-medium text-[#181A26] sm:text-[#695B3C] text-[14px] sm:text-[40px] font-robotoCondensed leading-[14px] sm:leading-[40px]'>
                {timeString}
              </div>
              <div className='text-[10px] text-[#808080] uppercase hidden sm:block'>
                TIME OUT
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
