import { Box, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { BigNumber, ethers } from 'ethers';
import depositChestImage from '../../../assets/images/DepositChest.png';
import prizeChestImage from '../../../assets/images/PrizeChest.png';

import { Icon } from './../../shared/Icon';

const GameCard = ({ endgame, winners, startgame, amount }: GameResultData) => {
  return (
    <div className='border-[2px] border-[#FFD900] rounded-[27px] p-[13px] flex flex-col gap-y-2'>
      <div className='flex gap-x-2'>
        <div className='flex-none w-[20px] flex flex-col items-center'>
          <div className='w-[15px] h-[15px] rounded-full bg-[#EFC40A] mt-[19px] sm:mt-[22px]' />
          <div className='w-[1px] h-[26px] sm:h-[32px] bg-[#EFC40A]' />
          <div className='w-[15px] h-[15px] rounded-full bg-[#EFC40A]' />
        </div>
        <div className='flex-1 flex flex-col gap-y-2'>
          <div className='flex flex-col gap-y-1'>
            <div className='text-[10px] uppercase text-[#808080]'>
              GAME START TIME:
            </div>
            <div className='self-stretch font-robotoCondensed text-[15px] sm:text-[20px] text-[#16253D] leading-[15px] sm:leading-[20px] flex items-center font-bold'>
              <div className='flex-1 flex items-end'>
                <div>
                  {dateFormat(
                    new Date(BigNumber.from(startgame).toNumber() * 1000),
                    'ddd, mmmm dS, yyyy'
                  )}
                </div>
                <div className='flex-1 border-b-[1px] border-[#EFC40A] mx-2' />
              </div>
              <div>
                {dateFormat(
                  new Date(BigNumber.from(startgame).toNumber() * 1000),
                  'HH:MM'
                )}
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-y-1'>
            <div className='text-[10px] uppercase text-[#808080]'>
              GAME END TIME:
            </div>
            {endgame ? (
              <div className='self-stretch font-robotoCondensed text-[15px] sm:text-[20px] text-[#16253D] leading-[15px] sm:leading-[20px] flex items-center font-bold'>
                <div className='flex-1 flex items-end'>
                  <div>
                    {dateFormat(
                      new Date(BigNumber.from(endgame).toNumber() * 1000),
                      'ddd, mmmm dS, yyyy'
                    )}
                  </div>
                  <div className='flex-1 border-b-[1px] border-[#EFC40A] mx-2' />
                </div>
                <div>
                  {dateFormat(
                    new Date(BigNumber.from(endgame).toNumber() * 1000),
                    'HH:MM'
                  )}
                </div>
              </div>
            ) : (
              <div className='self-stretch font-robotoCondensed text-[15px] sm:text-[20px] text-[#16253D] leading-[15px] sm:leading-[20px] flex items-center font-bold'>
                This game is not over yet
              </div>
            )}
          </div>
        </div>
      </div>

      {winners && winners.length > 0 && (
        <>
          <div className='flex gap-x-2'>
            <div className='flex-none w-[20px]'>
              <Icon name='winnerAmountIcon' />
            </div>
            <div className='flex-1 flex flex-col gap-y-1'>
              <div className='text-[10px] uppercase text-[#808080]'>
                WINNING AMOUNT
              </div>
              <div className='font-black text-[20px] sm:text-[36px] text-[#EFC40A] leading-[20px] sm:leading-[36px]'>
                {ethers.utils.formatEther(amount)}
              </div>
            </div>
          </div>
          <div className='flex gap-x-2'>
            <div className='flex-none w-[20px]'>
              <Icon name='winnerIcon' />
            </div>
            <div className='flex-1 flex flex-col gap-y-1'>
              <div className='text-[10px] uppercase text-[#808080]'>
                WINNERS:
              </div>
              {winners.map((value: Pirate) => (
                <div
                  key={value.address}
                  className='font-robotoCondensed text-[15px] sm:text-[20px] text-[#16253D] leading-[15px] sm:leading-[20px]'
                >
                  {`${value.address.slice(0, 10)}...${value.address.slice(-6)}`}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const GameCards: React.FC<LeaderboardNS.Profiles> = ({ leaderboard }) => {
  if (leaderboard.length === 0) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        whiteSpace='break-spaces'
        alignItems='center'
        minHeight='20vh'
      >
        <Typography
          sx={{ fontSize: 24 }}
        >{`There were no games during this period`}</Typography>
      </Box>
    );
  } else {
    return (
      <Box
        id='profile'
        sx={{
          overflowY: 'auto',
          gap: '15px',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        {leaderboard.map(GameCard)}
      </Box>
    );
  }
};

export default GameCards;
