import { useState } from 'react';
import { cn, ErrorType, formatToThousands } from '../../utils/utils';
import { useModal } from '../../context/modalContext';
import { Icon } from '../shared';
import { useChainMethods, useChainValues } from '../../utils/chain';
import { ethers } from 'ethers';
import { useGame } from '../../context/gameContext';

export const MintBurnModal = () => {
  const [selectedTab, setSelectedTab] = useState<'MINT' | 'BURN'>('MINT');
  const { closeModal } = useModal();
  const tabButtonClassName =
    'flex-1 h-[40px] sm:h-[87px] flex items-center justify-center text-[14px] sm:text-[24px] uppercase text-[#1A1A1A] font-bold text-[#EFC40A]';

  const { balanceBst, balanceDoubloon } = useChainValues();
  const { burnBst, burnDoubloon } = useChainMethods();
  const [burnOrMint, setDepositOrWithdraw] = useState(0);

  const [amount, setAmount] = useState<string>('0');

  const onChangeAmount = (event: any) => {
    event.preventDefault();
    setAmount(event.target.value);
  };

  const { handleOpenErrorSnackbar } = useGame();

  const handleVectorChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setAmount('0');
    setDepositOrWithdraw(newValue);
  };
  const handleAction = () => {
    return burnOrMint === 0
      ? (() => {
          try {
            burnDoubloon(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.DoubloonBurnError);
            console.error(e);
          }
          closeModal();
          setAmount('0');
        })()
      : (() => {
          try {
            burnBst(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.BstBurnError);
            console.error(e);
          }
          closeModal();
          setAmount('0');
        })();
  };

  const doubloonOutput = (() => {
    try {
      return ethers.utils.formatUnits(Number(amount) * 1000, 8);
    } catch (e) {
      console.error(e);
      return 0;
    }
  })();

  const bsOutput = (() => {
    try {
      return ethers.utils.parseUnits(amount, 8).toNumber() / 1000;
    } catch (e) {
      console.error(e);
      return 0;
    }
  })();

  const handleChangeTab = (tab: 'MINT' | 'BURN') => {
    setSelectedTab(tab);
    setAmount('0');
    setDepositOrWithdraw(tab === 'MINT' ? 0 : 1);
  };

  return (
    <div className='w-[95%] sm:w-[754px] min-h-none sm:min-h-[520px] absolute translate-x-[-50%] translate-y-[-50%] top-1/2 left-1/2'>
      <div className='flex justify-center items-center absolute -top-[30px] sm:-top-[80px] left-0 w-full [&>svg]:w-[100%] sm:[&>svg]:w-auto h-[50px] sm:h-auto'>
        <Icon name='mintBurnWaves' />
      </div>
      <div className='w-full h-full bg-white rounded-[27px] border-[2px] border-[#FFD900] relative'>
        <div className='flex justify-center items-center absolute -top-[50px] sm:-top-[125px] left-0 [&>svg]:w-[50%] sm:[&>svg]:w-auto h-[50px] sm:h-auto w-full'>
          <Icon name='mintBurnModalHeader' />
        </div>
        <div className='flex rounded-t-[27px] overflow-hidden'>
          <button
            type='button'
            onClick={() => handleChangeTab('MINT')}
            className={cn(tabButtonClassName, 'rounded-br-[27px] mint-tab-bg', {
              'bg-none text-black': selectedTab === 'MINT',
            })}
          >
            Mint
          </button>
          <button
            type='button'
            onClick={() => handleChangeTab('BURN')}
            className={cn(tabButtonClassName, 'rounded-bl-[27px] burn-tab-bg', {
              'bg-none text-black': selectedTab === 'BURN',
            })}
          >
            Burn
          </button>
        </div>
        <div className='p-[10px] pb-[30px] sm:pb-[43px] sm:px-[43px] sm:pt-[20px]'>
          {selectedTab === 'MINT' ? (
            <div>
              <div className='font-black text-center text-[15px] sm:text-[36px]'>
                Mint Blakspot
              </div>
              <div className='flex items-center justify-around mt-4'>
                <div className='text-center flex flex-col items-center gap-y-2'>
                  <div>
                    <Icon name='dbl' className='w-[70px] h-[70px]' />
                  </div>
                  <div className='text-[14px] sm:text-[24px] font-light'>
                    Doublon (DBL):
                  </div>
                  <div className='text-[24px] sm:text-[64px] font-black text-[#FFB900] leading-[24px] sm:leading-[70px] font-roboto'>
                    {balanceDoubloon
                      ? ethers.utils.formatUnits(balanceDoubloon, 8)
                      : '...'}
                  </div>
                </div>
                <div className='text-center flex flex-col items-center gap-y-2'>
                  <div>
                    <Icon name='bs' className='w-[70px] h-[70px]' />
                  </div>
                  <div className='text-[14px] sm:text-[24px] font-light'>
                    Blackspot (BS):
                  </div>
                  <div className='text-[24px] sm:text-[64px] font-black text-[#FFB900] leading-[24px] sm:leading-[70px] font-roboto'>
                    {balanceBst ? formatToThousands(Number(balanceBst)) : '...'}
                  </div>
                </div>
              </div>
              <div className='mt-4 max-w-[344px] mx-auto'>
                <div className='text-[14px] text-center text-[#808080] mb-1'>
                  DOUBLON AMOUNT:
                </div>
                <div className='flex items-center justify-center gap-x-2 h-[54px] border-[1px] border-[#B3B3B3] rounded-full'>
                  <div className='flex-1 px-10 h-full'>
                    <input
                      type='number'
                      className='border-none w-full h-full outline-none'
                      min={0}
                      value={amount}
                      onChange={onChangeAmount}
                    />
                  </div>
                  <button
                    type='button'
                    className='rounded-full w-[50px] h-[50px] yellow-btn-bg mr-[1px]'
                    onClick={() => {
                      setAmount(
                        !burnOrMint
                          ? balanceDoubloon
                            ? ethers.utils.formatUnits(balanceDoubloon, 8)
                            : '...'
                          : balanceBst
                      );
                    }}
                  >
                    <span className='uppercase text-white text-[15px] font-medium font-robotoCondensed relative z-10'>
                      max
                    </span>
                  </button>
                </div>
                <div className='mt-2'>
                  <button
                    className='w-full h-[50px] rounded-full yellow-btn-bg disabled:opacity-50'
                    onClick={handleAction}
                    disabled={
                      !burnOrMint ? bsOutput <= 0 : Number(doubloonOutput) < 1
                    }
                  >
                    <span className='uppercase text-white text-[15px] font-medium font-robotoCondensed relative z-10'>
                      Mint
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className='font-black text-center text-[15px] sm:text-[36px]'>
                Burn Blackspot
              </div>
              <div className='flex items-center justify-around mt-4'>
                <div className='text-center flex flex-col items-center gap-y-2'>
                  <div>
                    <Icon name='bs' className='w-[70px] h-[70px]' />
                  </div>
                  <div className='text-[14px] sm:text-[24px] font-light'>
                    Blackspot (BS):
                  </div>
                  <div className='text-[24px] sm:text-[64px] font-black text-[#FFB900] leading-[24px] sm:leading-[70px] font-roboto'>
                    {balanceBst ? formatToThousands(Number(balanceBst)) : '...'}
                  </div>
                </div>
                <div>
                  <Icon
                    name='burn'
                    className='w-[50px] sm:w-[111px] h-[50px] sm:h-[149px]'
                  />
                </div>
                <div className='text-center flex flex-col items-center gap-y-2'>
                  <div>
                    <Icon name='dbl' className='w-[70px] h-[70px]' />
                  </div>
                  <div className='text-[14px] sm:text-[24px] font-light'>
                    Burn Blackspot:
                  </div>
                  <div className='text-[24px] sm:text-[64px] font-black text-[#FFB900] leading-[24px] sm:leading-[70px] font-roboto'>
                    {balanceDoubloon
                      ? ethers.utils.formatUnits(balanceDoubloon, 8)
                      : '...'}
                  </div>
                </div>
              </div>
              <div className='mt-4 max-w-[344px] mx-auto'>
                <div className='text-[14px] text-center text-[#808080] mb-1'>
                  BLACKSPOT AMOUNT:
                </div>
                <div className='flex items-center justify-center gap-x-2 h-[54px] border-[1px] border-[#B3B3B3] rounded-full'>
                  <div className='flex-1 px-10 h-full'>
                    <input
                      type='number'
                      className='border-none w-full h-full outline-none'
                      min={0}
                      value={amount}
                      onChange={onChangeAmount}
                    />
                  </div>
                  <button
                    type='button'
                    className='rounded-full w-[50px] h-[50px] yellow-btn-bg mr-[1px]'
                    onClick={() => {
                      setAmount(balanceBst);
                    }}
                  >
                    <span className='uppercase text-white text-[15px] font-medium font-robotoCondensed relative z-10'>
                      max
                    </span>
                  </button>
                </div>
                <div className='mt-2'>
                  <button
                    className='w-full h-[50px] rounded-full red-btn-bg'
                    onClick={handleAction}
                    disabled={
                      !burnOrMint ? bsOutput <= 0 : Number(doubloonOutput) < 1
                    }
                  >
                    <span className='uppercase text-white text-[15px] font-medium font-robotoCondensed relative z-10'>
                      BURN & GET
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          onClick={closeModal}
          className='absolute -bottom-[28px] left-1/2 -translate-x-1/2'
        >
          <Icon
            name='close'
            className='w-[40px] sm:w-[56px] h-[40px] sm:h-[55px]'
          />
        </button>
      </div>
    </div>
  );
};
