const DefaultStateIcon = () => (
  <svg viewBox='0 0 91 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='45' cy='45' r='40' fill='url(#paint0_radial_1_238)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M90 45C90 20.1474 69.8529 0 44.9993 0C20.1471 0 0 20.1474 0 45C0 69.8526 20.1471 90 44.9993 90C69.8529 90 90 69.8526 90 45ZM85 45C85 22.9088 67.0915 5 44.9994 5C22.9085 5 5 22.9088 5 45C5 67.0912 22.9085 85 44.9994 85C67.0915 85 85 67.0912 85 45Z'
      fill='url(#paint1_linear_1_238)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.4123 77.3161C84.2638 69.2155 89.0966 58.1721 89.0966 46C89.0966 21.1474 68.9495 1 44.0972 1C31.9248 1 20.8812 5.83279 12.7807 13.6842C20.9602 5.24515 32.4161 0 45.0972 0C69.9495 0 90.0966 20.1474 90.0966 45C90.0966 57.6808 84.8514 69.1366 76.4123 77.3161ZM44.0006 85.9711C21.9085 85.9711 4 68.0623 4 45.9711C4 34.6712 8.68565 24.4656 16.2201 17.1913C9.27329 24.3866 5 34.1798 5 44.9711C5 67.0623 22.9085 84.9711 45.0006 84.9711C55.7916 84.9711 65.5846 80.6978 72.7798 73.7511C65.5057 81.2854 55.3003 85.9711 44.0006 85.9711Z'
      fill='#FCF900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3948 9.40961C61.7514 9.40961 75.0105 19.8059 75.0105 32.6301C75.0105 45.4532 61.7514 55.8494 45.3948 55.8494C29.0382 55.8494 15.7792 45.4532 15.7792 32.6301C15.7792 19.8059 29.0382 9.40961 45.3948 9.40961Z'
      fill='url(#paint2_linear_1_238)'
    />
    <rect
      x='27'
      y='42'
      width='36'
      height='6'
      fill='url(#paint3_linear_1_238)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63 42H27V48H28V43H63V42Z'
      fill='#FCF900'
    />
    <defs>
      <radialGradient
        id='paint0_radial_1_238'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(45 45) rotate(90) scale(40)'
      >
        <stop stopOpacity='0' />
        <stop offset='1' stopOpacity='0.1' />
      </radialGradient>
      <linearGradient
        id='paint1_linear_1_238'
        x1='45'
        y1='0'
        x2='45'
        y2='90'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1_238'
        x1='45.3948'
        y1='9.40961'
        x2='45.3948'
        y2='55.8494'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1_238'
        x1='45'
        y1='42'
        x2='45'
        y2='48'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

const HoverStateIcon = () => (
  <svg viewBox='0 0 91 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='45' cy='45' r='40' fill='url(#paint0_radial_1_240)' />
    <circle cx='45' cy='45' r='40' fill='url(#paint1_radial_1_240)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M90 45C90 20.1474 69.8529 0 44.9993 0C20.1471 0 0 20.1474 0 45C0 69.8526 20.1471 90 44.9993 90C69.8529 90 90 69.8526 90 45ZM85 45C85 22.9088 67.0915 5 44.9994 5C22.9085 5 5 22.9088 5 45C5 67.0912 22.9085 85 44.9994 85C67.0915 85 85 67.0912 85 45Z'
      fill='url(#paint2_linear_1_240)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M76.4123 77.3161C84.2638 69.2155 89.0966 58.1721 89.0966 46C89.0966 21.1474 68.9495 1 44.0972 1C31.9248 1 20.8812 5.83279 12.7807 13.6842C20.9602 5.24515 32.4161 0 45.0972 0C69.9495 0 90.0966 20.1474 90.0966 45C90.0966 57.6808 84.8514 69.1366 76.4123 77.3161ZM44.0006 85.9711C21.9085 85.9711 4 68.0623 4 45.9711C4 34.6712 8.68565 24.4656 16.2201 17.1913C9.27329 24.3866 5 34.1798 5 44.9711C5 67.0623 22.9085 84.9711 45.0006 84.9711C55.7916 84.9711 65.5846 80.6978 72.7798 73.7511C65.5057 81.2854 55.3003 85.9711 44.0006 85.9711Z'
      fill='#FCF900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.3948 9.40961C61.7514 9.40961 75.0105 19.8059 75.0105 32.6301C75.0105 45.4532 61.7514 55.8494 45.3948 55.8494C29.0382 55.8494 15.7792 45.4532 15.7792 32.6301C15.7792 19.8059 29.0382 9.40961 45.3948 9.40961Z'
      fill='url(#paint3_linear_1_240)'
    />
    <rect
      x='27'
      y='42'
      width='36'
      height='6'
      fill='url(#paint4_linear_1_240)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63 42H27V48H28V43H63V42Z'
      fill='#FCF900'
    />
    <defs>
      <radialGradient
        id='paint0_radial_1_240'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(35.5 32) rotate(53.514) scale(59.7013)'
      >
        <stop stopColor='#9C9C9C' />
        <stop offset='1' stopColor='#2D2D2D' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_1_240'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(45 45) rotate(90) scale(40)'
      >
        <stop stopOpacity='0' />
        <stop offset='1' stopOpacity='0.5' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_1_240'
        x1='45'
        y1='0'
        x2='45'
        y2='90'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1_240'
        x1='45.3948'
        y1='9.40961'
        x2='45.3948'
        y2='55.8494'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_1_240'
        x1='45'
        y1='42'
        x2='45'
        y2='48'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

const PressStateIcon = () => (
  <svg viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <ellipse
      cx='43.9528'
      cy='44'
      rx='39.0692'
      ry='39.1111'
      fill='url(#paint0_linear_70_323)'
    />
    <ellipse
      cx='43.9528'
      cy='44'
      rx='39.0692'
      ry='39.1111'
      fill='url(#paint1_radial_70_323)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M87.9057 44C87.9057 19.6997 68.2274 0 43.9522 0C19.6782 0 0 19.6997 0 44C0 68.3003 19.6782 88 43.9522 88C68.2274 88 87.9057 68.3003 87.9057 44ZM83.022 44C83.022 22.3997 65.5303 4.88889 43.9523 4.88889C22.3754 4.88889 4.88365 22.3997 4.88365 44C4.88365 65.6003 22.3754 83.1111 43.9523 83.1111C65.5303 83.1111 83.022 65.6003 83.022 44Z'
      fill='url(#paint2_linear_70_323)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M74.6342 75.598C82.8769 67.6002 88 56.399 88 44C88 19.6997 68.3218 1.52588e-05 44.0478 1.52588e-05C31.6618 1.52588e-05 20.4725 5.12856 12.4833 13.38C20.3954 5.70315 31.1819 0.977783 43.0711 0.977783C67.345 0.977783 87.0233 20.6774 87.0233 44.9778C87.0233 56.8794 82.3029 67.6774 74.6342 75.598ZM42.9767 84.0606C21.3987 84.0606 3.90692 66.5498 3.90692 44.9495C3.90692 33.9007 8.48353 23.9219 15.8426 16.8093C9.0575 23.8447 4.88365 33.4203 4.88365 43.9717C4.88365 65.572 22.3754 83.0828 43.9534 83.0828C54.4933 83.0828 64.0585 78.9045 71.0862 72.1122C63.9813 79.4791 54.0134 84.0606 42.9767 84.0606Z'
      fill='#FCF900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.3385 9.2005C60.3145 9.2005 73.265 19.3657 73.265 31.905C73.265 44.4431 60.3145 54.6083 44.3385 54.6083C28.3625 54.6083 15.412 44.4431 15.412 31.905C15.412 19.3657 28.3625 9.2005 44.3385 9.2005Z'
      fill='url(#paint3_linear_70_323)'
    />
    <rect
      x='26.3717'
      y='41.0667'
      width='35.1623'
      height='5.86667'
      fill='url(#paint4_linear_70_323)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.534 41.0667H26.3717V46.9333H27.3484V42.0444H61.534V41.0667Z'
      fill='#FCF900'
    />
    <defs>
      <linearGradient
        id='paint0_linear_70_323'
        x1='43.9528'
        y1='4.88889'
        x2='43.9528'
        y2='83.1111'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <radialGradient
        id='paint1_radial_70_323'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(43.9528 44) rotate(90) scale(39.1111 39.0692)'
      >
        <stop stopColor='#825B00' stopOpacity='0' />
        <stop offset='1' stopColor='#825B00' stopOpacity='0.3' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_70_323'
        x1='43.9528'
        y1='0'
        x2='43.9528'
        y2='88'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_70_323'
        x1='44.3385'
        y1='9.2005'
        x2='44.3385'
        y2='54.6083'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_70_323'
        x1='43.9528'
        y1='41.0667'
        x2='43.9528'
        y2='46.9333'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

interface MinusButtonProps {
  onClick?: () => void;
  className?: string;
}

export const MinusButton: React.FC<MinusButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={`group relative transition-transform duration-100 active:scale-95 ${className}`}
    >
      <div className='absolute inset-0 transition-opacity duration-200 opacity-100 group-hover:opacity-0 group-active:opacity-0'>
        <DefaultStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-100 group-active:opacity-0'>
        <HoverStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-0 group-active:opacity-100'>
        <PressStateIcon />
      </div>
    </button>
  );
};
