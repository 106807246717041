import { Icon } from '../shared/Icon/Icon';
import { useModal } from '../../context/modalContext';
import { BigNumber, ethers } from 'ethers';
import { useGame } from './../../context/gameContext';
import { useChainMethods } from './../../utils/chain';
import { useState } from 'react';
import { ErrorType } from '../../utils/utils';

export const TransferModal = () => {
  const { closeModal } = useModal();
  const { transferBst: transfer } = useChainMethods();

  const [amount, setAmount] = useState<string>('0');

  const onChangeAmount = (event: any) => {
    event.preventDefault();
    setAmount(event.target.value);
  };

  const { recipientAddress, handleOpenErrorSnackbar, setRecipientAddress } =
    useGame();

  const onChangeRecipientAddress = (event: any) => {
    event.preventDefault();
    setRecipientAddress(event.target.value);
  };

  return (
    <div className='w-[95%] sm:w-[500px] min-h-[245px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
      <div className='absolute -top-[50px] sm:-top-[85px] left-0 w-full flex justify-center text-center h-[90px] sm:h-auto'>
        <Icon name='transferHeader' className='w-[100%] sm:w-[500px]' />
      </div>
      <div className='w-full h-full border-[2px] border-[#FFD900] pt-[15px] px-[10px] pb-[30px] relative z-10 rounded-[10px] bg-white'>
        <div className='max-w-[300px] mx-auto text-center flex flex-col gap-y-4'>
          <div className='text-[20px] text-[#1A1A1A] font-black'>
            Mint Blakspot
          </div>
          <div>
            <div className='text-[15px] text-[#808080]'>RECIPIENT</div>
            <div>
              <input
                type='text'
                className='w-full h-[50px] rounded-full bg-white border-[1px] border-[#B3B3B3] px-10'
                value={recipientAddress}
                onChange={onChangeRecipientAddress}
              />
            </div>
          </div>
          <div>
            <div className='text-[15px] text-[#808080]'>BST AMOUNT</div>
            <div>
              <input
                type='number'
                className='w-full h-[50px] rounded-full bg-white border-[1px] border-[#B3B3B3] px-10'
                value={amount}
                onChange={onChangeAmount}
                min={0}
              />
            </div>
          </div>
          <div>
            <button
              type='button'
              className='yellow-btn-bg w-full h-[50px] rounded-full disabled:opacity-50'
              disabled={
                !ethers.utils.isAddress(recipientAddress) ||
                !amount ||
                BigNumber.from(amount).lte(0)
              }
              onClick={(() => {
                try {
                  return BigNumber.from(amount).gte(0)
                    ? () => {
                        try {
                          transfer(recipientAddress, String(amount));
                          setAmount('0');
                          closeModal();
                        } catch (e) {
                          console.error(e);
                          handleOpenErrorSnackbar(ErrorType.ErrorTransaction);
                        }
                        closeModal();
                        setAmount('0');
                      }
                    : () =>
                        console.error(
                          'trasfer tokens amount is null or signer is undefined'
                        );
                } catch (e) {
                  return () => console.error('Wrong amount', { e });
                }
              })()}
            >
              <span className='text-white relative z-10 uppercase text-[15px] font-robotoCondensed'>
                TRANSFER TOKENS
              </span>
            </button>
          </div>
          <div className='absolute left-0 -bottom-[20px] sm:-bottom-[28px] w-full flex justify-center'>
            <button type='button' onClick={() => closeModal()}>
              <Icon
                name='close'
                className='w-[40px] h-[40px] sm:w-[56px] sm:h-[55px]'
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
