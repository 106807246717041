import { Stack } from '@mui/material';
import { useDrag, usePinch, useWheel } from '@use-gesture/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useChainValues } from '../utils/chain';
import { BubbleCallout } from './components/bubbleCallout/BubbleCallout';
import styles from './styles.module.scss';
import { useGame } from '../context/gameContext';
type LineProps = {
  y1: number | string;
  y2: number | string;
  x1: number | string;
  x2: number | string;
  [args: string]: any;
};
type TextProps = {
  x: number | string;
  y: number | string;
  [args: string]: any;
};
type CircleProps = {
  cx: number | string;
  cy: number | string;
  r: number | string;
  [args: string]: any;
};

export const Chart = ({ children }: { children: React.ReactNode }) => {
  const { address } = useChainValues();
  const {
    pirates,
    recipientAddress,
    handleOpenModal,
    setRecipientAddress,
    scale,
    setScale,
    inGameOnly,
  } = useGame();

  useEffect(() => {
    console.log(
      '____________\n\nWALLETS:',
      { wallets: pirates },
      '\n^^^^^^^^^^^^^^^^^\n\n'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Line: React.FC<LineProps> = ({ y1, y2, x1, x2, ...args }) => {
    return <line y1={-y1} y2={-y2} x1={x1} x2={x2} {...args}></line>;
  };
  const Circle: React.FC<CircleProps> = ({ cx, cy, r, ...args }) => {
    return <circle cx={cx} cy={-cy} r={r} {...args}></circle>;
  };

  const scaledPx = (px: number) => `${px / scale}px`;

  const toLog = (x: number) => {
    return Math.log10(x) * 100;
  };
  const handleBubbleClick = (pirateBubble: PirateBubble) => {
    setFocusedPirate(pirateBubble);
  };

  const AvgLine = () => {
    const y = toLog(
      pirates
        .filter((wallet) => wallet.isInGame)
        .reduce((avg, value, _, { length }) => {
          return avg + value.amount / length;
        }, 0)
    );
    return (
      <Line
        y1={y}
        y2={y}
        x1={-10000}
        x2={+10000}
        strokeWidth={scaledPx(3)}
        stroke='gray'
        strokeDasharray={scaledPx(50)}
      />
    );
  };
  const PirateBubble = (pirateBubble: PirateBubble) => {
    const isInGame = pirateBubble.isInGame;
    const isCurrentPlayer = pirateBubble.address === address;
    const hasTransfered = pirateBubble.hasTransfered;
    const isRecepient = pirateBubble.address === recipientAddress;
    const bubbleStyle = {
      fill: isInGame
        ? hasTransfered
          ? isCurrentPlayer
            ? 'red'
            : 'white'
          : 'gray'
        : 'black',
      strokeWidth: '5px',
      stroke: isCurrentPlayer ? 'red' : isInGame ? 'black' : 'none',
      filter: isRecepient
        ? 'drop-shadow( 0px 0px 10px rgba(0,100, 50, 1))'
        : '',
    };
    return (
      <Circle
        key={pirateBubble.address}
        cx={pirateBubble.x}
        cy={pirateBubble.y}
        r={pirateBubble.r}
        style={bubbleStyle}
        onClick={() => handleBubbleClick(pirateBubble)}
      />
    );
  };

  const getBubbleData = (amount: number) => {
    const r = Math.log10(amount) * 4;
    return { r: r <= 10 ? 10 : r, y: toLog(amount) };
  };

  const bubbleField = useRef<SVGSVGElement>(null);

  useEffect(() => {
    setCoords({
      x: -400,
      y: -1600,
    });
  }, [bubbleField]);

  const [coords, setCoords] = useState({
    x: 0,
    y: -50,
  });
  const [focusedPirate, setFocusedPirate] = useState<PirateBubble>();
  const [preBubbles, setPreBubbles] = useState<any>();
  const [pirateBubbles, setPirateBubbles] = useState<any>();
  useEffect(() => {
    setPirateBubbles(preBubbles);
  }, [preBubbles]);

  const renderPlayers = () => {
    const _pirates = inGameOnly
      ? pirates.filter((pirate) => pirate.isInGame)
      : pirates;
    const sorted = (_pirates ?? []).sort((w1, w2) => w1.amount - w2.amount);

    let currentDeltaX = 0;
    const walletBubbles: PirateBubble[] = [];
    setPreBubbles(
      sorted
        .map((wallet, id) => {
          const { y: y1, r: r1 } = getBubbleData(wallet.amount);
          if (id === 0) {
            walletBubbles.push({ ...wallet, y: y1, r: r1, x: currentDeltaX });
            return walletBubbles[0];
          }
          let testX = 0;
          while (
            // eslint-disable-next-line no-loop-func
            walletBubbles.find((_wallet) => {
              const distance = Math.sqrt(
                Math.pow(_wallet.x - testX, 2) + Math.pow(_wallet.y - y1, 2)
              );
              return _wallet.r + r1 > distance;
            })
          ) {
            testX += r1 * 1.2;
          }
          walletBubbles.push({ ...wallet, x: testX, y: y1, r: r1 });

          return walletBubbles[walletBubbles.length - 1];
        })
        .map((w) =>
          PirateBubble({
            address: w.address,
            amount: w.amount,
            isInGame: w.isInGame,
            hasTransfered: w.hasTransfered,
            x: w.x,
            y: w.y,
            r: w.r,
          })
        )
    );
  };

  const bindPlaygroundDrag = useDrag(
    ({ offset: [ox, oy] }) => {
      setCoords({
        x: ox,
        y: oy,
      });
    },
    {
      bounds: { top: -4000, bottom: -200 / scale },
      transform: (v) => {
        return [(-2.5 / scale) * v[0], (-2.5 / scale) * v[1]];
      },
      from: () => [coords.x, coords.y],
      rubberband: true,
    }
  );
  // const bindPlaygroundPinch =
  useWheel(
    ({ direction: [_, y] }) => {
      if ((y > 0 && scale + y * 0.05 < 5) || (y < 0 && scale + y * 0.05 > 0.5))
        setScale((s) => s + y * 0.05);
    },
    { from: [100, 0], target: bubbleField, scaleBounds: { min: 0.5, max: 5 } }
  );

  usePinch(
    ({ offset: [s, _] }) => {
      setScale(s);
    },
    {
      target: bubbleField,
      scaleBounds: { min: 0.5, max: 5 },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => renderPlayers(), [address, pirates, inGameOnly]);

  return (
    <>
      <Stack gap={1} direction='row' position={'relative'} zIndex={9}>
        {children}
        <Stack alignItems='center' direction='row'></Stack>
      </Stack>
      <div {...bindPlaygroundDrag()} className={`${styles.Chart} relative`}>
        <svg width='100%' height='100%' ref={bubbleField}>
          <svg
            id='playground'
            viewBox={`
            ${coords.x}
            ${coords.y}
            ${100 / scale}
            ${1900 / scale}`}
            x='0'
            preserveAspectRatio='xMinYMin meet'
          >
            <AvgLine />

            <>{pirateBubbles}</>
          </svg>
        </svg>
        <BubbleCallout
          scale={scale}
          focusedPirate={focusedPirate}
          setFocusedPirate={setFocusedPirate}
          handleOpenModal={handleOpenModal}
          setRecepientAddress={setRecipientAddress}
        />
      </div>
    </>
  );
};
