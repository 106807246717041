import { Icon } from '../shared/Icon/Icon';
import { useModal } from '../../context/modalContext';
import { useGame } from '../../context/gameContext';
import { useChainMethods, useChainValues } from '../../utils/chain';
import { useState } from 'react';
import { ErrorType } from '../../utils/utils';
import { Link } from '@mui/material';
import { Stack, Tab, Tabs } from '@mui/material';
import { TextFieldCustom } from '../gameStatus/GameStatus';
import { ethers } from 'ethers';

export const DepositWithdrawModal = () => {
  const { closeModal } = useModal();
  const [selectedTab, setSelectedTab] = useState<'DEPOSIT' | 'WITHDRAW'>(
    'DEPOSIT'
  );

  const [amount, setAmount] = useState<string>('0');

  const onChangeAmount = (event: any) => {
    event.preventDefault();
    setAmount(event.target.value);
  };

  const { handleOpenErrorSnackbar } = useGame();

  const { deposit } = useChainValues();
  const { depositETH, withdraw } = useChainMethods();
  const [depositOrWithdraw, setDepositOrWithdraw] = useState(0);

  const handleAction = () => {
    if (!amount) return console.error('trasfer tokens amount is null');
    return depositOrWithdraw === 0
      ? (() => {
          try {
            depositETH(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.ReplenishingError);
            console.error(e);
          }
          closeModal();
          setAmount('0');
        })()
      : (() => {
          try {
            withdraw(String(amount));
          } catch (e) {
            handleOpenErrorSnackbar(ErrorType.WithdrawingError);
            console.error(e);
          }
          closeModal();
          setAmount('0');
        })();
  };

  const handleChangeTab = (tab: 'DEPOSIT' | 'WITHDRAW') => {
    setSelectedTab(tab);
  };

  const tabButtonClassName = 'w-full h-[50px] rounded-full bg-white';

  return (
    <div className='w-[95%] sm:w-[500px] min-h-[245px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
      <div className='absolute -top-[50px] sm:-top-[55px] left-0 w-full flex justify-center text-center h-[90px] sm:h-auto'>
        <Icon name='transferHeader' className='w-[100%] sm:w-[500px]' />
      </div>
      <div className='w-full h-full border-[2px] border-[#FFD900] pt-[15px] px-[10px] pb-[30px] relative z-10 rounded-[10px] bg-white'>
        <div>
          <Tabs
            value={selectedTab === 'DEPOSIT' ? 0 : 1}
            onChange={(_, newValue) =>
              handleChangeTab(newValue === 0 ? 'DEPOSIT' : 'WITHDRAW')
            }
          >
            <Tab label='Deposit'></Tab>
            <Tab label='Withdraw'></Tab>
          </Tabs>
          <div className='p-[10px] flex flex-col gap-y-2'>
            <div className='text-center text-[24px] font-black'>
              {selectedTab === 'DEPOSIT'
                ? 'Replenishing your chest'
                : 'Withdraw from chest'}
            </div>
            <div className='text-center text-[15px]'>
              Balance: {deposit ? ethers.utils.formatEther(deposit) : '... '}
            </div>
            <Stack direction='column' spacing={2}>
              <TextFieldCustom
                label='ETH amount'
                value={amount}
                onChange={onChangeAmount}
                InputProps={{
                  inputProps: {
                    min: 0,
                    type: 'string',
                  },
                }}
              />
              {!!depositOrWithdraw && (
                <Link
                  component='button'
                  variant='body2'
                  onClick={() => {
                    setAmount(
                      deposit ? ethers.utils.formatEther(deposit) : '0'
                    );
                  }}
                >
                  Withdraw all
                </Link>
              )}
              <button
                type='button'
                onClick={handleAction}
                disabled={!amount || amount === '0'}
                className='w-full h-[50px] rounded-full yellow-btn-bg disabled:opacity-50'
              >
                <span className='text-white text-[15px] font-medium uppercase'>
                  {depositOrWithdraw === 0 ? 'Deposit' : 'Withdraw'}
                </span>
              </button>
            </Stack>
          </div>
        </div>
        <div className='absolute left-0 -bottom-[20px] w-full flex justify-center'>
          <button type='button' onClick={() => closeModal()}>
            <Icon
              name='close'
              className='w-[40px] h-[40px] sm:w-[56px] sm:h-[55px]'
            />
          </button>
        </div>
      </div>
    </div>
  );
};
