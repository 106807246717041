import { useState } from 'react';
import { useGame } from '../../context/gameContext';
import {
  MinusButton,
  PlusButton,
  StarButton,
  EyeButton,
  DublonButton,
} from '../CustomButtons';
import { ethers } from 'ethers';
import { ModalContentType } from '../../utils/utils';
import { TimerComponent } from '../timer';

const WalletIcon = () => (
  <svg
    width='25'
    height='26'
    viewBox='0 0 25 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 3.29663C0 1.63978 1.34315 0.296631 3 0.296631H22C23.6569 0.296631 25 1.63978 25 3.29663V5.29662H16C14.3431 5.29662 13 6.63977 13 8.29662V16.2966C13 17.9535 14.3431 19.2966 16 19.2966H25V22.2966C25 23.9535 23.6569 25.2966 22 25.2966H3C1.34315 25.2966 0 23.9535 0 22.2966V3.29663ZM16 7.29662H25V17.2966H16C15.4477 17.2966 15 16.8489 15 16.2966V8.29662C15 7.74434 15.4477 7.29662 16 7.29662ZM20 14.2966C21.1045 14.2966 22 13.4012 22 12.2966C22 11.192 21.1045 10.2966 20 10.2966C18.8954 10.2966 18 11.192 18 12.2966C18 13.4012 18.8954 14.2966 20 14.2966Z'
      fill='#B3B3B3'
    />
  </svg>
);

export const GamePanel = () => {
  const [opponentWallet, setOpponentWallet] = useState('');
  const {
    handleOpenModal,
    setScale,
    inGameOnly,
    setInGameOnly,
    resetScale,
    scale,
  } = useGame();

  const handleMinusButtonClick = () => {
    setScale(scale - 0.1);
  };

  const handlePlusButtonClick = () => {
    setScale(scale + 0.1);
  };

  const handleDublonButtonClick = () => {
    handleOpenModal(ModalContentType.TransferToAnother, opponentWallet);
  };

  const handleStarButtonClick = () => {
    resetScale();
  };

  const handleEyeButtonClick = () => {
    setInGameOnly(!inGameOnly);
  };

  return (
    <div className='self-stretch flex justify-center'>
      <div className='flex-1 flex flex-col items-center'>
        <div className='flex items-center gap-x-[15px] sm:gap-x-[55px] mb-[-15px] sm:mb-[-10px]'>
          <div>
            <EyeButton
              onClick={handleEyeButtonClick}
              isChecked={inGameOnly}
              className='w-[44px] sm:w-[71px] h-[44px] sm:h-[71px] '
            />
          </div>
          <div className=''>
            <div className='w-[44px] h-[44px] sm:hidden mb-[20px]'>
              <TimerComponent size={44} showProgress={false} />
            </div>
          </div>
          <div>
            <StarButton
              onClick={handleStarButtonClick}
              className='w-[44px] sm:w-[71px] h-[44px] sm:h-[71px]'
            />
          </div>
        </div>
        <div className='flex items-center gap-x-4 sm:gap-x-11'>
          <div>
            <MinusButton
              onClick={handleMinusButtonClick}
              className='w-[70px] sm:w-[113px] h-[70px] sm:h-[113px]'
            />
          </div>
          <div className='bg-white rounded-full p-2 pb-0 rounded-b-none relative'>
            <div className='absolute -top-1 left-1 w-full h-full items-center justify-center hidden sm:flex'>
              <TimerComponent showMoves={false} showTimer={false} size={170} />
            </div>
            <div className='absolute -top-1 left-1 w-full h-full flex items-center justify-center sm:hidden'>
              <TimerComponent showMoves={false} showTimer={false} size={120} />
            </div>
            <div className='relative z-10'>
              <DublonButton
                onClick={handleDublonButtonClick}
                disabled={!ethers.utils.isAddress(opponentWallet)}
                className='w-[100px] sm:w-[162px] h-[100px] sm:h-[165px]'
              />
            </div>
          </div>
          <div>
            <PlusButton
              onClick={handlePlusButtonClick}
              className='w-[70px] sm:w-[113px] h-[70px] sm:h-[113px]'
            />
          </div>
        </div>
        <div className='self-stretch h-[35px] sm:h-[57px] flex items-end w-[90%] sm:w-[490px] mx-auto'>
          <div
            className='flex-none w-[45px] sm:w-[70px] h-full bg-cover'
            style={{
              backgroundImage: `url(/assets/input-bg.svg)`,
            }}
          />
          <div className='flex-1 h-full flex items-center gap-x-4 bg-white justify-center'>
            <WalletIcon />
            <div className='w-[170px] sm:w-[220px]'>
              <input
                type='text'
                className='bg-white border-none w-full h-full outline-none'
                placeholder="enter your opponent's wallet"
                value={opponentWallet || ''}
                onChange={(e) => setOpponentWallet(e.target.value)}
              />
            </div>
          </div>
          <div
            className='flex-none w-[45px] sm:w-[70px] h-full bg-cover bg-right'
            style={{
              backgroundImage: `url(/assets/input-bg.svg)`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
