export const CupIconDefaultState = () => (
  <svg viewBox='0 0 100 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M49.9964 85.8387C77.6086 85.8387 99.9927 78.7098 99.9927 69.9158C99.9927 61.1218 77.6086 53.9929 49.9964 53.9929C22.3841 53.9929 0 61.1218 0 69.9158C0 78.7098 22.3841 85.8387 49.9964 85.8387Z'
      fill='black'
      fillOpacity='0.4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.3755 42.418C73.0297 49.3713 64.2873 55.3338 56.9298 50.7586C49.581 46.1833 48.5855 44.2102 49.4684 41.2289C50.3513 38.2477 54.9303 36.2487 54.9822 34.9046C55.0342 33.5518 54.7052 32.0267 53.8223 31.5614C54.8611 29.5797 56.9904 25.2026 57.579 22.8934C59.6131 23.5138 60.6172 24.2031 61.2145 25.1509C62.0714 24.1342 64.3479 24.1773 65.3866 25.2457C66.4253 26.3141 66.0185 27.6755 68.3642 28.046C70.0002 28.3045 71.1428 28.9076 71.991 30.6481C73.8001 34.379 77.7126 35.4732 75.3668 42.4266L75.3755 42.418Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.5877 22.8935C57.5877 22.8935 56.9818 22.8332 56.7394 23.3588C56.497 23.8758 52.5499 31.5443 53.1299 31.8286C53.7098 32.113 54.9043 33.2762 54.9822 34.9822C55.5449 32.3456 57.5877 22.8935 57.5877 22.8935Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.6523 51.6289C58.0724 51.3963 57.5011 51.1119 56.9385 50.7587C53.2164 48.4409 51.1217 46.7866 50.083 45.339C49.9099 41.8925 53.0347 42.2285 54.6533 42.5042C57.0856 42.9178 58.2542 40.3416 60.1065 39.9452C61.9589 39.5489 61.881 40.6776 63.1794 40.7724C64.4777 40.8585 64.547 40.0831 67.0832 40.8068C69.6193 41.5392 70.3811 44.5118 68.9355 48.8028C68.1046 51.2584 62.9283 51.7065 58.6523 51.6375V51.6289Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.6523 51.6289C58.0724 51.3963 57.5011 51.1119 56.9385 50.7587C55.2073 49.6816 53.8224 48.7425 52.7404 47.9067C54.6274 44.4515 59.1111 42.5646 63.7593 43.5554C66.0704 44.0552 68.0526 45.1839 69.4982 46.6832C69.377 47.3466 69.1952 48.0532 68.9442 48.7942C68.1132 51.2498 62.937 51.6978 58.661 51.6289H58.6523Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9822 34.9736C54.9822 34.9477 54.9909 34.9305 54.9909 34.9047C55.0428 33.5519 54.7139 32.0268 53.831 31.5615C54.8697 29.5798 56.9991 25.2027 57.5877 22.8935C59.6218 23.5139 60.6259 24.2032 61.2231 25.151C62.0801 24.1343 64.3566 24.1774 65.3953 25.2458C66.434 26.3142 66.0271 27.6756 68.3729 28.0461C70.0089 28.3046 71.1514 28.9077 71.9997 30.6482C72.545 31.7683 73.2721 32.6472 73.9732 33.526C74.2329 34.3877 74.2849 35.5336 73.8001 36.9812C72.4671 40.9619 69.5155 43.116 66.5984 38.894C64.8326 40.1348 60.4354 40.6087 56.2373 36.671C55.7699 36.2316 55.3458 35.6543 54.9822 34.9736Z'
      fill='#626963'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.2303 39.6953C67.0226 39.4541 66.8148 39.187 66.6071 38.8854C65.88 39.3938 64.7028 39.7815 63.2746 39.7729C61.855 38.9629 61.05 37.3086 61.4136 35.6284C61.8637 33.5519 63.9151 32.2336 66.0012 32.6817C68.0872 33.1297 69.4116 35.1718 68.9615 37.2483C68.7364 38.3081 68.0872 39.1611 67.239 39.6953H67.2303Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.6244 30.4845C66.7196 29.1748 61.8983 26.7364 61.5521 27.4774C61.2837 28.0547 61.7858 28.1753 61.6905 28.5372C61.5867 28.9336 60.9202 28.7957 60.8855 30.0537C60.8596 31.3117 66.0445 31.5357 66.6244 30.4845Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.6244 30.4845C66.6244 30.45 66.6244 30.4156 66.6244 30.3811C65.5857 29.0714 62.1407 27.4516 61.8464 28.0719C61.578 28.6492 62.0801 28.7699 61.9849 29.1317C61.881 29.5195 61.2578 29.3988 61.1885 30.5362C62.2965 31.3461 66.131 31.372 66.6244 30.4759V30.4845Z'
      fill='#181A26'
    />
    <path
      d='M62.0931 35.9066C61.6781 37.8259 62.9047 39.7166 64.8328 40.1297C66.7609 40.5428 68.6604 39.3218 69.0754 37.4025C69.4904 35.4832 68.2637 33.5925 66.3356 33.1794C64.4075 32.7663 62.5081 33.9873 62.0931 35.9066Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.074 37.4034C68.6585 39.3248 66.7629 40.5397 64.8326 40.1261C63.4823 39.8418 62.4782 38.8251 62.1407 37.5929C62.1407 37.3517 62.1666 37.1018 62.2186 36.8519C62.634 34.9305 64.5297 33.7156 66.4599 34.1206C67.8103 34.4049 68.8143 35.4216 69.1519 36.6624C69.1519 36.9036 69.126 37.1535 69.074 37.4034Z'
      fill='#181A26'
    />
    <path
      d='M64.2091 26.847C64.0834 27.0727 65.3795 28.0277 67.1042 28.9801C68.8289 29.9324 70.329 30.5215 70.4547 30.2958C70.5805 30.0701 69.2843 29.1151 67.5597 28.1628C65.835 27.2104 64.3349 26.6213 64.2091 26.847Z'
      fill='#4D4D4D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.459 30.2949C70.3291 30.519 68.8316 29.9331 67.1091 28.9767C65.906 28.3132 64.9105 27.6411 64.4604 27.2275C64.4345 27.1758 64.4258 27.1328 64.4345 27.1069C64.5643 26.8829 66.0618 27.4688 67.7843 28.4252C68.9874 29.0887 69.9829 29.7607 70.433 30.1743C70.459 30.226 70.4676 30.2691 70.4503 30.2949H70.459Z'
      fill='#181A26'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.7577 47.9239C49.2261 45.2011 48.8019 43.5123 49.4771 41.229C50.36 38.2478 54.939 36.2488 54.9909 34.9046C55.0428 33.5519 54.7139 32.0268 53.831 31.5615C64.6162 33.2245 53.9782 40.8671 50.8967 43.1763C49.9532 43.8828 51.3901 46.1403 52.7577 47.9239Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.9894 30.7085C60.548 30.6137 60.4787 31.1738 60.9288 31.3289C62.1493 32.0613 66.1137 31.7339 66.5378 30.7688C66.0098 31.6563 62.2186 31.5357 60.9894 30.7171V30.7085Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.6576 37.705C71.2726 40.7724 69.6453 40.669 69.5674 38.5235C69.4895 36.378 70.9264 28.9853 71.6794 30.6568C73.2548 34.1637 74.9167 32.673 72.6489 37.705H72.6576Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9822 34.9736V34.965C54.9822 34.9477 54.9909 34.9305 54.9909 34.9046C55.0428 33.5519 54.7139 32.0268 53.831 31.5615C59.5266 32.4404 59.2496 34.9908 57.2587 37.5326C56.9212 37.2741 56.5836 36.9812 56.246 36.671C55.7786 36.2316 55.3544 35.6543 54.9909 34.9736H54.9822Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.842 16.259C20.376 19.7744 16.0135 29.3902 21.8302 35.7921C27.647 42.1941 29.7677 42.8144 32.5635 41.4272C35.3594 40.04 36.5279 35.2062 37.8523 34.9133C39.1766 34.6203 40.7433 34.6806 41.3665 35.4647C43.141 34.0947 47.0967 31.2427 49.278 30.2605C48.3086 28.3735 47.4516 27.5119 46.4129 27.0897C47.2612 26.0729 46.8197 23.8499 45.582 23.0142C44.3442 22.1784 43.0718 22.816 42.2927 20.5844C41.7561 19.0334 40.9597 18.0081 39.0814 17.4825C35.0737 16.3624 33.3079 12.7263 26.842 16.2417V16.259Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.278 30.2605C49.278 30.2605 49.4425 30.8464 48.9751 31.1738C48.499 31.5012 41.6176 36.7141 41.228 36.1971C40.8472 35.6801 39.4882 34.7151 37.783 34.9391C40.2932 33.9224 49.278 30.2605 49.278 30.2605Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.6703 34.2584C20.9993 34.784 21.3888 35.2924 21.8389 35.7922C24.7905 39.0405 26.79 40.7982 28.4087 41.5651C31.8451 41.1256 30.9708 38.1272 30.4082 36.5848C29.5686 34.2757 31.9143 32.673 31.9836 30.7861C32.0528 28.8991 30.9449 29.1748 30.6332 27.9168C30.313 26.6589 31.0747 26.4521 29.9062 24.0998C28.7376 21.7476 25.6561 21.5149 21.6744 23.6862C19.3893 24.927 19.8567 30.0795 20.679 34.2584H20.6703Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.6703 34.2584C20.9993 34.784 21.3888 35.2924 21.8389 35.7921C23.2152 37.3 24.375 38.489 25.3964 39.4024C28.4779 36.9467 29.5599 32.2164 27.7595 27.8393C26.8593 25.6594 25.3964 23.9189 23.6653 22.7643C23.0334 22.9969 22.3669 23.3071 21.6744 23.6776C19.3893 24.9184 19.8567 30.0709 20.679 34.2498L20.6703 34.2584Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.783 34.9391C37.809 34.9305 37.8263 34.9219 37.8523 34.9219C39.1766 34.6289 40.7433 34.6892 41.3665 35.4733C43.141 34.1033 47.0967 31.2513 49.278 30.2691C48.3086 28.3821 47.4516 27.5205 46.4129 27.0983C47.2612 26.0816 46.8197 23.8586 45.582 23.0228C44.3442 22.187 43.0717 22.8246 42.2927 20.593C41.7561 19.042 40.9597 18.0167 39.0814 17.4911C37.8782 17.1551 36.8741 16.595 35.8874 16.0608C34.9872 15.9574 33.8532 16.1039 32.4943 16.8363C28.7895 18.8439 27.1796 22.1094 31.871 24.2291C30.9535 26.185 31.2651 30.5707 35.8874 33.9913C36.4067 34.3791 37.0473 34.6892 37.783 34.9305V34.9391Z'
      fill='#626963'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.9708 23.7638C31.2392 23.9275 31.5421 24.0826 31.8797 24.229C31.4988 25.039 31.3344 26.2539 31.5854 27.6497C32.6328 28.8991 34.4159 29.3988 36.0086 28.744C37.9821 27.9427 38.9256 25.6938 38.1206 23.7379C37.3156 21.7734 35.0564 20.8342 33.0915 21.6355C32.0874 22.0405 31.3517 22.8246 30.9708 23.7551V23.7638Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.1807 22.7384C41.4618 22.411 44.7164 26.7105 44.0499 27.1758C43.5219 27.5377 43.3141 27.0724 42.9765 27.2275C42.6043 27.3998 42.8554 28.0288 41.6176 28.2787C40.3798 28.5286 39.2458 23.488 40.1807 22.7298V22.7384Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.1807 22.7384C40.2153 22.7298 40.2413 22.7298 40.2759 22.7212C41.7561 23.5139 43.9633 26.5985 43.4007 26.9949C42.8727 27.3568 42.6649 26.8915 42.3273 27.0466C41.9638 27.2103 42.1975 27.8048 41.0809 28.0719C40.0855 27.1327 39.3843 23.376 40.1893 22.7298L40.1807 22.7384Z'
      fill='#181A26'
    />
    <path
      d='M35.617 28.1327C33.7917 28.8763 31.7064 28.0061 30.9594 26.1892C30.2124 24.3722 31.0866 22.2964 32.9119 21.5529C34.7372 20.8093 36.8225 21.6794 37.5695 23.4964C38.3165 25.3134 37.4424 27.3891 35.617 28.1327Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.9097 21.5494C31.0834 22.2904 30.2091 24.3669 30.9535 26.185C31.4729 27.4602 32.6501 28.2615 33.9398 28.3821C34.1822 28.339 34.4245 28.2701 34.6582 28.1753C36.4846 27.4343 37.3589 25.3578 36.6145 23.5397C36.0951 22.2732 34.9179 21.4632 33.6282 21.3426C33.3858 21.3857 33.1435 21.4546 32.9097 21.5494Z'
      fill='#181A26'
    />
    <path
      d='M44.2053 24.4687C44.0042 24.6316 42.8322 23.529 41.5876 22.0061C40.343 20.4831 39.4971 19.1165 39.6982 18.9536C39.8993 18.7908 41.0713 19.8933 42.3159 21.4163C43.5606 22.9392 44.4065 24.3059 44.2053 24.4687Z'
      fill='#4D4D4D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.696 18.9559C39.4969 19.1196 40.3365 20.4896 41.5829 22.006C42.4485 23.0745 43.2881 23.9275 43.7815 24.298C43.8421 24.3152 43.8767 24.3152 43.9114 24.298C44.1105 24.1343 43.2622 22.7643 42.0244 21.2478C41.1588 20.1794 40.3192 19.3264 39.8258 18.9559C39.7652 18.9386 39.7219 18.9386 39.696 18.9559Z'
      fill='#181A26'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.3705 39.3938C28.6857 42.3836 30.4255 42.4956 32.5635 41.4358C35.3594 40.0486 36.5279 35.2148 37.8523 34.9219C39.1766 34.6289 40.7433 34.6893 41.3665 35.4733C37.8263 25.1941 32.1394 36.964 30.3909 40.3932C29.8542 41.4444 27.37 40.4363 25.3705 39.4024V39.3938Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.9511 28.3046C41.1155 28.7181 40.5789 28.8905 40.3538 28.4769C39.419 27.4085 39.0468 23.4708 39.9297 22.8763C39.142 23.5484 39.9297 27.2447 40.9597 28.3046H40.9511Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.9922 18.0942C29.205 19.9898 29.5859 21.5666 31.7239 21.265C33.8532 20.9635 40.9164 18.258 39.1333 17.8099C35.394 16.8793 36.5712 14.9838 31.9922 18.0942Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.783 34.9391H37.7917C37.809 34.9305 37.8263 34.9305 37.8436 34.9219C39.1679 34.6289 40.7347 34.6892 41.3579 35.4733C39.4882 30.045 37.0213 30.7688 34.8487 33.1642C35.1689 33.4485 35.5065 33.7328 35.8787 34.0086C36.3981 34.3963 37.0386 34.7065 37.7743 34.9477L37.783 34.9391Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M64.2008 35.4303C54.9822 38.0927 46.7159 49.1043 52.3076 58.8752C57.8993 68.6461 60.4181 70.0334 64.3652 69.0511C68.3036 68.0688 71.1687 62.2442 72.9345 62.2442C74.7003 62.2442 76.6739 62.7526 77.2452 63.933C79.8939 62.6923 85.7279 60.1763 88.7835 59.5301C88.0737 56.859 87.2341 55.5235 86.0309 54.6963C87.3985 53.6451 87.4678 50.6897 86.1261 49.2853C84.7844 47.8808 82.984 48.3289 82.6291 45.2787C82.3781 43.1505 81.6597 41.6254 79.4265 40.445C74.6484 37.9031 73.4279 32.7764 64.2094 35.4389L64.2008 35.4303Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M88.7748 59.5215C88.7748 59.5215 88.8181 60.3142 88.1256 60.5985C87.4331 60.8829 77.2019 65.5874 76.8557 64.8205C76.5181 64.0537 75.0552 62.4424 72.8307 62.2529C76.3103 61.6669 88.7748 59.5301 88.7748 59.5301V59.5215Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2516 56.6005C51.5199 57.3588 51.8661 58.1256 52.2989 58.8752C55.1294 63.8296 57.1808 66.6213 59.0159 68.0516C63.5169 68.4566 63.2486 64.3983 62.9716 62.2787C62.5561 59.1165 65.9925 57.7293 66.6157 55.3512C67.239 52.9731 65.7502 53.0162 65.7069 51.3274C65.6549 49.6386 66.685 49.5869 65.8627 46.2696C65.0404 42.9437 61.1885 41.7977 55.5016 43.4434C52.247 44.3826 51.3901 51.0689 51.2429 56.6092L51.2516 56.6005Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2516 56.6005C51.5199 57.3588 51.8661 58.1256 52.2989 58.8752C53.6146 61.1758 54.7658 63.0197 55.8046 64.4672C60.4181 62.2097 63.1274 56.4971 62.0801 50.4312C61.5521 47.4069 60.1844 44.779 58.3061 42.8317C57.4319 42.9523 56.497 43.1505 55.5102 43.4348C52.2556 44.374 51.3987 51.0602 51.2516 56.6005Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.8393 62.2528C72.8653 62.2528 72.8999 62.2442 72.9259 62.2442C74.6917 62.2442 76.6652 62.7526 77.2365 63.933C79.8852 62.6923 85.7193 60.1763 88.7748 59.5301C88.065 56.859 87.2254 55.5235 86.0222 54.6963C87.3899 53.6451 87.4591 50.6898 86.1174 49.2853C84.7758 47.8808 82.9754 48.3289 82.6205 45.2787C82.3694 43.1505 81.651 41.6254 79.4178 40.445C77.9809 39.6781 76.8643 38.6872 75.765 37.7394C74.6484 37.3517 73.1596 37.2225 71.2293 37.7739C65.9492 39.2904 62.9803 42.9954 68.3469 47.002C66.6331 49.225 65.7761 54.9031 70.6927 60.5468C71.2466 61.1844 71.9737 61.7531 72.8393 62.2701V62.2528Z'
      fill='#626963'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3255 46.1403C67.6285 46.4247 67.9661 46.709 68.3469 46.9934C67.6371 47.9153 67.0832 49.4145 67.0053 51.267C67.9834 53.154 70.1127 54.2741 72.3286 53.895C75.0639 53.4211 76.8989 50.8362 76.4229 48.1135C75.9554 45.3907 73.35 43.5641 70.6148 44.038C69.2212 44.2792 68.0613 45.0633 67.3169 46.1403H67.3255Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M79.3312 47.4156C81.0451 47.3552 83.9794 53.7399 82.9927 54.1449C82.2223 54.4637 82.0925 53.8089 81.6077 53.9123C81.0884 54.0243 81.2269 54.8945 79.5823 54.8687C77.929 54.8428 77.9203 48.1135 79.3226 47.4156H79.3312Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M79.3312 47.4156C79.3745 47.4156 79.4178 47.4156 79.4611 47.4242C81.1144 48.8459 83.0533 53.3953 82.2223 53.7313C81.4519 54.0501 81.3221 53.3953 80.8374 53.4987C80.3353 53.6107 80.4478 54.4292 78.959 54.4551C77.9636 52.9817 78.1367 48.0101 79.3312 47.4156Z'
      fill='#181A26'
    />
    <path
      d='M72.0009 52.9916C69.4693 53.4262 67.063 51.7356 66.6264 49.2155C66.1898 46.6955 67.8882 44.3002 70.4198 43.8656C72.9515 43.431 75.3577 45.1216 75.7943 47.6416C76.2309 50.1617 74.5326 52.5569 72.0009 52.9916Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.4157 43.8656C67.8882 44.2964 66.183 46.6918 66.6244 49.2164C66.9274 50.9741 68.1998 52.3355 69.8011 52.8438C70.1214 52.8524 70.4503 52.8352 70.7792 52.7749C73.3067 52.3441 75.012 49.9487 74.5705 47.4242C74.2675 45.6664 72.9951 44.3051 71.3938 43.7967C71.0735 43.7795 70.7446 43.8053 70.4157 43.857V43.8656Z'
      fill='#181A26'
    />
    <path
      d='M83.9593 50.7364C83.6574 50.8872 82.4784 49.1568 81.3258 46.8713C80.1732 44.5858 79.4836 42.6109 79.7854 42.46C80.0872 42.3092 81.2663 44.0396 82.4189 46.3251C83.5714 48.6106 84.2611 50.5856 83.9593 50.7364Z'
      fill='#4D4D4D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M79.7813 42.4612C79.4784 42.6163 80.1708 44.5894 81.3221 46.8727C82.1271 48.4667 82.9494 49.7937 83.4687 50.3968C83.538 50.4399 83.5899 50.4485 83.6245 50.4313C83.9275 50.2848 83.235 48.303 82.0838 46.0197C81.2788 44.4257 80.4565 43.0988 79.9371 42.4956C79.8679 42.4526 79.816 42.4439 79.7727 42.4612H79.7813Z'
      fill='#181A26'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.7872 64.4414C59.1544 69.1717 61.3443 69.8007 64.3652 69.0511C68.3036 68.0689 71.1687 62.2442 72.9345 62.2442C74.7003 62.2442 76.6739 62.7526 77.2452 63.933C75.6438 49.8712 65.0837 63.2437 61.8983 67.1124C60.9202 68.2929 58.0464 66.3197 55.7959 64.4414H55.7872Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M78.734 54.705C78.8292 55.2822 78.0934 55.3426 77.9203 54.7566C77.0288 53.1368 77.6693 48.0187 78.959 47.519C77.7732 48.1566 77.7299 53.0765 78.734 54.7136V54.705Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.2253 39.2128C66.1483 40.8413 66.183 42.9523 68.9875 43.1677C71.7833 43.3831 81.5385 41.9183 79.3918 40.8499C74.8994 38.6183 76.9336 36.5418 70.2339 39.2128H70.2253Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.8393 62.2528H72.848C72.874 62.2528 72.8999 62.2528 72.9259 62.2528C74.6917 62.2528 76.6652 62.7612 77.2365 63.9416C76.3882 56.5144 73.0471 56.7384 69.6107 59.1768C69.9309 59.6335 70.2945 60.0815 70.684 60.5382C71.238 61.1758 71.9651 61.7445 72.8307 62.2615L72.8393 62.2528Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8501 29.8124C39.9729 31.0618 51.6757 42.0734 47.192 54.412C42.7082 66.7505 40.0855 68.8357 35.3594 68.491C30.6332 68.1464 26.1928 62.0116 24.1587 62.3562C22.1245 62.7009 19.9605 63.6831 19.5364 65.1479C16.2472 64.2432 9.03683 62.5027 5.3927 62.3562C5.66969 59.151 6.37081 57.4449 7.59995 56.2559C5.81684 55.3167 5.15034 51.9391 6.41409 50.0521C7.67785 48.1738 9.83317 48.3375 9.63408 44.7531C9.49559 42.263 10.0236 40.3674 12.352 38.558C17.3378 34.6893 17.7273 28.5544 28.8501 29.8038V29.8124Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.40136 62.3562C5.40136 62.3562 5.50523 63.2782 6.36216 63.4591C7.21909 63.6401 19.9086 67.0349 20.151 66.0871C20.3933 65.1393 21.7437 63.0024 24.2625 62.339C20.151 62.3476 5.40136 62.3476 5.40136 62.3476V62.3562Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.945 51.5945C47.7805 52.525 47.5382 53.4642 47.1919 54.412C44.9241 60.6588 43.1237 64.2863 41.2973 66.2853C36.2076 67.638 35.7056 62.9163 35.6017 60.4262C35.4459 56.7039 31.2219 55.7906 30.036 53.1799C28.8501 50.5691 30.564 50.3192 30.2784 48.372C30.0014 46.4161 28.8069 46.5712 29.0925 42.5904C29.3695 38.6097 33.5762 36.5245 40.4317 37.2914C44.3615 37.7308 46.6813 45.2442 47.945 51.5858V51.5945Z'
      fill='#292E29'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.945 51.5944C47.7805 52.525 47.5382 53.4642 47.1919 54.412C46.1359 57.3243 45.1838 59.6593 44.2749 61.5291C38.5274 59.8489 34.2687 53.8175 34.2687 46.6315C34.2687 43.0557 35.3247 39.7556 37.0992 37.1449C38.1292 37.1104 39.2372 37.1621 40.4317 37.2914C44.3615 37.7308 46.6813 45.2442 47.945 51.5858V51.5944Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.2625 62.3476C24.2279 62.3476 24.1933 62.3562 24.1586 62.3562C22.1245 62.7009 19.9605 63.6831 19.5364 65.1479C16.2472 64.2432 9.03683 62.5027 5.3927 62.3562C5.66969 59.151 6.37081 57.4449 7.59995 56.2559C5.81684 55.3167 5.15034 51.9391 6.41409 50.0521C7.67785 48.1738 9.83317 48.3375 9.63408 44.7531C9.49559 42.263 10.0236 40.3674 12.352 38.558C13.8495 37.3948 14.9315 36.0334 16.0135 34.7237C17.2166 34.0603 18.9045 33.6208 21.233 33.8707C27.6037 34.5686 31.7499 38.2392 26.3832 43.9087C28.7982 46.1317 30.9102 52.4819 26.3832 59.935C25.8725 60.7708 25.1454 61.5808 24.2625 62.339V62.3476Z'
      fill='#626963'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.396 42.7369C27.1103 43.1246 26.7727 43.5124 26.3919 43.9173C27.3873 44.8307 28.3308 46.4505 28.7809 48.5615C28.0278 50.9224 25.8119 52.637 23.1892 52.637C19.9519 52.637 17.3292 50.0263 17.3292 46.8038C17.3292 43.5813 19.9519 40.9706 23.1892 40.9706C24.8425 40.9706 26.3313 41.6512 27.396 42.7455V42.7369Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8408 46.5711C11.8586 46.8469 9.75526 54.7566 10.9757 55.0324C11.9279 55.2478 11.9452 54.4637 12.5165 54.4895C13.1397 54.5154 13.1484 55.5493 15.0354 55.1874C16.9223 54.8256 15.598 47.0967 13.8495 46.5711H13.8408Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8408 46.5711C13.7889 46.5798 13.7456 46.5884 13.6937 46.6056C12.075 48.5701 10.7593 54.1707 11.7807 54.4034C12.7329 54.6188 12.7502 53.8347 13.3215 53.8605C13.9274 53.8864 13.9534 54.86 15.6759 54.5929C16.5242 52.6973 15.3383 47.0192 13.8495 46.5711H13.8408Z'
      fill='#181A26'
    />
    <path
      d='M23.3796 51.5428C26.377 51.5428 28.8069 49.124 28.8069 46.1403C28.8069 43.1567 26.377 40.7379 23.3796 40.7379C20.3822 40.7379 17.9524 43.1567 17.9524 46.1403C17.9524 49.124 20.3822 51.5428 23.3796 51.5428Z'
      fill='#3E423F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.3796 40.7379C26.3746 40.7379 28.8069 43.1591 28.8069 46.1403C28.8069 48.2255 27.621 50.0349 25.8812 50.931C25.5176 51.0086 25.1368 51.0516 24.7473 51.0516C21.7523 51.0516 19.32 48.6305 19.32 45.6492C19.32 43.5641 20.5059 41.7546 22.2457 40.8585C22.6092 40.781 22.9901 40.7379 23.3796 40.7379Z'
      fill='#181A26'
    />
    <path
      d='M9.18723 51.306C9.56419 51.4198 10.5748 49.1982 11.4444 46.3439C12.314 43.4896 12.7134 41.0835 12.3365 40.9697C11.9595 40.8559 10.949 43.0775 10.0793 45.9318C9.20967 48.7861 8.81027 51.1922 9.18723 51.306Z'
      fill='#4D4D4D'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3347 40.9705C12.7156 41.0826 12.3087 43.4865 11.4432 46.3471C10.8372 48.3461 10.1534 50.0263 9.67736 50.819C9.60811 50.8793 9.54752 50.9051 9.49559 50.8879C9.11473 50.7759 9.52156 48.3633 10.3871 45.5113C10.9931 43.5124 11.6682 41.8322 12.1529 41.0395C12.2222 40.9792 12.2828 40.9533 12.3347 40.9705Z'
      fill='#181A26'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.3009 61.4946C41.3665 67.5949 38.9862 68.7581 35.368 68.491C30.6419 68.1464 26.2014 62.0116 24.1673 62.3562C22.1332 62.7009 19.9692 63.6831 19.5451 65.1479C18.5843 48.6735 33.3772 61.9513 37.8176 65.7769C39.1766 66.9487 42.085 64.1053 44.3009 61.5032V61.4946Z'
      fill='#363F38'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.9788 54.8342C15.9875 55.5149 16.8444 55.4373 16.9223 54.7308C17.6235 52.6973 15.8663 46.933 14.2909 46.6142C15.7884 47.114 16.8098 52.7577 15.9875 54.8342H15.9788Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.6785 35.3441C27.6903 36.4125 28.0625 38.8509 24.8944 39.6436C21.7177 40.4363 10.2227 40.6776 12.4732 39.0319C17.1993 35.5854 14.4468 33.595 22.6785 35.3441Z'
      fill='#848484'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.2625 62.3476H24.2539C24.2279 62.3476 24.1933 62.3562 24.1673 62.3562C22.1418 62.7009 19.9692 63.6831 19.5451 65.1479C19.0344 56.4454 22.9295 56.0491 27.3613 58.1687C27.0757 58.7546 26.7554 59.3491 26.3919 59.9523C25.8812 60.7881 25.1541 61.598 24.2625 62.3562V62.3476Z'
      fill='#485049'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.3834 40.2984C43.8162 40.781 44.5346 40.7982 44.9847 40.3329C45.4348 39.8676 45.4435 39.1008 45.0107 38.6183C44.9847 38.5838 34.9179 27.4343 32.5029 17.0086C31.6287 13.2346 31.5248 10.6239 31.8537 8.87477C32.0268 7.96144 32.3125 7.32383 32.6674 6.8844C32.9703 6.50528 33.3252 6.28988 33.6974 6.1951C34.0437 6.10032 34.4159 6.10893 34.7621 6.18648C35.3161 6.30711 35.7056 6.86717 35.8614 7.48754C35.922 7.73742 35.9393 7.98729 35.922 8.21131C35.9047 8.40087 35.8527 8.56458 35.7835 8.69383C35.6623 8.90062 35.3853 9.02125 34.9266 8.96955C34.3033 8.892 32.6241 9.15049 32.5462 9.81395C32.477 10.4774 34.0437 11.3046 34.6669 11.3821C36.1124 11.5631 37.0992 10.9685 37.6791 10.0207C37.9561 9.55546 38.1206 9.02125 38.1725 8.4698C38.2245 7.95282 38.1812 7.40138 38.0513 6.8844C37.7051 5.47133 36.7183 4.15303 35.2209 3.82561C34.563 3.67913 33.8619 3.67052 33.1694 3.86008C32.3731 4.07548 31.6114 4.53215 30.9708 5.31623C30.3822 6.04862 29.9148 7.05673 29.6551 8.40087C29.257 10.4774 29.3608 13.4414 30.3216 17.5945C32.8838 28.6578 43.3661 40.2726 43.4007 40.3071L43.3834 40.2984Z'
      fill='#B5781B'
    />
    <path
      d='M43.2016 40.2898C43.6777 40.781 44.4567 40.7982 44.9501 40.3243C45.4435 39.8504 45.4608 39.0749 44.9847 38.5838C44.9501 38.5494 33.9052 27.1844 31.2565 16.5691C30.2957 12.7263 30.1831 10.0638 30.5467 8.28026C30.7371 7.3497 31.0487 6.69486 31.4382 6.25543C31.7758 5.87631 32.1653 5.65228 32.5635 5.54889C32.953 5.45411 33.3512 5.46272 33.7321 5.54027C34.3466 5.6609 34.7708 6.23819 34.9352 6.86718C35.0045 7.11706 35.0218 7.37555 35.0045 7.60819C34.9872 7.79774 34.9352 7.97007 34.8487 8.09932C34.7102 8.30611 34.4072 8.43535 33.9052 8.37504C33.2214 8.29749 32.6068 8.78 32.5289 9.46069C32.451 10.1414 32.9357 10.7531 33.6195 10.8307C35.2122 11.0116 36.2942 10.4085 36.9174 9.44346C37.2204 8.96956 37.4021 8.42673 37.4627 7.85806C37.5147 7.33246 37.4714 6.7724 37.3329 6.23819C36.952 4.79927 35.8701 3.45513 34.2254 3.12771C33.507 2.98123 32.728 2.97261 31.9749 3.16217C31.1007 3.37758 30.261 3.84286 29.5686 4.65279C28.9194 5.39379 28.4087 6.41914 28.123 7.79774C27.6903 9.90874 27.7941 12.9331 28.8501 17.1637C31.6633 28.4338 43.167 40.264 43.2016 40.2985V40.2898Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.8045 40.2984C55.3718 40.781 54.6533 40.7982 54.2032 40.3329C53.7531 39.8676 53.7444 39.1008 54.1772 38.6183C54.2032 38.5838 64.27 27.4343 66.685 17.0086C67.5592 13.2346 67.6631 10.6239 67.3342 8.87477C67.1611 7.96144 66.8754 7.32383 66.5205 6.8844C66.2176 6.50528 65.8627 6.28988 65.4905 6.1951C65.1442 6.10032 64.772 6.10893 64.4258 6.18648C63.8718 6.30711 63.4823 6.86717 63.3265 7.48754C63.2659 7.73742 63.2486 7.98729 63.2659 8.21131C63.2832 8.40087 63.3352 8.56458 63.4044 8.69383C63.5256 8.90062 63.8026 9.02125 64.2613 8.96955C64.8846 8.892 66.5638 9.15049 66.6417 9.81395C66.711 10.4774 65.1442 11.3046 64.521 11.3821C63.0755 11.5631 62.0801 10.9685 61.5088 10.0207C61.2318 9.55546 61.0673 9.02125 61.0154 8.4698C60.9634 7.95282 61.0067 7.40138 61.1366 6.8844C61.4828 5.47133 62.4696 4.15303 63.967 3.82561C64.6249 3.67913 65.326 3.67052 66.0185 3.86008C66.8148 4.07548 67.5765 4.53215 68.2171 5.31623C68.8057 6.04862 69.2731 7.05673 69.5328 8.40087C69.9309 10.4774 69.8271 13.4414 68.8663 17.5945C66.3041 28.6578 55.8219 40.2726 55.7872 40.3071L55.8045 40.2984Z'
      fill='#B5781B'
    />
    <path
      d='M55.9863 40.2898C55.5103 40.781 54.7312 40.7982 54.2378 40.3243C53.7445 39.8504 53.7271 39.0749 54.2032 38.5838C54.2378 38.5494 65.2827 27.1844 67.9314 16.5691C68.8923 12.7263 69.0048 10.0638 68.6412 8.28026C68.4508 7.3497 68.1392 6.69486 67.7497 6.25543C67.4121 5.87631 67.0226 5.65228 66.6157 5.54889C66.2349 5.45411 65.8281 5.46272 65.4559 5.54027C64.8413 5.6609 64.4172 6.23819 64.2527 6.86718C64.1835 7.11706 64.1661 7.37555 64.1834 7.60819C64.2008 7.79774 64.2527 7.97007 64.3393 8.09932C64.4777 8.30611 64.7807 8.43535 65.2827 8.37504C65.9666 8.29749 66.5811 8.78 66.659 9.46069C66.7369 10.1414 66.2522 10.7531 65.5684 10.8307C63.9844 11.0116 62.8937 10.4085 62.2705 9.44346C61.9675 8.96956 61.7858 8.42673 61.7252 7.85806C61.6732 7.33246 61.7165 6.7724 61.855 6.23819C62.2359 4.79927 63.3179 3.45513 64.9625 3.12771C65.6809 2.98123 66.4599 2.97261 67.213 3.16217C68.0873 3.37758 68.9269 3.84286 69.6193 4.65279C70.2685 5.39379 70.7792 6.41914 71.0649 7.79774C71.4977 9.90874 71.3938 12.9331 70.3378 17.1637C67.5246 28.4338 56.021 40.264 55.9863 40.2985V40.2898Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.5072 45.4597C49.581 45.4597 48.6635 45.4597 47.7373 45.4597L45.0453 40.4708C41.5137 33.9224 39.8258 26.857 39.8258 18.6285V1.87837C40.6568 1.74913 42.5784 1.63711 43.9287 1.43032C45.6166 1.31831 47.5036 1.258 49.4944 1.258C51.4853 1.258 53.3723 1.31831 55.0601 1.43032V18.6285C55.0601 26.857 54.082 33.9224 52.0566 40.4708L50.5072 45.4597Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7373 45.4596H47.3218L43.6863 40.7034C38.9169 34.4652 36.6318 27.7273 36.6318 19.8864V3.05017C36.6318 2.60213 37.8349 2.18854 39.8258 1.87836V18.6285C39.8258 26.857 41.5137 33.9224 45.0453 40.4708L47.7373 45.4596Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.7725 3.05879V19.8951C62.7725 27.7359 60.4874 34.4739 55.718 40.7121L52.0825 45.4683H51.2602L53.9522 40.4794C57.4838 33.931 59.1717 26.8657 59.1717 18.6371V1.82666C61.4049 2.14546 62.7725 2.5849 62.7725 3.06741V3.05879Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.163 1.81804V18.6285C59.163 26.857 57.4751 33.9224 53.9435 40.4708L51.2516 45.4596H50.5071L52.0566 40.4708C54.0907 33.9224 55.0601 26.857 55.0601 18.6285V1.42169C56.6095 1.51647 58.0031 1.65433 59.163 1.81804Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 74.7896C58.358 75.0308 54.2032 75.1773 49.6848 75.1773C48.3345 75.1773 47.0188 75.1601 45.7464 75.1428V71.0587H61.6473V74.7896Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 70.4642C54.3504 70.4642 58.5744 70.5934 61.6473 70.8088V72.5063C58.5744 72.7217 54.3504 72.8509 49.6848 72.8509C48.3259 72.8509 47.0102 72.8423 45.7464 72.8164V70.49C47.0102 70.4728 48.3259 70.4556 49.6848 70.4556V70.4642Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 75.1342C36.9434 74.9619 30.3216 74.2381 30.3216 73.3593V71.0501H45.7464V75.1342Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.048 73.3593C69.048 73.9365 66.157 74.4535 61.6473 74.7809V71.0501H69.048V73.3593Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 72.825C36.9434 72.6527 30.3216 71.9289 30.3216 71.0501C30.3216 70.1798 36.9434 69.4474 45.7464 69.2751V72.8164V72.825Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 69.6284C66.157 69.9644 69.048 70.4728 69.048 71.0501C69.048 71.6274 66.157 72.1444 61.6473 72.4718V69.6284Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 72.2133C38.2331 72.0927 32.6501 71.6188 32.6501 71.0501V63.2179H45.7464V72.2133Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.7196 71.0501C66.7196 71.3861 64.7807 71.6877 61.6473 71.9031V63.2179H66.7196V71.0501Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 71.9031C58.5744 72.1185 54.3504 72.2478 49.6848 72.2478C48.3259 72.2478 47.0102 72.2391 45.7464 72.2133V63.2179H61.6473V71.9031Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 62.0202C51.0871 62.0202 66.7196 62.1581 66.7196 63.2179C66.7196 64.2777 51.0871 64.4155 49.6848 64.4155C47.0881 64.4155 32.6501 64.2346 32.6501 63.2179C32.6501 62.2011 47.0881 62.0202 49.6848 62.0202Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.667 49.6816H52.2556C54.0388 53.7141 54.662 55.0927 58.358 57.781C62.0541 60.4693 61.5174 62.7009 61.1366 63.0714C59.8815 63.5884 54.7832 63.8555 49.6848 63.8555C53.6146 63.8555 57.5444 63.597 58.5138 63.0714C58.7995 62.7009 59.2236 60.4779 56.3758 57.781C53.5281 55.0927 53.052 53.7141 51.6757 49.6816H51.667Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.2245 63.0714C37.8436 62.7009 37.3069 60.4779 41.003 57.781C44.6991 55.0927 45.3223 53.7141 47.1054 49.6816H47.694C46.3177 53.7141 45.8416 55.0927 42.9938 57.781C40.1461 60.4693 40.5615 62.7009 40.8558 63.0714C41.8253 63.5884 45.7551 63.8555 49.6848 63.8555C44.5865 63.8555 39.4882 63.597 38.2331 63.0714H38.2245Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.6976 49.6816H51.667C53.0433 53.7141 53.5194 55.0927 56.3672 57.781C59.215 60.4693 58.7995 62.7009 58.5052 63.0714C57.5357 63.5884 53.606 63.8555 49.6762 63.8555C51.6843 63.8555 53.7012 63.597 54.1946 63.0714C54.3417 62.7009 54.5581 60.4779 53.0953 57.781C51.6411 55.0927 51.3901 53.7141 50.6889 49.6816H50.6976Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.6635 49.6816C49.3473 49.6816 49.6762 49.6816 49.6762 49.6816C49.6762 49.6816 50.0051 49.6816 50.6889 49.6816C51.3901 53.7141 51.6411 55.0927 53.0953 57.781C54.5494 60.4693 54.3417 62.7009 54.1946 63.0714C53.7012 63.597 51.693 63.8555 49.6762 63.8468H49.581H49.4944H49.3992H49.3126C45.5041 63.8296 41.7907 63.5711 40.8558 63.0714C40.5702 62.7009 40.1461 60.4779 42.9938 57.781C45.8416 55.0927 46.3264 53.7141 47.694 49.6816H48.6635Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 47.9928H52.6625C53.1299 47.9928 53.5107 48.3719 53.5107 48.8372C53.5107 49.3025 53.1299 49.6816 52.6625 49.6816H46.6986C46.2311 49.6816 45.8503 49.3025 45.8503 48.8372C45.8503 48.3719 46.2311 47.9928 46.6986 47.9928Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 47.9928H52.6625C53.0174 47.9928 53.3203 48.2082 53.4501 48.5184C53.3203 48.8286 53.0174 49.044 52.6625 49.044H46.6986C46.3437 49.044 46.0407 48.82 45.9109 48.5184C46.0407 48.2082 46.3437 47.9928 46.6986 47.9928Z'
      fill='#CD9E34'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 46.304H54.3244C54.7918 46.304 55.1727 46.6832 55.1727 47.1484C55.1727 47.6137 54.7918 47.9928 54.3244 47.9928H45.0366C44.5692 47.9928 44.1884 47.6137 44.1884 47.1484C44.1884 46.6832 44.5692 46.304 45.0366 46.304Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 46.304H54.3244C54.636 46.304 54.913 46.4764 55.0601 46.7262C54.913 46.9761 54.636 47.1484 54.3244 47.1484H45.0366C44.725 47.1484 44.448 46.9761 44.3009 46.7262C44.448 46.4764 44.725 46.304 45.0366 46.304Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 44.6152H52.6625C53.1299 44.6152 53.5107 44.9944 53.5107 45.4596C53.5107 45.9249 53.1299 46.304 52.6625 46.304H46.6986C46.2311 46.304 45.8503 45.9249 45.8503 45.4596C45.8503 44.9944 46.2311 44.6152 46.6986 44.6152Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 44.6152H52.6625C53.0174 44.6152 53.3203 44.8306 53.4501 45.1408C53.3203 45.451 53.0174 45.6664 52.6625 45.6664H46.6986C46.3437 45.6664 46.0407 45.451 45.9109 45.1408C46.0407 44.8306 46.3437 44.6152 46.6986 44.6152Z'
      fill='#E6C03B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.07602C36.8395 3.35174 36.3721 3.34313 36.0951 3.04156C35.8181 2.7486 35.8268 2.27471 36.1297 1.99898C36.7443 1.42169 38.5188 0.870247 41.1588 0.508362C43.392 0.198175 46.3004 0 49.7022 0C53.0347 0 55.8046 0.189559 57.9599 0.49113C60.5739 0.853014 62.3311 1.37861 63.1707 1.91282C63.5169 2.13684 63.6208 2.59351 63.3958 2.93816C63.1707 3.28281 62.7119 3.38621 62.3657 3.16218C61.6906 2.73137 60.1412 2.28332 57.7608 1.9559C55.6834 1.67156 52.9914 1.482 49.7022 1.482C46.3437 1.482 43.5132 1.67156 41.3579 1.97313C39.0208 2.29194 37.5493 2.6969 37.1425 3.07602Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.07603C36.8395 3.35175 36.3721 3.34314 36.0951 3.04157C36.0345 2.97263 35.9912 2.9037 35.9566 2.82616C35.9999 2.73138 36.0518 2.64522 36.1297 2.57628C36.7443 1.99899 38.5188 1.44755 41.1588 1.08566C43.392 0.775476 46.3004 0.577301 49.7022 0.577301C53.0347 0.577301 55.8046 0.76686 57.9599 1.06843C60.5739 1.43032 62.3311 1.95591 63.1707 2.49012C63.3005 2.57628 63.3958 2.6883 63.4563 2.82616C63.439 2.86062 63.4217 2.9037 63.3958 2.93817C63.1707 3.28282 62.7119 3.38622 62.3657 3.16219C61.6906 2.73138 60.1411 2.28333 57.7608 1.95591C55.6834 1.67157 52.9914 1.48201 49.7022 1.48201C46.3437 1.48201 43.5132 1.67157 41.3579 1.97314C39.0208 2.29195 37.5493 2.69691 37.1425 3.07603Z'
      fill='#CD9E34'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.3834 40.2984C43.8162 40.781 44.5346 40.7982 44.9847 40.3329C45.4348 39.8676 45.4435 39.1008 45.0107 38.6183C44.9847 38.5838 34.9179 27.4343 32.5029 17.0086C31.6287 13.2346 31.5248 10.6239 31.8537 8.87477C32.0268 7.96144 32.3125 7.32383 32.6674 6.8844C32.9703 6.50528 33.3252 6.28988 33.6974 6.1951C34.0437 6.10032 34.4159 6.10893 34.7621 6.18648C35.3161 6.30711 35.7056 6.86717 35.8614 7.48754C35.922 7.73742 35.9393 7.98729 35.922 8.21131C35.9047 8.40087 35.8527 8.56458 35.7835 8.69383C35.6623 8.90062 35.3853 9.02125 34.9266 8.96955C34.3033 8.892 32.6241 9.15049 32.5462 9.81395C32.477 10.4774 34.0437 11.3046 34.6669 11.3821C36.1124 11.5631 37.0992 10.9685 37.6791 10.0207C37.9561 9.55546 38.1206 9.02125 38.1725 8.4698C38.2245 7.95282 38.1812 7.40138 38.0513 6.8844C37.7051 5.47133 36.7183 4.15303 35.2209 3.82561C34.563 3.67913 33.8619 3.67052 33.1694 3.86008C32.3731 4.07548 31.6114 4.53215 30.9708 5.31623C30.3822 6.04862 29.9148 7.05673 29.6551 8.40087C29.257 10.4774 29.3608 13.4414 30.3216 17.5945C32.8838 28.6578 43.3661 40.2726 43.4007 40.3071L43.3834 40.2984Z'
      fill='#B5781B'
    />
    <path
      d='M43.2016 40.2898C43.6777 40.781 44.4567 40.7982 44.9501 40.3243C45.4435 39.8504 45.4608 39.0749 44.9847 38.5838C44.9501 38.5494 33.9052 27.1844 31.2565 16.5691C30.2957 12.7263 30.1831 10.0638 30.5467 8.28026C30.7371 7.3497 31.0487 6.69486 31.4382 6.25543C31.7758 5.87631 32.1653 5.65228 32.5635 5.54889C32.953 5.45411 33.3512 5.46272 33.7321 5.54027C34.3466 5.6609 34.7708 6.23819 34.9352 6.86718C35.0045 7.11706 35.0218 7.37555 35.0045 7.60819C34.9872 7.79774 34.9352 7.97007 34.8487 8.09932C34.7102 8.30611 34.4072 8.43535 33.9052 8.37504C33.2214 8.29749 32.6068 8.78 32.5289 9.46069C32.451 10.1414 32.9357 10.7531 33.6195 10.8307C35.2122 11.0116 36.2942 10.4085 36.9174 9.44346C37.2204 8.96956 37.4021 8.42673 37.4627 7.85806C37.5147 7.33246 37.4714 6.7724 37.3329 6.23819C36.952 4.79927 35.8701 3.45513 34.2254 3.12771C33.507 2.98123 32.728 2.97261 31.9749 3.16217C31.1007 3.37758 30.261 3.84286 29.5686 4.65279C28.9194 5.39379 28.4087 6.41914 28.123 7.79774C27.6903 9.90874 27.7941 12.9331 28.8501 17.1637C31.6633 28.4338 43.167 40.264 43.2016 40.2985V40.2898Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.8045 40.2984C55.3718 40.781 54.6533 40.7982 54.2032 40.3329C53.7531 39.8676 53.7444 39.1008 54.1772 38.6183C54.2032 38.5838 64.27 27.4343 66.685 17.0086C67.5592 13.2346 67.6631 10.6239 67.3342 8.87477C67.1611 7.96144 66.8754 7.32383 66.5205 6.8844C66.2176 6.50528 65.8627 6.28988 65.4905 6.1951C65.1442 6.10032 64.772 6.10893 64.4258 6.18648C63.8718 6.30711 63.4823 6.86717 63.3265 7.48754C63.2659 7.73742 63.2486 7.98729 63.2659 8.21131C63.2832 8.40087 63.3352 8.56458 63.4044 8.69383C63.5256 8.90062 63.8026 9.02125 64.2613 8.96955C64.8846 8.892 66.5638 9.15049 66.6417 9.81395C66.711 10.4774 65.1442 11.3046 64.521 11.3821C63.0755 11.5631 62.0801 10.9685 61.5088 10.0207C61.2318 9.55546 61.0673 9.02125 61.0154 8.4698C60.9634 7.95282 61.0067 7.40138 61.1366 6.8844C61.4828 5.47133 62.4696 4.15303 63.967 3.82561C64.6249 3.67913 65.326 3.67052 66.0185 3.86008C66.8148 4.07548 67.5765 4.53215 68.2171 5.31623C68.8057 6.04862 69.2731 7.05673 69.5328 8.40087C69.9309 10.4774 69.8271 13.4414 68.8663 17.5945C66.3041 28.6578 55.8219 40.2726 55.7872 40.3071L55.8045 40.2984Z'
      fill='#B5781B'
    />
    <path
      d='M55.9863 40.2898C55.5103 40.781 54.7312 40.7982 54.2378 40.3243C53.7445 39.8504 53.7271 39.0749 54.2032 38.5838C54.2378 38.5494 65.2827 27.1844 67.9314 16.5691C68.8923 12.7263 69.0048 10.0638 68.6412 8.28026C68.4508 7.3497 68.1392 6.69486 67.7497 6.25543C67.4121 5.87631 67.0226 5.65228 66.6157 5.54889C66.2349 5.45411 65.8281 5.46272 65.4559 5.54027C64.8413 5.6609 64.4172 6.23819 64.2527 6.86718C64.1835 7.11706 64.1661 7.37555 64.1834 7.60819C64.2008 7.79774 64.2527 7.97007 64.3393 8.09932C64.4777 8.30611 64.7807 8.43535 65.2827 8.37504C65.9666 8.29749 66.5811 8.78 66.659 9.46069C66.7369 10.1414 66.2522 10.7531 65.5684 10.8307C63.9844 11.0116 62.8937 10.4085 62.2705 9.44346C61.9675 8.96956 61.7858 8.42673 61.7252 7.85806C61.6732 7.33246 61.7165 6.7724 61.855 6.23819C62.2359 4.79927 63.3179 3.45513 64.9625 3.12771C65.6809 2.98123 66.4599 2.97261 67.213 3.16217C68.0873 3.37758 68.9269 3.84286 69.6193 4.65279C70.2685 5.39379 70.7792 6.41914 71.0649 7.79774C71.4977 9.90874 71.3938 12.9331 70.3378 17.1637C67.5246 28.4338 56.021 40.264 55.9863 40.2985V40.2898Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.5072 45.4597C49.581 45.4597 48.6635 45.4597 47.7373 45.4597L45.0453 40.4708C41.5137 33.9224 39.8258 26.857 39.8258 18.6285V1.87837C40.6568 1.74913 42.5784 1.63711 43.9287 1.43032C45.6166 1.31831 47.5036 1.258 49.4944 1.258C51.4853 1.258 53.3723 1.31831 55.0601 1.43032V18.6285C55.0601 26.857 54.082 33.9224 52.0566 40.4708L50.5072 45.4597Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7373 45.4596H47.3218L43.6863 40.7034C38.9169 34.4652 36.6318 27.7273 36.6318 19.8864V3.05017C36.6318 2.60213 37.8349 2.18854 39.8258 1.87836V18.6285C39.8258 26.857 41.5137 33.9224 45.0453 40.4708L47.7373 45.4596Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.7725 3.05879V19.8951C62.7725 27.7359 60.4874 34.4739 55.718 40.7121L52.0825 45.4683H51.2602L53.9522 40.4794C57.4838 33.931 59.1717 26.8657 59.1717 18.6371V1.82666C61.4049 2.14546 62.7725 2.5849 62.7725 3.06741V3.05879Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.163 1.81804V18.6285C59.163 26.857 57.4751 33.9224 53.9435 40.4708L51.2516 45.4596H50.5071L52.0566 40.4708C54.0907 33.9224 55.0601 26.857 55.0601 18.6285V1.42169C56.6095 1.51647 58.0031 1.65433 59.163 1.81804Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 74.7896C58.358 75.0308 54.2032 75.1773 49.6848 75.1773C48.3345 75.1773 47.0188 75.1601 45.7464 75.1428V71.0587H61.6473V74.7896Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 70.4642C54.3504 70.4642 58.5744 70.5934 61.6473 70.8088V72.5063C58.5744 72.7217 54.3504 72.8509 49.6848 72.8509C48.3259 72.8509 47.0102 72.8423 45.7464 72.8164V70.49C47.0102 70.4728 48.3259 70.4556 49.6848 70.4556V70.4642Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 75.1342C36.9434 74.9619 30.3216 74.2381 30.3216 73.3593V71.0501H45.7464V75.1342Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.048 73.3593C69.048 73.9365 66.157 74.4535 61.6473 74.7809V71.0501H69.048V73.3593Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 72.825C36.9434 72.6527 30.3216 71.9289 30.3216 71.0501C30.3216 70.1798 36.9434 69.4474 45.7464 69.2751V72.8164V72.825Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 69.6284C66.157 69.9644 69.048 70.4728 69.048 71.0501C69.048 71.6274 66.157 72.1444 61.6473 72.4718V69.6284Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 72.2133C38.2331 72.0927 32.6501 71.6188 32.6501 71.0501V63.2179H45.7464V72.2133Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.7196 71.0501C66.7196 71.3861 64.7807 71.6877 61.6473 71.9031V63.2179H66.7196V71.0501Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 71.9031C58.5744 72.1185 54.3504 72.2478 49.6848 72.2478C48.3259 72.2478 47.0102 72.2391 45.7464 72.2133V63.2179H61.6473V71.9031Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 62.0202C51.0871 62.0202 66.7196 62.1581 66.7196 63.2179C66.7196 64.2777 51.0871 64.4155 49.6848 64.4155C47.0881 64.4155 32.6501 64.2346 32.6501 63.2179C32.6501 62.2011 47.0881 62.0202 49.6848 62.0202Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.667 49.6816H52.2556C54.0388 53.7141 54.662 55.0927 58.358 57.781C62.0541 60.4693 61.5174 62.7009 61.1366 63.0714C59.8815 63.5884 54.7832 63.8555 49.6848 63.8555C53.6146 63.8555 57.5444 63.597 58.5138 63.0714C58.7995 62.7009 59.2236 60.4779 56.3758 57.781C53.5281 55.0927 53.052 53.7141 51.6757 49.6816H51.667Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.2245 63.0714C37.8436 62.7009 37.3069 60.4779 41.003 57.781C44.6991 55.0927 45.3223 53.7141 47.1054 49.6816H47.694C46.3177 53.7141 45.8416 55.0927 42.9938 57.781C40.1461 60.4693 40.5615 62.7009 40.8558 63.0714C41.8253 63.5884 45.7551 63.8555 49.6848 63.8555C44.5865 63.8555 39.4882 63.597 38.2331 63.0714H38.2245Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.6976 49.6816H51.667C53.0433 53.7141 53.5194 55.0927 56.3672 57.781C59.215 60.4693 58.7995 62.7009 58.5052 63.0714C57.5357 63.5884 53.606 63.8555 49.6762 63.8555C51.6843 63.8555 53.7012 63.597 54.1946 63.0714C54.3417 62.7009 54.5581 60.4779 53.0953 57.781C51.6411 55.0927 51.3901 53.7141 50.6889 49.6816H50.6976Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.6635 49.6816C49.3473 49.6816 49.6762 49.6816 49.6762 49.6816C49.6762 49.6816 50.0051 49.6816 50.6889 49.6816C51.3901 53.7141 51.6411 55.0927 53.0953 57.781C54.5494 60.4693 54.3417 62.7009 54.1946 63.0714C53.7012 63.597 51.693 63.8555 49.6762 63.8468H49.581H49.4944H49.3992H49.3126C45.5041 63.8296 41.7907 63.5711 40.8558 63.0714C40.5702 62.7009 40.1461 60.4779 42.9938 57.781C45.8416 55.0927 46.3264 53.7141 47.694 49.6816H48.6635Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 47.9928H52.6625C53.1299 47.9928 53.5107 48.3719 53.5107 48.8372C53.5107 49.3025 53.1299 49.6816 52.6625 49.6816H46.6986C46.2311 49.6816 45.8503 49.3025 45.8503 48.8372C45.8503 48.3719 46.2311 47.9928 46.6986 47.9928Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 47.9928H52.6625C53.0174 47.9928 53.3203 48.2082 53.4501 48.5184C53.3203 48.8286 53.0174 49.044 52.6625 49.044H46.6986C46.3437 49.044 46.0407 48.82 45.9109 48.5184C46.0407 48.2082 46.3437 47.9928 46.6986 47.9928Z'
      fill='#CD9E34'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 46.304H54.3244C54.7918 46.304 55.1727 46.6832 55.1727 47.1484C55.1727 47.6137 54.7918 47.9928 54.3244 47.9928H45.0366C44.5692 47.9928 44.1884 47.6137 44.1884 47.1484C44.1884 46.6832 44.5692 46.304 45.0366 46.304Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 46.304H54.3244C54.636 46.304 54.913 46.4764 55.0601 46.7262C54.913 46.9761 54.636 47.1484 54.3244 47.1484H45.0366C44.725 47.1484 44.448 46.9761 44.3009 46.7262C44.448 46.4764 44.725 46.304 45.0366 46.304Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 44.6152H52.6625C53.1299 44.6152 53.5107 44.9944 53.5107 45.4596C53.5107 45.9249 53.1299 46.304 52.6625 46.304H46.6986C46.2311 46.304 45.8503 45.9249 45.8503 45.4596C45.8503 44.9944 46.2311 44.6152 46.6986 44.6152Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 44.6152H52.6625C53.0174 44.6152 53.3203 44.8306 53.4501 45.1408C53.3203 45.451 53.0174 45.6664 52.6625 45.6664H46.6986C46.3437 45.6664 46.0407 45.451 45.9109 45.1408C46.0407 44.8306 46.3437 44.6152 46.6986 44.6152Z'
      fill='#E6C03B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.07602C36.8395 3.35174 36.3721 3.34313 36.0951 3.04156C35.8181 2.7486 35.8268 2.27471 36.1297 1.99898C36.7443 1.42169 38.5188 0.870247 41.1588 0.508362C43.392 0.198175 46.3004 0 49.7022 0C53.0347 0 55.8046 0.189559 57.9599 0.49113C60.5739 0.853014 62.3311 1.37861 63.1707 1.91282C63.5169 2.13684 63.6208 2.59351 63.3958 2.93816C63.1707 3.28281 62.7119 3.38621 62.3657 3.16218C61.6906 2.73137 60.1412 2.28332 57.7608 1.9559C55.6834 1.67156 52.9914 1.482 49.7022 1.482C46.3437 1.482 43.5132 1.67156 41.3579 1.97313C39.0208 2.29194 37.5493 2.6969 37.1425 3.07602Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.07603C36.8395 3.35175 36.3721 3.34314 36.0951 3.04157C36.0345 2.97263 35.9912 2.9037 35.9566 2.82616C35.9999 2.73138 36.0518 2.64522 36.1297 2.57628C36.7443 1.99899 38.5188 1.44755 41.1588 1.08566C43.392 0.775476 46.3004 0.577301 49.7022 0.577301C53.0347 0.577301 55.8046 0.76686 57.9599 1.06843C60.5739 1.43032 62.3311 1.95591 63.1707 2.49012C63.3005 2.57628 63.3958 2.6883 63.4563 2.82616C63.439 2.86062 63.4217 2.9037 63.3958 2.93817C63.1707 3.28282 62.7119 3.38622 62.3657 3.16219C61.6906 2.73138 60.1411 2.28333 57.7608 1.95591C55.6834 1.67157 52.9914 1.48201 49.7022 1.48201C46.3437 1.48201 43.5132 1.67157 41.3579 1.97314C39.0208 2.29195 37.5493 2.69691 37.1425 3.07603Z'
      fill='#CD9E34'
    />
  </svg>
);

export const CupIconActiveState = () => (
  <svg
    width='100'
    height='91'
    viewBox='0 0 100 91'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M50 91C77.6142 91 100 82.4934 100 72C100 61.5066 77.6142 53 50 53C22.3858 53 0 61.5066 0 72C0 82.4934 22.3858 91 50 91Z'
      fill='url(#paint0_radial_70_6360)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.3755 43.2567C73.0297 50.2101 64.2873 56.1725 56.9298 51.5973C49.581 47.022 48.5855 45.0489 49.4684 42.0676C50.3513 39.0864 54.9303 37.0874 54.9822 35.7433C55.0342 34.3905 54.7052 32.8654 53.8223 32.4002C54.8611 30.4184 56.9904 26.0413 57.579 23.7321C59.6131 24.3525 60.6172 25.0418 61.2145 25.9896C62.0714 24.9729 64.3479 25.016 65.3866 26.0844C66.4253 27.1528 66.0185 28.5142 68.3642 28.8847C70.0002 29.1432 71.1428 29.7463 71.991 31.4868C73.8001 35.2177 77.7126 36.312 75.3668 43.2653L75.3755 43.2567Z'
      fill='#6C632F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M57.5877 23.7322C57.5877 23.7322 56.9818 23.6719 56.7394 24.1975C56.497 24.7145 52.5499 32.383 53.1299 32.6673C53.7098 32.9517 54.9043 34.1149 54.9822 35.8209C55.5449 33.1843 57.5877 23.7322 57.5877 23.7322Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.6523 52.4676C58.0724 52.235 57.5011 51.9507 56.9385 51.5974C53.2164 49.2796 51.1217 47.6253 50.083 46.1777C49.9099 42.7312 53.0347 43.0672 54.6533 43.343C57.0856 43.7565 58.2542 41.1803 60.1065 40.7839C61.9589 40.3876 61.881 41.5163 63.1794 41.6111C64.4777 41.6972 64.547 40.9218 67.0832 41.6456C69.6193 42.3779 70.3811 45.3506 68.9355 49.6415C68.1046 52.0971 62.9283 52.5452 58.6523 52.4762V52.4676Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M58.6523 52.4676C58.0724 52.235 57.5011 51.9507 56.9385 51.5974C55.2073 50.5203 53.8224 49.5812 52.7404 48.7454C54.6274 45.2902 59.1111 43.4033 63.7593 44.3942C66.0704 44.8939 68.0526 46.0226 69.4982 47.5219C69.377 48.1853 69.1952 48.8919 68.9442 49.6329C68.1132 52.0885 62.937 52.5366 58.661 52.4676H58.6523Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9822 35.8123C54.9822 35.7865 54.9909 35.7692 54.9909 35.7434C55.0428 34.3906 54.7139 32.8655 53.831 32.4002C54.8697 30.4185 56.9991 26.0414 57.5877 23.7322C59.6218 24.3526 60.6259 25.0419 61.2231 25.9897C62.0801 24.973 64.3566 25.0161 65.3953 26.0845C66.434 27.1529 66.0271 28.5143 68.3729 28.8848C70.0089 29.1433 71.1514 29.7464 71.9997 31.4869C72.545 32.607 73.2721 33.4859 73.9732 34.3648C74.2329 35.2264 74.2849 36.3724 73.8001 37.8199C72.4671 41.8006 69.5155 43.9547 66.5984 39.7327C64.8326 40.9735 60.4354 41.4474 56.2373 37.5097C55.7699 37.0703 55.3458 36.493 54.9822 35.8123Z'
      fill='#90894E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.2303 40.534C67.0226 40.2928 66.8148 40.0257 66.6071 39.7241C65.88 40.2325 64.7028 40.6202 63.2746 40.6116C61.855 39.8017 61.05 38.1473 61.4136 36.4671C61.8637 34.3906 63.9151 33.0723 66.0012 33.5204C68.0872 33.9684 69.4116 36.0105 68.9615 38.087C68.7364 39.1468 68.0872 39.9998 67.239 40.534H67.2303Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.6244 31.3232C66.7196 30.0135 61.8983 27.5751 61.5521 28.3161C61.2837 28.8934 61.7858 29.014 61.6905 29.3759C61.5867 29.7723 60.9202 29.6344 60.8855 30.8924C60.8596 32.1504 66.0445 32.3744 66.6244 31.3232Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.6244 31.3232C66.6244 31.2888 66.6244 31.2543 66.6244 31.2198C65.5857 29.9101 62.1407 28.2903 61.8464 28.9107C61.578 29.4879 62.0801 29.6086 61.9849 29.9705C61.881 30.3582 61.2578 30.2376 61.1885 31.3749C62.2965 32.1848 66.131 32.2107 66.6244 31.3146V31.3232Z'
      fill='#221501'
    />
    <path
      d='M62.0931 36.7453C61.6781 38.6646 62.9047 40.5554 64.8328 40.9685C66.7609 41.3815 68.6604 40.1605 69.0754 38.2412C69.4904 36.3219 68.2637 34.4312 66.3356 34.0181C64.4075 33.605 62.5081 34.826 62.0931 36.7453Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.074 38.2421C68.6585 40.1635 66.7629 41.3784 64.8326 40.9649C63.4823 40.6805 62.4782 39.6638 62.1407 38.4317C62.1407 38.1904 62.1666 37.9405 62.2186 37.6907C62.634 35.7692 64.5297 34.5543 66.4599 34.9593C67.8103 35.2436 68.8143 36.2603 69.1519 37.5011C69.1519 37.7424 69.126 37.9922 69.074 38.2421Z'
      fill='url(#paint1_radial_70_6360)'
    />
    <path
      d='M64.2091 27.6857C64.0834 27.9114 65.3795 28.8664 67.1042 29.8188C68.8289 30.7711 70.329 31.3602 70.4547 31.1345C70.5805 30.9088 69.2843 29.9538 67.5597 29.0015C65.835 28.0491 64.3349 27.4601 64.2091 27.6857Z'
      fill='#90894E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.459 31.1337C70.3291 31.3577 68.8316 30.7718 67.1091 29.8154C65.906 29.1519 64.9105 28.4798 64.4604 28.0663C64.4345 28.0146 64.4258 27.9715 64.4345 27.9456C64.5643 27.7216 66.0618 28.3075 67.7843 29.2639C68.9874 29.9274 69.9829 30.5994 70.433 31.013C70.459 31.0647 70.4676 31.1078 70.4503 31.1337H70.459Z'
      fill='url(#paint2_linear_70_6360)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M52.7577 48.7626C49.2261 46.0399 48.8019 44.3511 49.4771 42.0677C50.36 39.0865 54.939 37.0875 54.9909 35.7434C55.0428 34.3906 54.7139 32.8655 53.831 32.4002C64.6162 34.0632 53.9782 41.7059 50.8967 44.015C49.9532 44.7216 51.3901 46.979 52.7577 48.7626Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M60.9894 31.5472C60.548 31.4524 60.4787 32.0125 60.9288 32.1676C62.1493 32.9 66.1137 32.5726 66.5378 31.6075C66.0098 32.495 62.2186 32.3744 60.9894 31.5558V31.5472Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.6576 38.5437C71.2726 41.6111 69.6453 41.5077 69.5674 39.3622C69.4895 37.2168 70.9264 29.824 71.6794 31.4955C73.2548 35.0024 74.9167 33.5117 72.6489 38.5437H72.6576Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M54.9822 35.8123V35.8037C54.9822 35.7864 54.9909 35.7692 54.9909 35.7434C55.0428 34.3906 54.7139 32.8655 53.831 32.4002C59.5266 33.2791 59.2496 35.8295 57.2587 38.3713C56.9212 38.1129 56.5836 37.8199 56.246 37.5097C55.7786 37.0703 55.3544 36.493 54.9909 35.8123H54.9822Z'
      fill='#6C632F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.842 17.0977C20.376 20.6131 16.0135 30.2289 21.8302 36.6309C27.647 43.0328 29.7677 43.6531 32.5635 42.2659C35.3594 40.8787 36.5279 36.0449 37.8523 35.752C39.1766 35.459 40.7433 35.5193 41.3665 36.3034C43.141 34.9334 47.0967 32.0814 49.278 31.0992C48.3086 29.2122 47.4516 28.3506 46.4129 27.9284C47.2612 26.9117 46.8197 24.6886 45.582 23.8529C44.3442 23.0171 43.0718 23.6547 42.2927 21.4231C41.7561 19.8721 40.9597 18.8468 39.0814 18.3212C35.0737 17.2011 33.3079 13.565 26.842 17.0804V17.0977Z'
      fill='#6C632F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.278 31.0992C49.278 31.0992 49.4425 31.6851 48.9751 32.0125C48.499 32.3399 41.6176 37.5528 41.228 37.0358C40.8472 36.5188 39.4882 35.5538 37.783 35.7778C40.2932 34.7611 49.278 31.0992 49.278 31.0992Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.6703 35.0972C20.9993 35.6228 21.3888 36.1311 21.8389 36.6309C24.7905 39.8792 26.79 41.6369 28.4087 42.4038C31.8451 41.9644 30.9708 38.9659 30.4082 37.4236C29.5686 35.1144 31.9143 33.5118 31.9836 31.6248C32.0528 29.7378 30.9449 30.0135 30.6332 28.7556C30.313 27.4976 31.0747 27.2908 29.9062 24.9385C28.7376 22.5863 25.6561 22.3536 21.6744 24.525C19.3893 25.7657 19.8567 30.9183 20.679 35.0972H20.6703Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.6703 35.0972C20.9993 35.6227 21.3888 36.1311 21.8389 36.6309C23.2152 38.1387 24.375 39.3278 25.3964 40.2411C28.4779 37.7854 29.5599 33.0551 27.7595 28.678C26.8593 26.4981 25.3964 24.7576 23.6653 23.603C23.0334 23.8356 22.3669 24.1458 21.6744 24.5163C19.3893 25.7571 19.8567 30.9096 20.679 35.0885L20.6703 35.0972Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.783 35.7778C37.809 35.7692 37.8263 35.7606 37.8523 35.7606C39.1766 35.4676 40.7433 35.528 41.3665 36.312C43.141 34.9421 47.0967 32.0901 49.278 31.1078C48.3086 29.2208 47.4516 28.3592 46.4129 27.937C47.2612 26.9203 46.8197 24.6973 45.582 23.8615C44.3442 23.0257 43.0717 23.6633 42.2927 21.4317C41.7561 19.8807 40.9597 18.8554 39.0814 18.3298C37.8782 17.9938 36.8741 17.4337 35.8874 16.8995C34.9872 16.7961 33.8532 16.9426 32.4943 17.675C28.7895 19.6826 27.1796 22.9482 31.871 25.0678C30.9535 27.0237 31.2651 31.4094 35.8874 34.83C36.4067 35.2178 37.0473 35.528 37.783 35.7692V35.7778Z'
      fill='#90894E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.9708 24.6025C31.2392 24.7662 31.5421 24.9213 31.8797 25.0678C31.4988 25.8777 31.3344 27.0926 31.5854 28.4884C32.6328 29.7378 34.4159 30.2375 36.0086 29.5827C37.9821 28.7814 38.9256 26.5325 38.1206 24.5766C37.3156 22.6121 35.0564 21.6729 33.0915 22.4742C32.0874 22.8792 31.3517 23.6633 30.9708 24.5939V24.6025Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.1807 23.5771C41.4618 23.2497 44.7164 27.5492 44.0499 28.0145C43.5219 28.3764 43.3141 27.9111 42.9765 28.0662C42.6043 28.2386 42.8554 28.8675 41.6176 29.1174C40.3798 29.3673 39.2458 24.3267 40.1807 23.5685V23.5771Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.1807 23.5771C40.2153 23.5685 40.2413 23.5685 40.2759 23.5599C41.7561 24.3526 43.9633 27.4372 43.4007 27.8336C42.8727 28.1955 42.6649 27.7302 42.3273 27.8853C41.9638 28.049 42.1975 28.6435 41.0809 28.9106C40.0855 27.9715 39.3843 24.2147 40.1893 23.5685L40.1807 23.5771Z'
      fill='#221501'
    />
    <path
      d='M35.617 28.9714C33.7917 29.715 31.7064 28.8449 30.9594 27.0279C30.2124 25.2109 31.0866 23.1352 32.9119 22.3916C34.7372 21.648 36.8225 22.5181 37.5695 24.3351C38.3165 26.1521 37.4424 28.2278 35.617 28.9714Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.9097 22.3881C31.0834 23.1291 30.2091 25.2056 30.9535 27.0237C31.4729 28.2989 32.6501 29.1002 33.9398 29.2208C34.1822 29.1777 34.4245 29.1088 34.6582 29.014C36.4846 28.273 37.3589 26.1965 36.6145 24.3785C36.0951 23.1119 34.9179 22.3019 33.6282 22.1813C33.3858 22.2244 33.1435 22.2933 32.9097 22.3881Z'
      fill='url(#paint3_radial_70_6360)'
    />
    <path
      d='M44.2053 25.3074C44.0042 25.4703 42.8322 24.3678 41.5876 22.8448C40.343 21.3218 39.4971 19.9552 39.6982 19.7923C39.8993 19.6295 41.0713 20.732 42.3159 22.255C43.5606 23.7779 44.4065 25.1446 44.2053 25.3074Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M39.696 19.7946C39.4969 19.9583 40.3365 21.3283 41.5829 22.8447C42.4485 23.9132 43.2881 24.7662 43.7815 25.1367C43.8421 25.1539 43.8767 25.1539 43.9114 25.1367C44.1105 24.973 43.2622 23.603 42.0244 22.0865C41.1588 21.0181 40.3192 20.1651 39.8258 19.7946C39.7652 19.7773 39.7219 19.7773 39.696 19.7946Z'
      fill='#221501'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.3705 40.2325C28.6857 43.2223 30.4255 43.3343 32.5635 42.2745C35.3594 40.8873 36.5279 36.0536 37.8523 35.7606C39.1766 35.4677 40.7433 35.528 41.3665 36.312C37.8263 26.0328 32.1394 37.8027 30.3909 41.232C29.8542 42.2832 27.37 41.275 25.3705 40.2411V40.2325Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.9511 29.1433C41.1155 29.5569 40.5789 29.7292 40.3538 29.3156C39.419 28.2472 39.0468 24.3095 39.9297 23.715C39.142 24.3871 39.9297 28.0835 40.9597 29.1433H40.9511Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.9922 18.933C29.205 20.8285 29.5859 22.4053 31.7239 22.1038C33.8532 21.8022 40.9164 19.0967 39.1333 18.6486C35.394 17.7181 36.5712 15.8225 31.9922 18.933Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.783 35.7778H37.7917C37.809 35.7692 37.8263 35.7692 37.8436 35.7606C39.1679 35.4676 40.7347 35.528 41.3579 36.312C39.4882 30.8838 37.0213 31.6075 34.8487 34.0029C35.1689 34.2872 35.5065 34.5715 35.8787 34.8473C36.3981 35.235 37.0386 35.5452 37.7743 35.7864L37.783 35.7778Z'
      fill='#6C632F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M64.2008 36.269C54.9822 38.9314 46.7159 49.9431 52.3076 59.714C57.8993 69.4849 60.4181 70.8721 64.3652 69.8898C68.3036 68.9076 71.1687 63.0829 72.9345 63.0829C74.7003 63.0829 76.6739 63.5913 77.2452 64.7717C79.8939 63.531 85.7279 61.015 88.7835 60.3688C88.0737 57.6977 87.2341 56.3622 86.0309 55.535C87.3985 54.4839 87.4678 51.5285 86.1261 50.124C84.7844 48.7195 82.984 49.1676 82.6291 46.1174C82.3781 43.9892 81.6597 42.4641 79.4265 41.2837C74.6484 38.7419 73.4279 33.6152 64.2094 36.2776L64.2008 36.269Z'
      fill='#6C632F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M88.7748 60.3602C88.7748 60.3602 88.8181 61.1529 88.1256 61.4372C87.4331 61.7216 77.2019 66.4261 76.8557 65.6592C76.5181 64.8924 75.0552 63.2811 72.8307 63.0916C76.3103 62.5057 88.7748 60.3688 88.7748 60.3688V60.3602Z'
      fill='#221501'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2516 57.4393C51.5199 58.1975 51.8661 58.9643 52.2989 59.714C55.1294 64.6683 57.1808 67.46 59.0159 68.8903C63.5169 69.2953 63.2486 65.237 62.9716 63.1174C62.5561 59.9552 65.9925 58.568 66.6157 56.1899C67.239 53.8118 65.7502 53.8549 65.7069 52.1661C65.6549 50.4773 66.685 50.4256 65.8627 47.1083C65.0404 43.7824 61.1885 42.6364 55.5016 44.2822C52.247 45.2213 51.3901 51.9076 51.2429 57.4479L51.2516 57.4393Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.2516 57.4392C51.5199 58.1975 51.8661 58.9643 52.2989 59.7139C53.6146 62.0145 54.7658 63.8584 55.8046 65.3059C60.4181 63.0485 63.1274 57.3358 62.0801 51.27C61.5521 48.2456 60.1844 45.6177 58.3061 43.6704C57.4319 43.791 56.497 43.9892 55.5102 44.2735C52.2556 45.2127 51.3987 51.899 51.2516 57.4392Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.8393 63.0916C72.8653 63.0916 72.8999 63.0829 72.9259 63.0829C74.6917 63.0829 76.6652 63.5913 77.2365 64.7717C79.8852 63.531 85.7193 61.015 88.7748 60.3688C88.065 57.6977 87.2254 56.3622 86.0222 55.5351C87.3899 54.4839 87.4591 51.5285 86.1174 50.124C84.7758 48.7196 82.9754 49.1676 82.6205 46.1174C82.3694 43.9892 81.651 42.4641 79.4178 41.2837C77.9809 40.5168 76.8643 39.526 75.765 38.5782C74.6484 38.1904 73.1596 38.0612 71.2293 38.6126C65.9492 40.1291 62.9803 43.8341 68.3469 47.8407C66.6331 50.0637 65.7761 55.7418 70.6927 61.3855C71.2466 62.0231 71.9737 62.5918 72.8393 63.1088V63.0916Z'
      fill='#90894E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M67.3255 46.9791C67.6285 47.2634 67.9661 47.5477 68.3469 47.8321C67.6371 48.754 67.0832 50.2533 67.0053 52.1058C67.9834 53.9927 70.1127 55.1128 72.3286 54.7337C75.0639 54.2598 76.8989 51.6749 76.4229 48.9522C75.9554 46.2294 73.35 44.4028 70.6148 44.8767C69.2212 45.1179 68.0613 45.902 67.3169 46.9791H67.3255Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M79.3312 48.2543C81.0451 48.194 83.9794 54.5786 82.9927 54.9836C82.2223 55.3024 82.0925 54.6476 81.6077 54.751C81.0884 54.863 81.2269 55.7332 79.5823 55.7074C77.929 55.6815 77.9203 48.9522 79.3226 48.2543H79.3312Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M79.3312 48.2543C79.3745 48.2543 79.4178 48.2543 79.4611 48.2629C81.1144 49.6846 83.0533 54.234 82.2223 54.57C81.4519 54.8888 81.3221 54.234 80.8374 54.3374C80.3353 54.4494 80.4478 55.2679 78.959 55.2938C77.9636 53.8204 78.1367 48.8488 79.3312 48.2543Z'
      fill='#221501'
    />
    <path
      d='M72.0009 53.8303C69.4693 54.2649 67.063 52.5743 66.6264 50.0542C66.1898 47.5342 67.8882 45.1389 70.4198 44.7043C72.9515 44.2697 75.3577 45.9603 75.7943 48.4803C76.2309 51.0004 74.5326 53.3957 72.0009 53.8303Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.4157 44.7043C67.8882 45.1352 66.183 47.5305 66.6244 50.0551C66.9274 51.8128 68.1998 53.1742 69.8011 53.6825C70.1214 53.6912 70.4503 53.6739 70.7792 53.6136C73.3067 53.1828 75.012 50.7875 74.5705 48.2629C74.2675 46.5052 72.9951 45.1438 71.3938 44.6354C71.0735 44.6182 70.7446 44.644 70.4157 44.6957V44.7043Z'
      fill='url(#paint4_radial_70_6360)'
    />
    <path
      d='M83.9593 51.5751C83.6574 51.7259 82.4784 49.9955 81.3258 47.71C80.1732 45.4246 79.4836 43.4496 79.7854 43.2987C80.0872 43.1479 81.2663 44.8784 82.4189 47.1638C83.5714 49.4493 84.2611 51.4243 83.9593 51.5751Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M79.7813 43.2999C79.4784 43.455 80.1708 45.4281 81.3221 47.7114C82.1271 49.3055 82.9494 50.6324 83.4687 51.2355C83.538 51.2786 83.5899 51.2872 83.6245 51.27C83.9275 51.1235 83.235 49.1417 82.0838 46.8584C81.2788 45.2644 80.4565 43.9375 79.9371 43.3344C79.8679 43.2913 79.816 43.2827 79.7727 43.2999H79.7813Z'
      fill='url(#paint5_linear_70_6360)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.7872 65.2801C59.1544 70.0105 61.3443 70.6395 64.3652 69.8898C68.3036 68.9076 71.1687 63.0829 72.9345 63.0829C74.7003 63.0829 76.6739 63.5913 77.2452 64.7717C75.6438 50.7099 65.0837 64.0824 61.8983 67.9512C60.9202 69.1316 58.0464 67.1585 55.7959 65.2801H55.7872Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M78.734 55.5437C78.8292 56.121 78.0934 56.1813 77.9203 55.5954C77.0288 53.9755 77.6693 48.8574 78.959 48.3577C77.7732 48.9953 77.7299 53.9152 78.734 55.5523V55.5437Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M70.2253 40.0515C66.1483 41.68 66.183 43.791 68.9875 44.0064C71.7833 44.2218 81.5385 42.7571 79.3918 41.6886C74.8994 39.457 76.9336 37.3805 70.2339 40.0515H70.2253Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M72.8393 63.0916H72.848C72.874 63.0916 72.8999 63.0916 72.9259 63.0916C74.6917 63.0916 76.6652 63.5999 77.2365 64.7804C76.3882 57.3531 73.0471 57.5771 69.6107 60.0155C69.9309 60.4722 70.2945 60.9202 70.684 61.3769C71.238 62.0145 71.9651 62.5832 72.8307 63.1002L72.8393 63.0916Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.8501 30.6511C39.9729 31.9005 51.6757 42.9122 47.192 55.2507C42.7082 67.5893 40.0855 69.6744 35.3594 69.3298C30.6332 68.9851 26.1928 62.8503 24.1587 63.1949C22.1245 63.5396 19.9605 64.5219 19.5364 65.9866C16.2472 65.0819 9.03683 63.3414 5.3927 63.1949C5.66969 59.9897 6.37081 58.2836 7.59995 57.0946C5.81684 56.1554 5.15034 52.7778 6.41409 50.8909C7.67785 49.0125 9.83317 49.1762 9.63408 45.5918C9.49559 43.1017 10.0236 41.2061 12.352 39.3967C17.3378 35.528 17.7273 29.3932 28.8501 30.6425V30.6511Z'
      fill='#6C632F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.40136 63.1949C5.40136 63.1949 5.50523 64.1169 6.36216 64.2978C7.21909 64.4788 19.9086 67.8736 20.151 66.9258C20.3933 65.978 21.7437 63.8412 24.2625 63.1777C20.151 63.1863 5.40136 63.1863 5.40136 63.1863V63.1949Z'
      fill='#3A2A11'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.945 52.4332C47.7806 53.3637 47.5382 54.3029 47.1919 55.2507C44.9241 61.4975 43.1237 65.125 41.2973 67.124C36.2076 68.4767 35.7056 63.755 35.6017 61.2649C35.4459 57.5426 31.2219 56.6293 30.036 54.0186C28.8501 51.4078 30.564 51.158 30.2784 49.2107C30.0014 47.2548 28.8069 47.4099 29.0925 43.4291C29.3695 39.4484 33.5762 37.3633 40.4317 38.1301C44.3615 38.5695 46.6813 46.083 47.945 52.4246V52.4332Z'
      fill='#3C3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.945 52.4332C47.7805 53.3637 47.5382 54.3029 47.1919 55.2507C46.1359 58.163 45.1838 60.498 44.2749 62.3678C38.5274 60.6876 34.2687 54.6562 34.2687 47.4702C34.2687 43.8944 35.3247 40.5944 37.0992 37.9836C38.1292 37.9492 39.2372 38.0008 40.4317 38.1301C44.3615 38.5695 46.6813 46.0829 47.945 52.4245V52.4332Z'
      fill='#4D4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.2625 63.1863C24.2279 63.1863 24.1933 63.1949 24.1586 63.1949C22.1245 63.5396 19.9605 64.5219 19.5364 65.9866C16.2472 65.0819 9.03683 63.3414 5.3927 63.1949C5.66969 59.9897 6.37081 58.2836 7.59995 57.0946C5.81684 56.1554 5.15034 52.7778 6.41409 50.8909C7.67785 49.0125 9.83317 49.1762 9.63408 45.5918C9.49559 43.1017 10.0236 41.2061 12.352 39.3967C13.8495 38.2335 14.9315 36.8721 16.0135 35.5624C17.2166 34.899 18.9045 34.4596 21.233 34.7094C27.6037 35.4073 31.7499 39.0779 26.3832 44.7474C28.7982 46.9704 30.9102 53.3206 26.3832 60.7738C25.8725 61.6095 25.1454 62.4195 24.2625 63.1777V63.1863Z'
      fill='#91894E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.396 43.5756C27.1103 43.9633 26.7727 44.3511 26.3919 44.756C27.3873 45.6694 28.3308 47.2892 28.7809 49.4002C28.0278 51.7611 25.8119 53.4757 23.1892 53.4757C19.9519 53.4757 17.3292 50.865 17.3292 47.6425C17.3292 44.42 19.9519 41.8093 23.1892 41.8093C24.8425 41.8093 26.3313 42.49 27.396 43.5842V43.5756Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8408 47.4099C11.8586 47.6856 9.75526 55.5953 10.9757 55.8711C11.9279 56.0865 11.9452 55.3024 12.5165 55.3282C13.1397 55.3541 13.1484 56.388 15.0354 56.0262C16.9223 55.6643 15.598 47.9354 13.8495 47.4099H13.8408Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8408 47.4099C13.7889 47.4185 13.7456 47.4271 13.6937 47.4443C12.075 49.4088 10.7593 55.0094 11.7807 55.2421C12.7329 55.4575 12.7502 54.6734 13.3215 54.6992C13.9274 54.7251 13.9534 55.6987 15.6759 55.4316C16.5242 53.536 15.3383 47.8579 13.8495 47.4099H13.8408Z'
      fill='#221501'
    />
    <path
      d='M23.3796 52.3815C26.377 52.3815 28.8069 49.9627 28.8069 46.9791C28.8069 43.9954 26.377 41.5766 23.3796 41.5766C20.3822 41.5766 17.9524 43.9954 17.9524 46.9791C17.9524 49.9627 20.3822 52.3815 23.3796 52.3815Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.3796 41.5766C26.3746 41.5766 28.8069 43.9978 28.8069 46.9791C28.8069 49.0642 27.621 50.8736 25.8812 51.7697C25.5176 51.8473 25.1368 51.8904 24.7473 51.8904C21.7523 51.8904 19.32 49.4692 19.32 46.4879C19.32 44.4028 20.5059 42.5934 22.2457 41.6973C22.6092 41.6197 22.9901 41.5766 23.3796 41.5766Z'
      fill='url(#paint6_radial_70_6360)'
    />
    <path
      d='M9.18723 52.1447C9.56419 52.2585 10.5748 50.0369 11.4444 47.1826C12.314 44.3283 12.7134 41.9222 12.3365 41.8084C11.9595 41.6946 10.949 43.9162 10.0793 46.7705C9.20967 49.6248 8.81027 52.0309 9.18723 52.1447Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3347 41.8093C12.7156 41.9213 12.3087 44.3252 11.4432 47.1858C10.8372 49.1848 10.1534 50.865 9.67736 51.6577C9.60811 51.718 9.54752 51.7439 9.49559 51.7266C9.11473 51.6146 9.52156 49.2021 10.3871 46.3501C10.9931 44.3511 11.6682 42.6709 12.1529 41.8782C12.2222 41.8179 12.2828 41.792 12.3347 41.8093Z'
      fill='url(#paint7_linear_70_6360)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M44.3009 62.3333C41.3665 68.4337 38.9862 69.5969 35.368 69.3298C30.6419 68.9851 26.2014 62.8503 24.1673 63.1949C22.1332 63.5396 19.9692 64.5219 19.5451 65.9866C18.5843 49.5122 33.3772 62.79 37.8176 66.6156C39.1766 67.7874 42.085 64.9441 44.3009 62.3419V62.3333Z'
      fill='#4C4826'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.9788 55.6729C15.9875 56.3536 16.8444 56.276 16.9223 55.5695C17.6235 53.5361 15.8663 47.7717 14.2909 47.4529C15.7884 47.9527 16.8098 53.5964 15.9875 55.6729H15.9788Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.6785 36.1828C27.6903 37.2512 28.0625 39.6897 24.8944 40.4824C21.7177 41.2751 10.2227 41.5163 12.4732 39.8706C17.1993 36.4241 14.4468 34.4337 22.6785 36.1828Z'
      fill='#B6AA65'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.2625 63.1863H24.2539C24.2279 63.1863 24.1933 63.1949 24.1673 63.1949C22.1418 63.5396 19.9692 64.5219 19.5451 65.9866C19.0344 57.2842 22.9295 56.8878 27.3613 59.0074C27.0757 59.5933 26.7554 60.1878 26.3919 60.791C25.8812 61.6268 25.1541 62.4367 24.2625 63.1949V63.1863Z'
      fill='#3B3414'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.3834 41.1372C43.8162 41.6197 44.5346 41.6369 44.9847 41.1716C45.4348 40.7063 45.4435 39.9395 45.0107 39.457C44.9847 39.4225 34.9179 28.273 32.5029 17.8473C31.6287 14.0733 31.5248 11.4626 31.8537 9.71348C32.0268 8.80015 32.3125 8.16255 32.6674 7.72312C32.9703 7.344 33.3252 7.12859 33.6974 7.03381C34.0437 6.93903 34.4159 6.94765 34.7621 7.02519C35.3161 7.14582 35.7056 7.70588 35.8614 8.32626C35.922 8.57613 35.9393 8.826 35.922 9.05003C35.9047 9.23959 35.8527 9.4033 35.7835 9.53254C35.6623 9.73933 35.3853 9.85996 34.9266 9.80826C34.3033 9.73072 32.6241 9.98921 32.5462 10.6527C32.477 11.3161 34.0437 12.1433 34.6669 12.2208C36.1124 12.4018 37.0992 11.8072 37.6791 10.8595C37.9561 10.3942 38.1206 9.85996 38.1725 9.30852C38.2245 8.79154 38.1812 8.24009 38.0513 7.72312C37.7051 6.31004 36.7183 4.99175 35.2209 4.66433C34.563 4.51785 33.8619 4.50923 33.1694 4.69879C32.3731 4.9142 31.6114 5.37086 30.9708 6.15495C30.3822 6.88733 29.9148 7.89544 29.6551 9.23959C29.257 11.3161 29.3608 14.2801 30.3216 18.4332C32.8838 29.4965 43.3661 41.1113 43.4007 41.1458L43.3834 41.1372Z'
      fill='#B5781B'
    />
    <path
      d='M43.2016 41.1286C43.6777 41.6197 44.4567 41.6369 44.9501 41.163C45.4435 40.6891 45.4608 39.9137 44.9847 39.4225C44.9501 39.3881 33.9052 28.0232 31.2565 17.4079C30.2957 13.565 30.1831 10.9025 30.5467 9.11897C30.7371 8.18841 31.0487 7.53357 31.4382 7.09414C31.7758 6.71502 32.1653 6.491 32.5635 6.3876C32.953 6.29282 33.3512 6.30144 33.7321 6.37899C34.3466 6.49961 34.7708 7.07691 34.9352 7.7059C35.0045 7.95577 35.0218 8.21426 35.0045 8.4469C34.9872 8.63646 34.9352 8.80878 34.8487 8.93803C34.7102 9.14482 34.4072 9.27407 33.9052 9.21375C33.2214 9.13621 32.6068 9.61872 32.5289 10.2994C32.451 10.9801 32.9357 11.5919 33.6195 11.6694C35.2122 11.8503 36.2942 11.2472 36.9174 10.2822C37.2204 9.80828 37.4021 9.26545 37.4627 8.69677C37.5147 8.17118 37.4714 7.61112 37.3329 7.07691C36.952 5.63798 35.8701 4.29384 34.2254 3.96642C33.507 3.81994 32.728 3.81133 31.9749 4.00089C31.1007 4.21629 30.261 4.68157 29.5686 5.49151C28.9194 6.23251 28.4087 7.25785 28.123 8.63646C27.6903 10.7475 27.7941 13.7718 28.8501 18.0024C31.6633 29.2725 43.167 41.1027 43.2016 41.1372V41.1286Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.8045 41.1372C55.3718 41.6197 54.6533 41.6369 54.2032 41.1716C53.7531 40.7063 53.7444 39.9395 54.1772 39.457C54.2032 39.4225 64.27 28.273 66.685 17.8473C67.5592 14.0733 67.6631 11.4626 67.3342 9.71348C67.1611 8.80015 66.8754 8.16255 66.5205 7.72312C66.2176 7.344 65.8627 7.12859 65.4905 7.03381C65.1442 6.93903 64.772 6.94765 64.4258 7.02519C63.8718 7.14582 63.4823 7.70588 63.3265 8.32626C63.2659 8.57613 63.2486 8.826 63.2659 9.05003C63.2832 9.23959 63.3352 9.4033 63.4044 9.53254C63.5256 9.73933 63.8026 9.85996 64.2613 9.80826C64.8846 9.73072 66.5638 9.98921 66.6417 10.6527C66.711 11.3161 65.1442 12.1433 64.521 12.2208C63.0755 12.4018 62.0801 11.8072 61.5088 10.8595C61.2318 10.3942 61.0673 9.85996 61.0154 9.30852C60.9634 8.79154 61.0067 8.24009 61.1366 7.72312C61.4828 6.31004 62.4696 4.99175 63.967 4.66433C64.6249 4.51785 65.326 4.50923 66.0185 4.69879C66.8148 4.9142 67.5765 5.37086 68.2171 6.15495C68.8057 6.88733 69.2731 7.89544 69.5328 9.23959C69.9309 11.3161 69.8271 14.2801 68.8663 18.4332C66.3041 29.4965 55.8219 41.1113 55.7872 41.1458L55.8045 41.1372Z'
      fill='#B5781B'
    />
    <path
      d='M55.9863 41.1286C55.5103 41.6197 54.7312 41.6369 54.2378 41.163C53.7445 40.6891 53.7271 39.9137 54.2032 39.4225C54.2378 39.3881 65.2827 28.0232 67.9314 17.4079C68.8923 13.565 69.0048 10.9025 68.6412 9.11897C68.4508 8.18841 68.1392 7.53357 67.7497 7.09414C67.4121 6.71502 67.0226 6.491 66.6157 6.3876C66.2349 6.29282 65.8281 6.30144 65.4559 6.37899C64.8413 6.49961 64.4172 7.07691 64.2527 7.7059C64.1835 7.95577 64.1661 8.21426 64.1834 8.4469C64.2008 8.63646 64.2527 8.80878 64.3393 8.93803C64.4777 9.14482 64.7807 9.27407 65.2827 9.21375C65.9666 9.13621 66.5811 9.61872 66.659 10.2994C66.7369 10.9801 66.2522 11.5919 65.5684 11.6694C63.9844 11.8503 62.8937 11.2472 62.2705 10.2822C61.9675 9.80828 61.7858 9.26545 61.7252 8.69677C61.6732 8.17118 61.7165 7.61112 61.855 7.07691C62.2359 5.63798 63.3179 4.29384 64.9625 3.96642C65.6809 3.81994 66.4599 3.81133 67.213 4.00089C68.0873 4.21629 68.9269 4.68157 69.6193 5.49151C70.2685 6.23251 70.7792 7.25785 71.0649 8.63646C71.4977 10.7475 71.3938 13.7718 70.3378 18.0024C67.5246 29.2725 56.021 41.1027 55.9863 41.1372V41.1286Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.5072 46.2984C49.581 46.2984 48.6635 46.2984 47.7373 46.2984L45.0453 41.3095C41.5137 34.7611 39.8258 27.6958 39.8258 19.4672V2.71708C40.6568 2.58784 42.5784 2.47583 43.9287 2.26904C45.6166 2.15702 47.5036 2.09671 49.4944 2.09671C51.4853 2.09671 53.3723 2.15702 55.0601 2.26904V19.4672C55.0601 27.6958 54.082 34.7611 52.0566 41.3095L50.5072 46.2984Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7373 46.2984H47.3218L43.6863 41.5422C38.9169 35.304 36.6318 28.566 36.6318 20.7252V3.88889C36.6318 3.44084 37.8349 3.02726 39.8258 2.71707V19.4672C39.8258 27.6958 41.5137 34.7611 45.0453 41.3095L47.7373 46.2984Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.7725 3.89751V20.7338C62.7725 28.5746 60.4874 35.3126 55.718 41.5508L52.0825 46.307H51.2602L53.9522 41.3181C57.4838 34.7697 59.1717 27.7044 59.1717 19.4758V2.66537C61.4049 2.98418 62.7725 3.42361 62.7725 3.90612V3.89751Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.163 2.65676V19.4672C59.163 27.6957 57.4751 34.7611 53.9435 41.3095L51.2516 46.2984H50.5071L52.0566 41.3095C54.0907 34.7611 55.0601 27.6957 55.0601 19.4672V2.26041C56.6095 2.35519 58.0031 2.49305 59.163 2.65676Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 75.6283C58.358 75.8695 54.2032 76.016 49.6848 76.016C48.3345 76.016 47.0188 75.9988 45.7464 75.9815V71.8974H61.6473V75.6283Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 71.3029C54.3504 71.3029 58.5744 71.4321 61.6473 71.6476V73.345C58.5744 73.5604 54.3504 73.6896 49.6848 73.6896C48.3259 73.6896 47.0102 73.681 45.7464 73.6552V71.3287C47.0102 71.3115 48.3259 71.2943 49.6848 71.2943V71.3029Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 75.9729C36.9434 75.8006 30.3216 75.0768 30.3216 74.198V71.8888H45.7464V75.9729Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.048 74.198C69.048 74.7753 66.157 75.2922 61.6473 75.6197V71.8888H69.048V74.198Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 73.6637C36.9434 73.4914 30.3216 72.7677 30.3216 71.8888C30.3216 71.0185 36.9434 70.2862 45.7464 70.1138V73.6551V73.6637Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 70.4671C66.157 70.8031 69.048 71.3115 69.048 71.8888C69.048 72.4661 66.157 72.9831 61.6473 73.3105V70.4671Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 73.052C38.2331 72.9314 32.6501 72.4575 32.6501 71.8888V64.0566H45.7464V73.052Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.7196 71.8888C66.7196 72.2248 64.7807 72.5264 61.6473 72.7418V64.0566H66.7196V71.8888Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 72.7418C58.5744 72.9572 54.3504 73.0865 49.6848 73.0865C48.3259 73.0865 47.0102 73.0778 45.7464 73.052V64.0566H61.6473V72.7418Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 62.8589C51.0871 62.8589 66.7196 62.9967 66.7196 64.0566C66.7196 65.1164 51.0871 65.2542 49.6848 65.2542C47.0881 65.2542 32.6501 65.0733 32.6501 64.0566C32.6501 63.0398 47.0881 62.8589 49.6848 62.8589Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.667 50.5204H52.2556C54.0388 54.5528 54.662 55.9314 58.358 58.6197C62.0541 61.308 61.5174 63.5396 61.1366 63.9101C59.8815 64.4271 54.7832 64.6942 49.6848 64.6942C53.6146 64.6942 57.5444 64.4357 58.5138 63.9101C58.7995 63.5396 59.2236 61.3166 56.3758 58.6197C53.5281 55.9314 53.052 54.5528 51.6757 50.5204H51.667Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.2245 63.9101C37.8436 63.5396 37.3069 61.3166 41.003 58.6197C44.6991 55.9314 45.3223 54.5528 47.1054 50.5204H47.694C46.3177 54.5528 45.8416 55.9314 42.9938 58.6197C40.1461 61.308 40.5615 63.5396 40.8558 63.9101C41.8253 64.4271 45.7551 64.6942 49.6848 64.6942C44.5865 64.6942 39.4882 64.4357 38.2331 63.9101H38.2245Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.6976 50.5204H51.667C53.0433 54.5528 53.5194 55.9314 56.3672 58.6197C59.215 61.308 58.7995 63.5396 58.5052 63.9101C57.5357 64.4271 53.606 64.6942 49.6762 64.6942C51.6843 64.6942 53.7012 64.4357 54.1946 63.9101C54.3417 63.5396 54.5581 61.3166 53.0953 58.6197C51.6411 55.9314 51.3901 54.5528 50.6889 50.5204H50.6976Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.6635 50.5204C49.3473 50.5204 49.6762 50.5204 49.6762 50.5204C49.6762 50.5204 50.0051 50.5204 50.6889 50.5204C51.3901 54.5528 51.6411 55.9314 53.0953 58.6197C54.5494 61.308 54.3417 63.5396 54.1946 63.9101C53.7012 64.4357 51.693 64.6942 49.6762 64.6856H49.581H49.4944H49.3992H49.3126C45.5041 64.6683 41.7907 64.4098 40.8558 63.9101C40.5702 63.5396 40.1461 61.3166 42.9938 58.6197C45.8416 55.9314 46.3264 54.5528 47.694 50.5204H48.6635Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 48.8315H52.6625C53.1299 48.8315 53.5107 49.2107 53.5107 49.6759C53.5107 50.1412 53.1299 50.5203 52.6625 50.5203H46.6986C46.2311 50.5203 45.8503 50.1412 45.8503 49.6759C45.8503 49.2107 46.2311 48.8315 46.6986 48.8315Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 48.8315H52.6625C53.0174 48.8315 53.3203 49.047 53.4501 49.3571C53.3203 49.6673 53.0174 49.8827 52.6625 49.8827H46.6986C46.3437 49.8827 46.0407 49.6587 45.9109 49.3571C46.0407 49.047 46.3437 48.8315 46.6986 48.8315Z'
      fill='#CD9E34'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 47.1428H54.3244C54.7918 47.1428 55.1727 47.5219 55.1727 47.9872C55.1727 48.4524 54.7918 48.8316 54.3244 48.8316H45.0366C44.5692 48.8316 44.1884 48.4524 44.1884 47.9872C44.1884 47.5219 44.5692 47.1428 45.0366 47.1428Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 47.1428H54.3244C54.636 47.1428 54.913 47.3151 55.0601 47.565C54.913 47.8148 54.636 47.9872 54.3244 47.9872H45.0366C44.725 47.9872 44.448 47.8148 44.3009 47.565C44.448 47.3151 44.725 47.1428 45.0366 47.1428Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 45.4539H52.6625C53.1299 45.4539 53.5107 45.8331 53.5107 46.2983C53.5107 46.7636 53.1299 47.1427 52.6625 47.1427H46.6986C46.2311 47.1427 45.8503 46.7636 45.8503 46.2983C45.8503 45.8331 46.2311 45.4539 46.6986 45.4539Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 45.4539H52.6625C53.0174 45.4539 53.3203 45.6694 53.4501 45.9795C53.3203 46.2897 53.0174 46.5051 52.6625 46.5051H46.6986C46.3437 46.5051 46.0407 46.2897 45.9109 45.9795C46.0407 45.6694 46.3437 45.4539 46.6986 45.4539Z'
      fill='#E6C03B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.91474C36.8395 4.19046 36.3721 4.18184 36.0951 3.88027C35.8181 3.58732 35.8268 3.11342 36.1297 2.8377C36.7443 2.26041 38.5188 1.70896 41.1588 1.34708C43.392 1.03689 46.3004 0.838715 49.7022 0.838715C53.0347 0.838715 55.8046 1.02827 57.9599 1.32984C60.5739 1.69173 62.3311 2.21732 63.1707 2.75153C63.5169 2.97556 63.6208 3.43222 63.3958 3.77688C63.1707 4.12153 62.7119 4.22492 62.3657 4.0009C61.6906 3.57008 60.1412 3.12204 57.7608 2.79462C55.6834 2.51028 52.9914 2.32072 49.7022 2.32072C46.3437 2.32072 43.5132 2.51028 41.3579 2.81185C39.0208 3.13065 37.5493 3.53562 37.1425 3.91474Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.91475C36.8395 4.19047 36.3721 4.18185 36.0951 3.88028C36.0345 3.81135 35.9912 3.74242 35.9566 3.66487C35.9999 3.57009 36.0518 3.48393 36.1297 3.415C36.7443 2.83771 38.5188 2.28626 41.1588 1.92438C43.392 1.61419 46.3004 1.41602 49.7022 1.41602C53.0347 1.41602 55.8046 1.60557 57.9599 1.90715C60.5739 2.26903 62.3311 2.79463 63.1707 3.32884C63.3005 3.415 63.3958 3.52701 63.4563 3.66487C63.439 3.69934 63.4217 3.74242 63.3958 3.77688C63.1707 4.12154 62.7119 4.22493 62.3657 4.00091C61.6906 3.57009 60.1411 3.12204 57.7608 2.79462C55.6834 2.51029 52.9914 2.32073 49.7022 2.32073C46.3437 2.32073 43.5132 2.51029 41.3579 2.81186C39.0208 3.13066 37.5493 3.53563 37.1425 3.91475Z'
      fill='#CD9E34'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M43.3834 41.1372C43.8162 41.6197 44.5346 41.6369 44.9847 41.1716C45.4348 40.7063 45.4435 39.9395 45.0107 39.457C44.9847 39.4225 34.9179 28.273 32.5029 17.8473C31.6287 14.0733 31.5248 11.4626 31.8537 9.71348C32.0268 8.80015 32.3125 8.16255 32.6674 7.72312C32.9703 7.344 33.3252 7.12859 33.6974 7.03381C34.0437 6.93903 34.4159 6.94765 34.7621 7.02519C35.3161 7.14582 35.7056 7.70588 35.8614 8.32626C35.922 8.57613 35.9393 8.826 35.922 9.05003C35.9047 9.23959 35.8527 9.4033 35.7835 9.53254C35.6623 9.73933 35.3853 9.85996 34.9266 9.80826C34.3033 9.73072 32.6241 9.98921 32.5462 10.6527C32.477 11.3161 34.0437 12.1433 34.6669 12.2208C36.1124 12.4018 37.0992 11.8072 37.6791 10.8595C37.9561 10.3942 38.1206 9.85996 38.1725 9.30852C38.2245 8.79154 38.1812 8.24009 38.0513 7.72312C37.7051 6.31004 36.7183 4.99175 35.2209 4.66433C34.563 4.51785 33.8619 4.50923 33.1694 4.69879C32.3731 4.9142 31.6114 5.37086 30.9708 6.15495C30.3822 6.88733 29.9148 7.89544 29.6551 9.23959C29.257 11.3161 29.3608 14.2801 30.3216 18.4332C32.8838 29.4965 43.3661 41.1113 43.4007 41.1458L43.3834 41.1372Z'
      fill='#B5781B'
    />
    <path
      d='M43.2016 41.1286C43.6777 41.6197 44.4567 41.6369 44.9501 41.163C45.4435 40.6891 45.4608 39.9137 44.9847 39.4225C44.9501 39.3881 33.9052 28.0232 31.2565 17.4079C30.2957 13.565 30.1831 10.9025 30.5467 9.11897C30.7371 8.18841 31.0487 7.53357 31.4382 7.09414C31.7758 6.71502 32.1653 6.491 32.5635 6.3876C32.953 6.29282 33.3512 6.30144 33.7321 6.37899C34.3466 6.49961 34.7708 7.07691 34.9352 7.7059C35.0045 7.95577 35.0218 8.21426 35.0045 8.4469C34.9872 8.63646 34.9352 8.80878 34.8487 8.93803C34.7102 9.14482 34.4072 9.27407 33.9052 9.21375C33.2214 9.13621 32.6068 9.61872 32.5289 10.2994C32.451 10.9801 32.9357 11.5919 33.6195 11.6694C35.2122 11.8503 36.2942 11.2472 36.9174 10.2822C37.2204 9.80828 37.4021 9.26545 37.4627 8.69677C37.5147 8.17118 37.4714 7.61112 37.3329 7.07691C36.952 5.63798 35.8701 4.29384 34.2254 3.96642C33.507 3.81994 32.728 3.81133 31.9749 4.00089C31.1007 4.21629 30.261 4.68157 29.5686 5.49151C28.9194 6.23251 28.4087 7.25785 28.123 8.63646C27.6903 10.7475 27.7941 13.7718 28.8501 18.0024C31.6633 29.2725 43.167 41.1027 43.2016 41.1372V41.1286Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M55.8045 41.1372C55.3718 41.6197 54.6533 41.6369 54.2032 41.1716C53.7531 40.7063 53.7444 39.9395 54.1772 39.457C54.2032 39.4225 64.27 28.273 66.685 17.8473C67.5592 14.0733 67.6631 11.4626 67.3342 9.71348C67.1611 8.80015 66.8754 8.16255 66.5205 7.72312C66.2176 7.344 65.8627 7.12859 65.4905 7.03381C65.1442 6.93903 64.772 6.94765 64.4258 7.02519C63.8718 7.14582 63.4823 7.70588 63.3265 8.32626C63.2659 8.57613 63.2486 8.826 63.2659 9.05003C63.2832 9.23959 63.3352 9.4033 63.4044 9.53254C63.5256 9.73933 63.8026 9.85996 64.2613 9.80826C64.8846 9.73072 66.5638 9.98921 66.6417 10.6527C66.711 11.3161 65.1442 12.1433 64.521 12.2208C63.0755 12.4018 62.0801 11.8072 61.5088 10.8595C61.2318 10.3942 61.0673 9.85996 61.0154 9.30852C60.9634 8.79154 61.0067 8.24009 61.1366 7.72312C61.4828 6.31004 62.4696 4.99175 63.967 4.66433C64.6249 4.51785 65.326 4.50923 66.0185 4.69879C66.8148 4.9142 67.5765 5.37086 68.2171 6.15495C68.8057 6.88733 69.2731 7.89544 69.5328 9.23959C69.9309 11.3161 69.8271 14.2801 68.8663 18.4332C66.3041 29.4965 55.8219 41.1113 55.7872 41.1458L55.8045 41.1372Z'
      fill='#B5781B'
    />
    <path
      d='M55.9863 41.1286C55.5103 41.6197 54.7312 41.6369 54.2378 41.163C53.7445 40.6891 53.7271 39.9137 54.2032 39.4225C54.2378 39.3881 65.2827 28.0232 67.9314 17.4079C68.8923 13.565 69.0048 10.9025 68.6412 9.11897C68.4508 8.18841 68.1392 7.53357 67.7497 7.09414C67.4121 6.71502 67.0226 6.491 66.6157 6.3876C66.2349 6.29282 65.8281 6.30144 65.4559 6.37899C64.8413 6.49961 64.4172 7.07691 64.2527 7.7059C64.1835 7.95577 64.1661 8.21426 64.1834 8.4469C64.2008 8.63646 64.2527 8.80878 64.3393 8.93803C64.4777 9.14482 64.7807 9.27407 65.2827 9.21375C65.9666 9.13621 66.5811 9.61872 66.659 10.2994C66.7369 10.9801 66.2522 11.5919 65.5684 11.6694C63.9844 11.8503 62.8937 11.2472 62.2705 10.2822C61.9675 9.80828 61.7858 9.26545 61.7252 8.69677C61.6732 8.17118 61.7165 7.61112 61.855 7.07691C62.2359 5.63798 63.3179 4.29384 64.9625 3.96642C65.6809 3.81994 66.4599 3.81133 67.213 4.00089C68.0873 4.21629 68.9269 4.68157 69.6193 5.49151C70.2685 6.23251 70.7792 7.25785 71.0649 8.63646C71.4977 10.7475 71.3938 13.7718 70.3378 18.0024C67.5246 29.2725 56.021 41.1027 55.9863 41.1372V41.1286Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.5072 46.2984C49.581 46.2984 48.6635 46.2984 47.7373 46.2984L45.0453 41.3095C41.5137 34.7611 39.8258 27.6958 39.8258 19.4672V2.71708C40.6568 2.58784 42.5784 2.47583 43.9287 2.26904C45.6166 2.15702 47.5036 2.09671 49.4944 2.09671C51.4853 2.09671 53.3723 2.15702 55.0601 2.26904V19.4672C55.0601 27.6958 54.082 34.7611 52.0566 41.3095L50.5072 46.2984Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M47.7373 46.2984H47.3218L43.6863 41.5422C38.9169 35.304 36.6318 28.566 36.6318 20.7252V3.88889C36.6318 3.44084 37.8349 3.02726 39.8258 2.71707V19.4672C39.8258 27.6958 41.5137 34.7611 45.0453 41.3095L47.7373 46.2984Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M62.7725 3.89751V20.7338C62.7725 28.5746 60.4874 35.3126 55.718 41.5508L52.0825 46.307H51.2602L53.9522 41.3181C57.4838 34.7697 59.1717 27.7044 59.1717 19.4758V2.66537C61.4049 2.98418 62.7725 3.42361 62.7725 3.90612V3.89751Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M59.163 2.65676V19.4672C59.163 27.6957 57.4751 34.7611 53.9435 41.3095L51.2516 46.2984H50.5071L52.0566 41.3095C54.0907 34.7611 55.0601 27.6957 55.0601 19.4672V2.26041C56.6095 2.35519 58.0031 2.49305 59.163 2.65676Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 75.6283C58.358 75.8695 54.2032 76.016 49.6848 76.016C48.3345 76.016 47.0188 75.9988 45.7464 75.9815V71.8974H61.6473V75.6283Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 71.3029C54.3504 71.3029 58.5744 71.4321 61.6473 71.6476V73.345C58.5744 73.5604 54.3504 73.6896 49.6848 73.6896C48.3259 73.6896 47.0102 73.681 45.7464 73.6552V71.3287C47.0102 71.3115 48.3259 71.2943 49.6848 71.2943V71.3029Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 75.9729C36.9434 75.8006 30.3216 75.0768 30.3216 74.198V71.8888H45.7464V75.9729Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.048 74.198C69.048 74.7753 66.157 75.2922 61.6473 75.6197V71.8888H69.048V74.198Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 73.6637C36.9434 73.4914 30.3216 72.7677 30.3216 71.8888C30.3216 71.0185 36.9434 70.2862 45.7464 70.1138V73.6551V73.6637Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 70.4671C66.157 70.8031 69.048 71.3115 69.048 71.8888C69.048 72.4661 66.157 72.9831 61.6473 73.3105V70.4671Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.7464 73.052C38.2331 72.9314 32.6501 72.4575 32.6501 71.8888V64.0566H45.7464V73.052Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M66.7196 71.8888C66.7196 72.2248 64.7807 72.5264 61.6473 72.7418V64.0566H66.7196V71.8888Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M61.6473 72.7418C58.5744 72.9572 54.3504 73.0865 49.6848 73.0865C48.3259 73.0865 47.0102 73.0778 45.7464 73.052V64.0566H61.6473V72.7418Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.6848 62.8589C51.0871 62.8589 66.7196 62.9967 66.7196 64.0566C66.7196 65.1164 51.0871 65.2542 49.6848 65.2542C47.0881 65.2542 32.6501 65.0733 32.6501 64.0566C32.6501 63.0398 47.0881 62.8589 49.6848 62.8589Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M51.667 50.5204H52.2556C54.0388 54.5528 54.662 55.9314 58.358 58.6197C62.0541 61.308 61.5174 63.5396 61.1366 63.9101C59.8815 64.4271 54.7832 64.6942 49.6848 64.6942C53.6146 64.6942 57.5444 64.4357 58.5138 63.9101C58.7995 63.5396 59.2236 61.3166 56.3758 58.6197C53.5281 55.9314 53.052 54.5528 51.6757 50.5204H51.667Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.2245 63.9101C37.8436 63.5396 37.3069 61.3166 41.003 58.6197C44.6991 55.9314 45.3223 54.5528 47.1054 50.5204H47.694C46.3177 54.5528 45.8416 55.9314 42.9938 58.6197C40.1461 61.308 40.5615 63.5396 40.8558 63.9101C41.8253 64.4271 45.7551 64.6942 49.6848 64.6942C44.5865 64.6942 39.4882 64.4357 38.2331 63.9101H38.2245Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.6976 50.5204H51.667C53.0433 54.5528 53.5194 55.9314 56.3672 58.6197C59.215 61.308 58.7995 63.5396 58.5052 63.9101C57.5357 64.4271 53.606 64.6942 49.6762 64.6942C51.6843 64.6942 53.7012 64.4357 54.1946 63.9101C54.3417 63.5396 54.5581 61.3166 53.0953 58.6197C51.6411 55.9314 51.3901 54.5528 50.6889 50.5204H50.6976Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.6635 50.5204C49.3473 50.5204 49.6762 50.5204 49.6762 50.5204C49.6762 50.5204 50.0051 50.5204 50.6889 50.5204C51.3901 54.5528 51.6411 55.9314 53.0953 58.6197C54.5494 61.308 54.3417 63.5396 54.1946 63.9101C53.7012 64.4357 51.693 64.6942 49.6762 64.6856H49.581H49.4944H49.3992H49.3126C45.5041 64.6683 41.7907 64.4098 40.8558 63.9101C40.5702 63.5396 40.1461 61.3166 42.9938 58.6197C45.8416 55.9314 46.3264 54.5528 47.694 50.5204H48.6635Z'
      fill='#FFD900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 48.8315H52.6625C53.1299 48.8315 53.5107 49.2107 53.5107 49.6759C53.5107 50.1412 53.1299 50.5203 52.6625 50.5203H46.6986C46.2311 50.5203 45.8503 50.1412 45.8503 49.6759C45.8503 49.2107 46.2311 48.8315 46.6986 48.8315Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 48.8315H52.6625C53.0174 48.8315 53.3203 49.047 53.4501 49.3571C53.3203 49.6673 53.0174 49.8827 52.6625 49.8827H46.6986C46.3437 49.8827 46.0407 49.6587 45.9109 49.3571C46.0407 49.047 46.3437 48.8315 46.6986 48.8315Z'
      fill='#CD9E34'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 47.1428H54.3244C54.7918 47.1428 55.1727 47.5219 55.1727 47.9872C55.1727 48.4524 54.7918 48.8316 54.3244 48.8316H45.0366C44.5692 48.8316 44.1884 48.4524 44.1884 47.9872C44.1884 47.5219 44.5692 47.1428 45.0366 47.1428Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M45.0366 47.1428H54.3244C54.636 47.1428 54.913 47.3151 55.0601 47.565C54.913 47.8148 54.636 47.9872 54.3244 47.9872H45.0366C44.725 47.9872 44.448 47.8148 44.3009 47.565C44.448 47.3151 44.725 47.1428 45.0366 47.1428Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 45.4539H52.6625C53.1299 45.4539 53.5107 45.8331 53.5107 46.2983C53.5107 46.7636 53.1299 47.1427 52.6625 47.1427H46.6986C46.2311 47.1427 45.8503 46.7636 45.8503 46.2983C45.8503 45.8331 46.2311 45.4539 46.6986 45.4539Z'
      fill='#B5781B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.6986 45.4539H52.6625C53.0174 45.4539 53.3203 45.6694 53.4501 45.9795C53.3203 46.2897 53.0174 46.5051 52.6625 46.5051H46.6986C46.3437 46.5051 46.0407 46.2897 45.9109 45.9795C46.0407 45.6694 46.3437 45.4539 46.6986 45.4539Z'
      fill='#E6C03B'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.91474C36.8395 4.19046 36.3721 4.18184 36.0951 3.88027C35.8181 3.58732 35.8268 3.11342 36.1297 2.8377C36.7443 2.26041 38.5188 1.70896 41.1588 1.34708C43.392 1.03689 46.3004 0.838715 49.7022 0.838715C53.0347 0.838715 55.8046 1.02827 57.9599 1.32984C60.5739 1.69173 62.3311 2.21732 63.1707 2.75153C63.5169 2.97556 63.6208 3.43222 63.3958 3.77688C63.1707 4.12153 62.7119 4.22492 62.3657 4.0009C61.6906 3.57008 60.1412 3.12204 57.7608 2.79462C55.6834 2.51028 52.9914 2.32072 49.7022 2.32072C46.3437 2.32072 43.5132 2.51028 41.3579 2.81185C39.0208 3.13065 37.5493 3.53562 37.1425 3.91474Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M37.1425 3.91475C36.8395 4.19047 36.3721 4.18185 36.0951 3.88028C36.0345 3.81135 35.9912 3.74242 35.9566 3.66487C35.9999 3.57009 36.0518 3.48393 36.1297 3.415C36.7443 2.83771 38.5188 2.28626 41.1588 1.92438C43.392 1.61419 46.3004 1.41602 49.7022 1.41602C53.0347 1.41602 55.8046 1.60557 57.9599 1.90715C60.5739 2.26903 62.3311 2.79463 63.1707 3.32884C63.3005 3.415 63.3958 3.52701 63.4563 3.66487C63.439 3.69934 63.4217 3.74242 63.3958 3.77688C63.1707 4.12154 62.7119 4.22493 62.3657 4.00091C61.6906 3.57009 60.1411 3.12204 57.7608 2.79462C55.6834 2.51029 52.9914 2.32073 49.7022 2.32073C46.3437 2.32073 43.5132 2.51029 41.3579 2.81186C39.0208 3.13066 37.5493 3.53563 37.1425 3.91475Z'
      fill='#CD9E34'
    />
    <defs>
      <radialGradient
        id='paint0_radial_70_6360'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(50 72) rotate(90) scale(19 50)'
      >
        <stop stopColor='#FFFF00' />
        <stop offset='1' stopColor='#DF8000' stopOpacity='0' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_70_6360'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(67.0775 38.7205) rotate(-6.59003) scale(7.81165 6.50509)'
      >
        <stop stopColor='#FFB900' />
        <stop offset='0.505' stopColor='#780000' />
        <stop offset='1' stopColor='#3B3414' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_70_6360'
        x1='68.5'
        y1='28.5'
        x2='67.5'
        y2='32'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#3B3414' />
        <stop offset='0.375322' stopColor='#780000' />
        <stop offset='1' stopColor='#FFB900' />
      </linearGradient>
      <radialGradient
        id='paint3_radial_70_6360'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(35.048 26.5655) rotate(-8.49663) scale(6.92927 7.3962)'
      >
        <stop stopColor='#FFB900' />
        <stop offset='0.505' stopColor='#780000' />
        <stop offset='1' stopColor='#3B3414' />
      </radialGradient>
      <radialGradient
        id='paint4_radial_70_6360'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(66.5 53) rotate(-30.2564) scale(13.8924 14.6162)'
      >
        <stop stopColor='#FFB900' />
        <stop offset='0.505' stopColor='#780000' />
        <stop offset='1' stopColor='#3B3414' />
      </radialGradient>
      <linearGradient
        id='paint5_linear_70_6360'
        x1='83.5'
        y1='46'
        x2='80.5'
        y2='48.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#3B3414' />
        <stop offset='0.375322' stopColor='#780000' />
        <stop offset='1' stopColor='#FFB900' />
      </linearGradient>
      <radialGradient
        id='paint6_radial_70_6360'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(27 49.5) rotate(27.5528) scale(12.9711 13.2644)'
      >
        <stop stopColor='#FFB900' />
        <stop offset='0.505' stopColor='#780000' />
        <stop offset='1' stopColor='#3B3414' />
      </radialGradient>
      <linearGradient
        id='paint7_linear_70_6360'
        x1='10'
        y1='48'
        x2='11.5'
        y2='48.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#3B3414' />
        <stop offset='0.375322' stopColor='#780000' />
        <stop offset='1' stopColor='#FFB900' />
      </linearGradient>
    </defs>
  </svg>
);
