import { Chart } from '../chart/Chart';
import Modal from '../components/modal/Modal';
import GameStatus from '../components/gameStatus/GameStatus';
import CustomSnackbar from '../components/customSnackbar/CustomSnackbar';
import ErrorSnackbar from '../components/errorSnackbar/ErrorSnackbar';
import { useGame } from '../context/gameContext';

const Game = ({
  blackSpotAddress,
  doubloonAddress,
}: {
  blackSpotAddress: string;
  doubloonAddress: string;
}) => {
  const {
    customSnackbarOpen,
    errorSnackbarOpen,
    errorSnackbarCause,
    isModalOpen,
    modalContent,
    winners,
    startDate,
    recipientAddress,
    totalTransactions,
    finishTransactions,
    handleOpenErrorSnackbar,
    handleCloseCustomSnackbar,
    handleAnywayCloseCustomSnackbar,
    handleCloseErrorSnackbar,
    handleCloseModal,
    setRecipientAddress,
  } = useGame();

  return (
    <div className='flex-1 flex flex-col'>
      <div className='flex-none' />
      <div className='flex-1'>
        <Chart>
          <GameStatus
            startDate={startDate}
            totalTransactions={totalTransactions}
            finishTransactions={finishTransactions}
          />
        </Chart>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        modalContent={modalContent}
        handleCloseModal={handleCloseModal}
        recepientAddress={recipientAddress}
        setRecepientAddress={setRecipientAddress}
        handleOpenErrorSnackbar={handleOpenErrorSnackbar}
        winners={winners}
      />
      <CustomSnackbar
        isOpen={customSnackbarOpen}
        handleOpenErrorSnackbar={handleOpenErrorSnackbar}
        handleClose={handleCloseCustomSnackbar}
        handleAnywayClose={handleAnywayCloseCustomSnackbar}
        bsAddress={blackSpotAddress}
        dAddress={doubloonAddress}
      />
      <ErrorSnackbar
        isOpen={errorSnackbarOpen}
        handleClose={handleCloseErrorSnackbar}
        errorCause={errorSnackbarCause}
      />
    </div>
  );
};

export default Game;
