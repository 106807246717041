export const StarIconDefaultStateIcon = () => (
  <svg viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='22' cy='22' r='18' fill='url(#paint0_radial_203_1026)' />
    <circle cx='22' cy='22' r='18' fill='url(#paint1_radial_203_1026)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 5C29.7322 5 36 9.70116 36 15.5003C36 21.2988 29.7322 26 22 26C14.2678 26 8 21.2988 8 15.5003C8 9.70116 14.2678 5 22 5Z'
      fill='url(#paint2_linear_203_1026)'
    />
    <circle
      cx='22'
      cy='22'
      r='20'
      stroke='url(#paint3_linear_203_1026)'
      strokeWidth='4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 22C40 31.9411 31.9411 40 22 40C17.2838 40 12.9913 38.1862 9.78185 35.2182C13.0701 38.7738 17.7751 41 23 41C32.9411 41 41 32.9411 41 23C41 17.7751 38.7738 13.0701 35.2182 9.78185C38.1862 12.9913 40 17.2838 40 22ZM6.95165 38.0484C3.26056 34.1136 1 28.8208 1 23C1 10.8497 10.8497 1 23 1C28.8208 1 34.1136 3.26056 38.0484 6.95165C34.0347 2.67295 28.3295 0 22 0C9.84974 0 0 9.84974 0 22C0 28.3295 2.67295 34.0347 6.95165 38.0484Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 24L42 22L40.5348 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 24L2 22L3.4652 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 20L42 22L40.5348 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 20L2 22L3.4652 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L20 4L22 2L22 3.4652Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L24 40L22 42L22 40.5348Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L20 40L22 42L22 40.5348Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L24 4L22 2L22 3.4652Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 22C17.8352 22 22 26.1648 22 34V22C17.991 22 13.9539 22 10 22Z'
      fill='#F4BA15'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 22C17.8352 22 22 17.8352 22 10V22C17.991 22 13.9539 22 10 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 22C26.1648 22 22 26.1648 22 34V22C26.009 22 30.0461 22 34 22Z'
      fill='#EE8900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 22C26.1648 22 22 17.8352 22 10V22C26.009 22 30.0461 22 34 22Z'
      fill='#F4BA15'
    />
    <defs>
      <radialGradient
        id='paint0_radial_203_1026'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(17.725 16.15) rotate(53.514) scale(26.8656)'
      >
        <stop stopColor='#9C9C9C' />
        <stop offset='1' stopColor='#2D2D2D' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_203_1026'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(22 22) rotate(90) scale(18)'
      >
        <stop stopOpacity='0' />
        <stop offset='1' stopOpacity='0.5' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_203_1026'
        x1='22'
        y1='5'
        x2='22'
        y2='26'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_203_1026'
        x1='22'
        y1='0'
        x2='22'
        y2='44'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

export const StarIconHoverStateIcon = () => (
  <svg viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='22' cy='22' r='18' fill='url(#paint0_radial_203_1066)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 5C29.7322 5 36 9.70116 36 15.5003C36 21.2988 29.7322 26 22 26C14.2678 26 8 21.2988 8 15.5003C8 9.70116 14.2678 5 22 5Z'
      fill='url(#paint1_linear_203_1066)'
    />
    <circle
      cx='22'
      cy='22'
      r='20'
      stroke='url(#paint2_linear_203_1066)'
      strokeWidth='4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 22C40 31.9411 31.9411 40 22 40C17.2838 40 12.9913 38.1862 9.78185 35.2182C13.0701 38.7738 17.7751 41 23 41C32.9411 41 41 32.9411 41 23C41 17.7751 38.7738 13.0701 35.2182 9.78185C38.1862 12.9913 40 17.2838 40 22ZM6.95165 38.0484C3.26056 34.1136 1 28.8208 1 23C1 10.8497 10.8497 1 23 1C28.8208 1 34.1136 3.26056 38.0484 6.95165C34.0347 2.67295 28.3295 0 22 0C9.84974 0 0 9.84974 0 22C0 28.3295 2.67295 34.0347 6.95165 38.0484Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 24L42 22L40.5348 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 24L2 22L3.4652 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 20L42 22L40.5348 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 20L2 22L3.4652 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L20 4L22 2L22 3.4652Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L24 40L22 42L22 40.5348Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L20 40L22 42L22 40.5348Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L24 4L22 2L22 3.4652Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 22C17.8352 22 22 26.1648 22 34V22C17.991 22 13.9539 22 10 22Z'
      fill='#F4BA15'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 22C17.8352 22 22 17.8352 22 10V22C17.991 22 13.9539 22 10 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 22C26.1648 22 22 26.1648 22 34V22C26.009 22 30.0461 22 34 22Z'
      fill='#EE8900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 22C26.1648 22 22 17.8352 22 10V22C26.009 22 30.0461 22 34 22Z'
      fill='#F4BA15'
    />
    <defs>
      <radialGradient
        id='paint0_radial_203_1066'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(22 22) rotate(90) scale(18)'
      >
        <stop stopOpacity='0' />
        <stop offset='1' stopOpacity='0.5' />
      </radialGradient>
      <linearGradient
        id='paint1_linear_203_1066'
        x1='22'
        y1='5'
        x2='22'
        y2='26'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_203_1066'
        x1='22'
        y1='0'
        x2='22'
        y2='44'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);

export const StarIconPressStateIcon = () => (
  <svg viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='22' cy='22' r='18' fill='url(#paint0_linear_203_1046)' />
    <circle cx='22' cy='22' r='18' fill='url(#paint1_radial_203_1046)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 5C29.7322 5 36 9.70116 36 15.5003C36 21.2988 29.7322 26 22 26C14.2678 26 8 21.2988 8 15.5003C8 9.70116 14.2678 5 22 5Z'
      fill='url(#paint2_linear_203_1046)'
    />
    <circle
      cx='22'
      cy='22'
      r='20'
      stroke='url(#paint3_linear_203_1046)'
      strokeWidth='4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 22C40 31.9411 31.9411 40 22 40C17.2838 40 12.9913 38.1862 9.78185 35.2182C13.0701 38.7738 17.7751 41 23 41C32.9411 41 41 32.9411 41 23C41 17.7751 38.7738 13.0701 35.2182 9.78185C38.1862 12.9913 40 17.2838 40 22ZM6.95165 38.0484C3.26056 34.1136 1 28.8208 1 23C1 10.8497 10.8497 1 23 1C28.8208 1 34.1136 3.26056 38.0484 6.95165C34.0347 2.67295 28.3295 0 22 0C9.84974 0 0 9.84974 0 22C0 28.3295 2.67295 34.0347 6.95165 38.0484Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 24L42 22L40.5348 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 24L2 22L3.4652 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 20L42 22L40.5348 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 20L2 22L3.4652 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L20 4L22 2L22 3.4652Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L24 40L22 42L22 40.5348Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L20 40L22 42L22 40.5348Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L24 4L22 2L22 3.4652Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 22C17.8352 22 22 26.1648 22 34V22C17.991 22 13.9539 22 10 22Z'
      fill='#F4BA15'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 22C17.8352 22 22 17.8352 22 10V22C17.991 22 13.9539 22 10 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 22C26.1648 22 22 26.1648 22 34V22C26.009 22 30.0461 22 34 22Z'
      fill='#EE8900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 22C26.1648 22 22 17.8352 22 10V22C26.009 22 30.0461 22 34 22Z'
      fill='#F4BA15'
    />
    <defs>
      <linearGradient
        id='paint0_linear_203_1046'
        x1='22'
        y1='4'
        x2='22'
        y2='40'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <radialGradient
        id='paint1_radial_203_1046'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(22 22) rotate(90) scale(18)'
      >
        <stop stopColor='#825B00' stopOpacity='0' />
        <stop offset='1' stopColor='#825B00' stopOpacity='0.3' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_203_1046'
        x1='22'
        y1='5'
        x2='22'
        y2='26'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_203_1046'
        x1='22'
        y1='0'
        x2='22'
        y2='44'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
    </defs>
  </svg>
);
