import { createContext, useEffect, useState, ReactNode } from 'react';
import { useSnackbar } from 'notistack';
import { addHours } from '../utils/utils';
import { useGame } from './gameContext';

type TimerContextType = {
  minutes: number;
  seconds: number;
  finishTransactions: number;
  totalTransactions: number;
  isTimeOver: boolean;
  progressPercentage: number;
  angleInRadians: number;
};

const defaultContext: TimerContextType = {
  minutes: 0,
  seconds: 0,
  finishTransactions: 0,
  totalTransactions: 0,
  isTimeOver: false,
  progressPercentage: 0,
  angleInRadians: 0,
};

export const TimerContext = createContext<TimerContextType>(defaultContext);

type TimerProviderProps = {
  children: ReactNode;
};

export const TimerProvider: React.FC<TimerProviderProps> = ({ children }) => {
  const { startDate, totalTransactions, finishTransactions } = useGame();

  const { enqueueSnackbar } = useSnackbar();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(100);
  const [angleInRadians, setAngleInRadians] = useState(0);

  const handleLittleTimeLeftSnackbar = (minutes: number) => {
    enqueueSnackbar(
      minutes === 0
        ? 'Time is over! Before the end of the game 3 transactions'
        : `Only ${minutes} minutes left before the end of time!`,
      {
        autoHideDuration: 3000,
        variant: 'warning',
      }
    );
  };

  // Calculate the percentage of time left and the angle for countdown
  const calculateProgress = (mins: number, secs: number) => {
    // Assuming a 10-minute game (adjust as needed)
    const totalGameTimeInSeconds = 10 * 60;
    const currentTimeInSeconds = mins * 60 + secs;
    const percentage = (currentTimeInSeconds / totalGameTimeInSeconds) * 100;
    const clampedPercentage = Math.max(0, Math.min(100, percentage));

    // Calculate angle (same formula as in TimerComponent)
    const angle = (clampedPercentage / 100) * 2 * Math.PI - Math.PI / 2;

    setProgressPercentage(clampedPercentage);
    setAngleInRadians(angle);
  };

  useEffect(() => {
    const endTime = addHours(1 / 6, new Date(startDate)).getTime();

    const mins = Math.floor((endTime - (new Date() as any)) / 60000);
    const secs =
      mins >= 0 ? Math.floor(((endTime - (new Date() as any)) / 1000) % 60) : 0;

    setMinutes(mins > 0 ? mins : 0);
    setSeconds(secs);

    // Calculate progress when time updates
    calculateProgress(mins > 0 ? mins : 0, secs);
  }, [startDate]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds <= 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
          if (minutes < 3) {
            handleLittleTimeLeftSnackbar(minutes);
          }
        }
      }

      // Update progress whenever time changes
      calculateProgress(minutes, seconds);
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  const isTimeOver = minutes === 0 && seconds === 0;

  return (
    <TimerContext.Provider
      value={{
        minutes,
        seconds,
        finishTransactions,
        totalTransactions,
        isTimeOver,
        progressPercentage,
        angleInRadians,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
