export const EthIconDefaultState = () => (
  <svg viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M40 80.0015C62.0914 80.0015 80 62.1032 80 40.0245C80 17.9458 62.0914 0.0475464 40 0.0475464C17.9086 0.0475464 0 17.9458 0 40.0245C0 62.1032 17.9086 80.0015 40 80.0015Z'
      fill='#627EEA'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 70.3262V55.1516L21.1774 44.1926L40 70.3341V70.3262Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 70.3262V55.1516L58.8226 44.1926L40 70.3341V70.3262Z'
      fill='#C1CCF7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.0001 51.451V32.243L21.2964 40.5633L40.0001 51.451Z'
      fill='#C1CCF7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 51.451V32.243L58.7037 40.5633L40 51.451Z'
      fill='#8198EE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.0001 32.243V10.0715L21.2964 40.5633L40.0001 32.243Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 32.243V10.0715L58.7037 40.5633L40 32.243Z'
      fill='#C1CCF7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 0C59.3935 0 75.56 13.7958 79.223 32.1004C71.112 42.1403 56.7295 48.8123 40.333 48.8123C23.5798 48.8123 8.92767 41.8391 0.919739 31.4427C4.83649 13.4709 20.8444 0.00792408 40 0.00792408V0Z'
      fill='url(#paint0_linear_251_2031)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_251_2031'
        x1='40.0714'
        y1='0'
        x2='40.0714'
        y2='48.8123'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
    </defs>
  </svg>
);
