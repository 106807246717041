import { ButtonHTMLAttributes } from 'react';
import {
  DubloneIconDefaultStateIcon,
  DubloneIconHoverStateIcon,
  DubloneIconPressStateIcon,
} from '../CustomIcons';

interface DublonButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  className?: string;
}

export const DublonButton: React.FC<DublonButtonProps> = ({
  onClick,
  className,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={`group relative transition-transform duration-100 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none ${className}`}
      {...props}
    >
      <div className='absolute inset-0 transition-opacity duration-200 opacity-100 group-hover:opacity-0 group-active:opacity-0'>
        <DubloneIconDefaultStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-100 group-active:opacity-0'>
        <DubloneIconHoverStateIcon />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-0 group-active:opacity-100'>
        <DubloneIconPressStateIcon />
      </div>
    </button>
  );
};
