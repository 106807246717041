import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { BigNumber } from 'ethers';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { ErrorType, getCookie, ModalContentType } from '../utils/utils';
import { useSocketListeners } from '../utils/socket';

interface GameContextType {
  pirates: Pirate[];
  setPirates: (pirates: Pirate[]) => void;
  customSnackbarOpen: boolean;
  errorSnackbarOpen: boolean;
  errorSnackbarCause: ErrorType;
  isModalOpen: boolean;
  modalContent: ModalContentType;
  winners: Winner[];
  startDate: Date;
  recipientAddress: string;
  gameResultsData: GameResultData[];
  currentPage: string;
  totalTransactions: number;
  finishTransactions: number;
  handleTransactionSnackbar: (
    sender: string,
    receiver: string,
    amount: string
  ) => void;
  handleOpenCustomSnackbar: () => void;
  handleOpenErrorSnackbar: (errorType: ErrorType) => void;
  handleCloseCustomSnackbar: (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => void;
  handleAnywayCloseCustomSnackbar: () => void;
  handleCloseErrorSnackbar: (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => void;
  handleGoToGame: () => void;
  handleGoToLeaderboard: () => void;
  handleClearClickRecipientAddress: () => void;
  handleOpenModal: (
    contentType: ModalContentType,
    opponentWallet?: string
  ) => void;
  handleCloseModal: () => void;
  setRecipientAddress: (address: string) => void;
  scale: number;
  setScale: (scale: number | ((prevScale: number) => number)) => void;
  inGameOnly: boolean;
  setInGameOnly: (inGameOnly: boolean) => void;
  resetScale: () => void;
}

const GameContext = createContext<GameContextType | undefined>(undefined);

interface GameProviderProps {
  children: ReactNode;
  blackSpotAddress: string;
  doubloonAddress: string;
}

export const GameProvider = ({
  children,
  blackSpotAddress,
  doubloonAddress,
}: GameProviderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [pirates, setPirates] = useState<Pirate[]>([]);
  const [customSnackbarOpen, setCustomSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarCause, setErrorSnackbarCause] = useState<ErrorType>(
    ErrorType.UnknownError
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ModalContentType>(
    ModalContentType.ReplenishBST
  );
  const [winners, setWinners] = useState<Winner[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [recipientAddress, setRecipientAddress] = useState('');
  const [gameResultsData, setGameResultsData] = useState<GameResultData[]>([]);
  const [currentPage, setCurrentPage] = useState('game');
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [finishTransactions, setFinishTransactions] = useState<number>(-1);
  const [scale, setScale] = useState(1);
  const [inGameOnly, setInGameOnly] = useState(false);

  const { onGameTransaction, onInitialGameData, onGameOver, onWinner } =
    useSocketListeners();

  const handleTransactionSnackbar = (
    sender: string,
    receiver: string,
    amount: string
  ) => {
    enqueueSnackbar(
      `The chest ${receiver} was replenished with ${amount} tokens from a chest ${sender}`,
      {
        autoHideDuration: 3000,
        variant: 'info',
      }
    );
  };

  const handleOpenCustomSnackbar = () => {
    if (
      !Boolean(getCookie('bsTokenAdded')) ||
      !Boolean(getCookie('dTokenAdded'))
    ) {
      setCustomSnackbarOpen(true);
    }
  };

  const handleOpenErrorSnackbar = (errorType: ErrorType) => {
    setErrorSnackbarCause(errorType);
    setErrorSnackbarOpen(true);
  };

  const handleCloseCustomSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCustomSnackbarOpen(false);
  };

  const handleAnywayCloseCustomSnackbar = () => {
    setCustomSnackbarOpen(false);
  };

  const handleCloseErrorSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbarOpen(false);
  };

  const handleGoToGame = () => {
    setCurrentPage('game');
  };

  const handleGoToLeaderboard = () => {
    setCurrentPage('leaderboard');
  };

  const handleClearClickRecipientAddress = () => {
    setRecipientAddress('');
  };

  const handleOpenModal = (
    contentType: ModalContentType,
    opponentWallet?: string
  ) => {
    setModalOpen(true);
    setModalContent(contentType);
    setRecipientAddress(opponentWallet as string);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const resetScale = () => {
    setScale(1);
    // You might want to reset coords here too if you move that to context later
  };

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      handleOpenCustomSnackbar();
    }
  }, []);

  useEffect(() => {
    axios.get('https://dev.pirate.top/api/results').then(
      (response: any) => {
        setGameResultsData(response.data);
      },
      (error: any) => {
        console.log('no game results');
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    onGameOver((gameOverData: GameOverData) => {
      handleOpenModal(ModalContentType.GameOver);
    });
  }, [onGameOver]);

  useEffect(() => {
    onWinner((winnerData: Winner) => {
      const winner: Winner = {
        winner: winnerData.winner,
        prize: BigNumber.from(winnerData.prize).toNumber(),
        gameStartTime: winnerData.gameStartTime,
      };
      setWinners([...winners, winner]);
    });
  }, [winners]);

  useEffect(() => {
    onGameTransaction((transactionData: GameTransaction) => {
      const sender = transactionData.sender;
      const receiver = transactionData.receiver;

      setPirates((currentPirates) => {
        const newPirates = currentPirates.map((wallet) => {
          if (wallet.address === receiver.address) {
            return {
              ...wallet,
              address: receiver.address,
              amount: BigNumber.from(receiver.amount).toString(),
              isInGame: receiver.isInGame,
              hasTransfered: receiver.hasTransfered,
            };
          }
          if (wallet.address === sender.address) {
            return {
              ...wallet,
              address: sender.address,
              amount: BigNumber.from(sender.amount).toString(),
              isInGame: sender.isInGame,
              hasTransfered: sender.hasTransfered,
            };
          }
          return wallet;
        });
        return newPirates;
      });

      setTotalTransactions(
        BigNumber.from(transactionData.totalTransactions).toNumber()
      );
      setFinishTransactions(
        BigNumber.from(transactionData.finishTransactions).toNumber()
      );

      handleTransactionSnackbar(
        sender.address,
        receiver.address,
        BigNumber.from(sender.amount).toString()
      );
    });
  }, [onGameTransaction]);

  useEffect(() => {
    onInitialGameData((initialGameData) => {
      const pirates: Pirate[] = [];
      const startTime = new Date(
        BigNumber.from(initialGameData.startGame).toNumber() * 1000
      );
      setStartDate(startTime);

      try {
        initialGameData.gamers.forEach((pirate: any) => {
          pirates.push({
            ...pirate,
            amount: BigNumber.from(pirate.amount).toString(),
          });
        });
      } catch (e) {
        handleOpenErrorSnackbar(ErrorType.UnknownError);
        console.error(e);
      }

      setPirates(pirates);
      setTotalTransactions(
        BigNumber.from(initialGameData.totalTransactions).toNumber()
      );
      setFinishTransactions(
        BigNumber.from(initialGameData.finishTransactions).toNumber()
      );
    });
  }, []);

  const value = {
    pirates,
    setPirates,
    customSnackbarOpen,
    errorSnackbarOpen,
    errorSnackbarCause,
    isModalOpen,
    modalContent,
    winners,
    startDate,
    recipientAddress,
    gameResultsData,
    currentPage,
    totalTransactions,
    finishTransactions,
    handleTransactionSnackbar,
    handleOpenCustomSnackbar,
    handleOpenErrorSnackbar,
    handleCloseCustomSnackbar,
    handleAnywayCloseCustomSnackbar,
    handleCloseErrorSnackbar,
    handleGoToGame,
    handleGoToLeaderboard,
    handleClearClickRecipientAddress,
    handleOpenModal,
    handleCloseModal,
    setRecipientAddress,
    scale,
    setScale,
    inGameOnly,
    setInGameOnly,
    resetScale,
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

export const useGame = () => {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
};
