import { ButtonHTMLAttributes } from 'react';
import {
  EyeIconDefaultStateIcon,
  EyeIconHoverStateIcon,
  EyeIconPressStateIcon,
} from '../CustomIcons';

interface EyeButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  className?: string;
  isChecked?: boolean;
}

export const EyeButton: React.FC<EyeButtonProps> = ({
  onClick,
  className,
  isChecked,
  ...props
}) => {
  const BaseIconState = isChecked
    ? EyeIconDefaultStateIcon
    : EyeIconHoverStateIcon;

  const HoverIconState = isChecked
    ? EyeIconDefaultStateIcon
    : EyeIconHoverStateIcon;

  return (
    <button
      onClick={onClick}
      className={`group relative transition-transform duration-100 active:scale-95 ${className}`}
      {...props}
    >
      <div className='absolute inset-0 transition-opacity duration-200 opacity-100 group-hover:opacity-0 group-active:opacity-0'>
        <BaseIconState />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-100 group-active:opacity-0'>
        <HoverIconState />
      </div>
      <div className='absolute inset-0 transition-opacity duration-200 opacity-0 group-hover:opacity-0 group-active:opacity-100'>
        <EyeIconPressStateIcon />
      </div>
    </button>
  );
};
