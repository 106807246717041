export const EyeIconDefaultStateIcon = () => (
  <svg viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='22' cy='22' r='18' fill='url(#paint0_radial_201_962)' />
    <circle cx='22' cy='22' r='18' fill='url(#paint1_radial_201_962)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 5C29.7322 5 36 9.70116 36 15.5003C36 21.2988 29.7322 26 22 26C14.2678 26 8 21.2988 8 15.5003C8 9.70116 14.2678 5 22 5Z'
      fill='url(#paint2_linear_201_962)'
    />
    <circle
      cx='22'
      cy='22'
      r='20'
      stroke='url(#paint3_linear_201_962)'
      strokeWidth='4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 22C40 31.9411 31.9411 40 22 40C17.2838 40 12.9913 38.1862 9.78185 35.2182C13.0701 38.7738 17.7751 41 23 41C32.9411 41 41 32.9411 41 23C41 17.7751 38.7738 13.0701 35.2182 9.78185C38.1862 12.9913 40 17.2838 40 22ZM6.95165 38.0484C3.26056 34.1136 1 28.8208 1 23C1 10.8497 10.8497 1 23 1C28.8208 1 34.1136 3.26056 38.0484 6.95165C34.0347 2.67295 28.3295 0 22 0C9.84974 0 0 9.84974 0 22C0 28.3295 2.67295 34.0347 6.95165 38.0484Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 22C13.9184 18.6129 17.4354 15 22 15C26.5646 15 30.0816 18.6129 33 22C30.0816 25.3871 26.5646 29 22 29C17.4354 29 13.9184 25.3871 11 22ZM22 16C25.2177 16 27.9116 18.6882 27.9116 22C27.9116 25.2366 25.2925 28 22 28C18.7823 28 16.0884 25.3118 16.0884 22C16.0884 18.7634 18.7075 16 22 16Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.6986 21.6923L32.8493 21.8462L33 22C31.2671 21.0769 29.5342 20.5385 27.5 20C26.5959 17.7692 24.4863 16 22 16C19.5137 16 17.3288 17.7692 16.5 20C14.5411 20.5385 12.7329 21.0769 11 22C13.9384 18.6154 17.4041 15 22 15C26.4452 15 29.8356 18.3846 32.6986 21.6923Z'
      fill='#FFFF4F'
    />
    <path
      d='M22 26C24.2091 26 26 24.2091 26 22C26 19.7909 24.2091 18 22 18C19.7909 18 18 19.7909 18 22C18 24.2091 19.7909 26 22 26Z'
      fill='url(#paint4_radial_201_962)'
    />
    <path
      d='M22 21.4908C23.0924 21.4908 23.978 20.9332 23.978 20.2454C23.978 19.5576 23.0924 19 22 19C20.9076 19 20.022 19.5576 20.022 20.2454C20.022 20.9332 20.9076 21.4908 22 21.4908Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 24L42 22L40.5348 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 24L2 22L3.4652 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 20L42 22L40.5348 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 20L2 22L3.4652 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L20 4L22 2L22 3.4652Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L24 40L22 42L22 40.5348Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L20 40L22 42L22 40.5348Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L24 4L22 2L22 3.4652Z'
      fill='#F2B100'
    />
    <defs>
      <radialGradient
        id='paint0_radial_201_962'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(17.725 16.15) rotate(53.514) scale(26.8656)'
      >
        <stop stopColor='#9C9C9C' />
        <stop offset='1' stopColor='#2D2D2D' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_201_962'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(22 22) rotate(90) scale(18)'
      >
        <stop stopOpacity='0' />
        <stop offset='1' stopOpacity='0.5' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_201_962'
        x1='22'
        y1='5'
        x2='22'
        y2='26'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_201_962'
        x1='22'
        y1='0'
        x2='22'
        y2='44'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <radialGradient
        id='paint4_radial_201_962'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(21.2014 16.5829) rotate(86.2682) scale(7.62152 7.62269)'
      >
        <stop stopColor='#FAEC2B' />
        <stop offset='0.509804' stopColor='#F4BB15' />
        <stop offset='1' stopColor='#EE8A00' />
      </radialGradient>
    </defs>
  </svg>
);

export const EyeIconHoverStateIcon = () => (
  <svg viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='22' cy='22' r='18' fill='url(#paint0_radial_201_984)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 5C29.7322 5 36 9.70116 36 15.5003C36 21.2988 29.7322 26 22 26C14.2678 26 8 21.2988 8 15.5003C8 9.70116 14.2678 5 22 5Z'
      fill='url(#paint1_linear_201_984)'
    />
    <circle
      cx='22'
      cy='22'
      r='20'
      stroke='url(#paint2_linear_201_984)'
      strokeWidth='4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 22C40 31.9411 31.9411 40 22 40C17.2838 40 12.9913 38.1862 9.78185 35.2182C13.0701 38.7738 17.7751 41 23 41C32.9411 41 41 32.9411 41 23C41 17.7751 38.7738 13.0701 35.2182 9.78185C38.1862 12.9913 40 17.2838 40 22ZM6.95165 38.0484C3.26056 34.1136 1 28.8208 1 23C1 10.8497 10.8497 1 23 1C28.8208 1 34.1136 3.26056 38.0484 6.95165C34.0347 2.67295 28.3295 0 22 0C9.84974 0 0 9.84974 0 22C0 28.3295 2.67295 34.0347 6.95165 38.0484Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 22C13.9184 18.6129 17.4354 15 22 15C26.5646 15 30.0816 18.6129 33 22C30.0816 25.3871 26.5646 29 22 29C17.4354 29 13.9184 25.3871 11 22ZM22 16C25.2177 16 27.9116 18.6882 27.9116 22C27.9116 25.2366 25.2925 28 22 28C18.7823 28 16.0884 25.3118 16.0884 22C16.0884 18.7634 18.7075 16 22 16Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.6986 21.6923L32.8493 21.8462L33 22C31.2671 21.0769 29.5342 20.5385 27.5 20C26.5959 17.7692 24.4863 16 22 16C19.5137 16 17.3288 17.7692 16.5 20C14.5411 20.5385 12.7329 21.0769 11 22C13.9384 18.6154 17.4041 15 22 15C26.4452 15 29.8356 18.3846 32.6986 21.6923Z'
      fill='#FFFF4F'
    />
    <path
      d='M22 26C24.2091 26 26 24.2091 26 22C26 19.7909 24.2091 18 22 18C19.7909 18 18 19.7909 18 22C18 24.2091 19.7909 26 22 26Z'
      fill='url(#paint3_radial_201_984)'
    />
    <path
      d='M22 21.4908C23.0924 21.4908 23.978 20.9332 23.978 20.2454C23.978 19.5576 23.0924 19 22 19C20.9076 19 20.022 19.5576 20.022 20.2454C20.022 20.9332 20.9076 21.4908 22 21.4908Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 24L42 22L40.5348 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 24L2 22L3.4652 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 20L42 22L40.5348 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 20L2 22L3.4652 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L20 4L22 2L22 3.4652Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L24 40L22 42L22 40.5348Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L20 40L22 42L22 40.5348Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L24 4L22 2L22 3.4652Z'
      fill='#F2B100'
    />
    <defs>
      <radialGradient
        id='paint0_radial_201_984'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(22 22) rotate(90) scale(18)'
      >
        <stop stopColor='#825B00' stopOpacity='0' />
        <stop offset='1' stopColor='#825B00' stopOpacity='0.3' />
      </radialGradient>
      <linearGradient
        id='paint1_linear_201_984'
        x1='22'
        y1='5'
        x2='22'
        y2='26'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_201_984'
        x1='22'
        y1='0'
        x2='22'
        y2='44'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <radialGradient
        id='paint3_radial_201_984'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(21.2014 16.5829) rotate(86.2682) scale(7.62152 7.62269)'
      >
        <stop stopColor='#FAEC2B' />
        <stop offset='0.509804' stopColor='#F4BB15' />
        <stop offset='1' stopColor='#EE8A00' />
      </radialGradient>
    </defs>
  </svg>
);

export const EyeIconPressStateIcon = () => (
  <svg viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='22' cy='22' r='18' fill='url(#paint0_linear_201_964)' />
    <circle cx='22' cy='22' r='18' fill='url(#paint1_radial_201_964)' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 5C29.7322 5 36 9.70116 36 15.5003C36 21.2988 29.7322 26 22 26C14.2678 26 8 21.2988 8 15.5003C8 9.70116 14.2678 5 22 5Z'
      fill='url(#paint2_linear_201_964)'
    />
    <circle
      cx='22'
      cy='22'
      r='20'
      stroke='url(#paint3_linear_201_964)'
      strokeWidth='4'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 22C40 31.9411 31.9411 40 22 40C17.2838 40 12.9913 38.1862 9.78185 35.2182C13.0701 38.7738 17.7751 41 23 41C32.9411 41 41 32.9411 41 23C41 17.7751 38.7738 13.0701 35.2182 9.78185C38.1862 12.9913 40 17.2838 40 22ZM6.95165 38.0484C3.26056 34.1136 1 28.8208 1 23C1 10.8497 10.8497 1 23 1C28.8208 1 34.1136 3.26056 38.0484 6.95165C34.0347 2.67295 28.3295 0 22 0C9.84974 0 0 9.84974 0 22C0 28.3295 2.67295 34.0347 6.95165 38.0484Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 22C13.9184 18.6129 17.4354 15 22 15C26.5646 15 30.0816 18.6129 33 22C30.0816 25.3871 26.5646 29 22 29C17.4354 29 13.9184 25.3871 11 22ZM22 16C25.2177 16 27.9116 18.6882 27.9116 22C27.9116 25.2366 25.2925 28 22 28C18.7823 28 16.0884 25.3118 16.0884 22C16.0884 18.7634 18.7075 16 22 16Z'
      fill='#FFB900'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.6986 21.6923L32.8493 21.8462L33 22C31.2671 21.0769 29.5342 20.5385 27.5 20C26.5959 17.7692 24.4863 16 22 16C19.5137 16 17.3288 17.7692 16.5 20C14.5411 20.5385 12.7329 21.0769 11 22C13.9384 18.6154 17.4041 15 22 15C26.4452 15 29.8356 18.3846 32.6986 21.6923Z'
      fill='#FFFF4F'
    />
    <path
      d='M22 26C24.2091 26 26 24.2091 26 22C26 19.7909 24.2091 18 22 18C19.7909 18 18 19.7909 18 22C18 24.2091 19.7909 26 22 26Z'
      fill='url(#paint4_radial_201_964)'
    />
    <path
      d='M22 21.4908C23.0924 21.4908 23.978 20.9332 23.978 20.2454C23.978 19.5576 23.0924 19 22 19C20.9076 19 20.022 19.5576 20.022 20.2454C20.022 20.9332 20.9076 21.4908 22 21.4908Z'
      fill='#FFFF4F'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 24L42 22L40.5348 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 24L2 22L3.4652 22Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40.5348 22L35 22L40 20L42 22L40.5348 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.4652 22L9 22L4 20L2 22L3.4652 22Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L20 4L22 2L22 3.4652Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L24 40L22 42L22 40.5348Z'
      fill='#FDFF82'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 40.5348L22 35L20 40L22 42L22 40.5348Z'
      fill='#F2B100'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22 3.4652L22 9L24 4L22 2L22 3.4652Z'
      fill='#F2B100'
    />
    <defs>
      <linearGradient
        id='paint0_linear_201_964'
        x1='22'
        y1='4'
        x2='22'
        y2='40'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <radialGradient
        id='paint1_radial_201_964'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(22 22) rotate(90) scale(18)'
      >
        <stop stopColor='#825B00' stopOpacity='0' />
        <stop offset='1' stopColor='#825B00' stopOpacity='0.3' />
      </radialGradient>
      <linearGradient
        id='paint2_linear_201_964'
        x1='22'
        y1='5'
        x2='22'
        y2='26'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.3' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_201_964'
        x1='22'
        y1='0'
        x2='22'
        y2='44'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9EA2A' />
        <stop offset='1' stopColor='#EE8A00' />
      </linearGradient>
      <radialGradient
        id='paint4_radial_201_964'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(21.2014 16.5829) rotate(86.2682) scale(7.62152 7.62269)'
      >
        <stop stopColor='#FAEC2B' />
        <stop offset='0.509804' stopColor='#F4BB15' />
        <stop offset='1' stopColor='#EE8A00' />
      </radialGradient>
    </defs>
  </svg>
);
